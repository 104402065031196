// Created: 2024-02-08
// Purpose: About page component that provides information about StorySplat
// Features:
// - Company/product information
// - Mission statement
// - Key features overview
// Changes:
// - Added toolbar for consistent navigation
// - Updated styling with white background
// - Added Discovery link to toolbar
// - Made content scrollable
// - Added pricing link to toolbar

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import config from '../config';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  background-color: #f4f4f4;
  overflow: hidden;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  margin-right: 40px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #666;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 32px;
  color: #333;
  margin-bottom: 24px;
`;

const Section = styled.section`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #444;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  
  &:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #666;
  }
`;

const About: React.FC = () => {
  return (
    <PageContainer>
      <Toolbar>
        <Logo to="/">
          StorySplat
        </Logo>
        <NavLinks>
          <NavLink to="/editor">Editor</NavLink>
          <NavLink to="/discover">Discover</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/help">Help</NavLink>
          {config.stripeIntegrationEnabled && (
            <NavLink to="/pricing">Pricing</NavLink>
          )}
        </NavLinks>
      </Toolbar>

      <ScrollContainer>
        <ContentContainer>
          <Title>About StorySplat</Title>
          
          <Section>
            <SectionTitle>Our Vision</SectionTitle>
            <Text>
              StorySplat empowers creators to tell immersive stories through stunning 3D experiences. 
              We believe that every space, object, and moment has a story to tell, and our platform 
              provides the canvas for these stories to come alive in breathtaking detail and depth.
            </Text>
          </Section>

          <Section>
            <SectionTitle>The StorySplat Experience</SectionTitle>
            <Text>
              Step into a world where storytelling transcends traditional boundaries. Our platform 
              transforms static captures into dynamic, interactive narratives that viewers can explore 
              from every angle. Whether you're preserving memories, showcasing spaces, or creating 
              educational experiences, StorySplat brings your stories to life with unprecedented realism 
              and emotional impact.
            </Text>
            <FeatureList>
              <FeatureItem>Create immersive narratives that captivate your audience</FeatureItem>
              <FeatureItem>Guide viewers through carefully crafted interactive journeys</FeatureItem>
              <FeatureItem>Share experiences that feel real and personal</FeatureItem>
              <FeatureItem>Build emotional connections through spatial storytelling</FeatureItem>
            </FeatureList>
          </Section>

          <Section>
            <SectionTitle>Storytelling Features</SectionTitle>
            <FeatureList>
              <FeatureItem>
                <strong>Interactive Narratives:</strong> Create guided journeys with 
                story points, annotations, and seamless transitions
              </FeatureItem>
              <FeatureItem>
                <strong>Emotional Moments:</strong> Capture and share spaces exactly as 
                they are, preserving their authentic feel and atmosphere
              </FeatureItem>
              <FeatureItem>
                <strong>Dynamic Perspectives:</strong> Let viewers explore stories from 
                multiple angles and viewpoints
              </FeatureItem>
              <FeatureItem>
                <strong>Rich Context:</strong> Add descriptions, historical details, and 
                cultural significance to your spaces
              </FeatureItem>
              <FeatureItem>
                <strong>Engagement Insights:</strong> Understand how viewers interact 
                with your stories
              </FeatureItem>
              <FeatureItem>
                <strong>Easy Sharing:</strong> Share your stories across platforms and 
                devices seamlessly
              </FeatureItem>
            </FeatureList>
          </Section>

          <Section>
            <SectionTitle>Stories We Enable</SectionTitle>
            <Text>
              StorySplat brings stories to life across diverse fields and purposes:
            </Text>
            <FeatureList>
              <FeatureItem>
                <strong>Heritage Preservation:</strong> Capture historical sites and cultural 
                landmarks, preserving their stories for future generations
              </FeatureItem>
              <FeatureItem>
                <strong>Real Estate Storytelling:</strong> Transform property tours into 
                compelling narratives about potential future homes
              </FeatureItem>
              <FeatureItem>
                <strong>Educational Journeys:</strong> Create interactive learning 
                experiences that make complex subjects accessible and engaging
              </FeatureItem>
              <FeatureItem>
                <strong>Museum Experiences:</strong> Bring exhibits to life with detailed 
                context and interactive exploration
              </FeatureItem>
              <FeatureItem>
                <strong>Personal Memories:</strong> Preserve special places and moments 
                with emotional depth and detail
              </FeatureItem>
              <FeatureItem>
                <strong>Brand Storytelling:</strong> Create immersive brand experiences 
                that resonate with audiences
              </FeatureItem>
            </FeatureList>
          </Section>

          <Section>
            <SectionTitle>Start Your Story</SectionTitle>
            <Text>
              Whether you're a storyteller, educator, preservationist, or business owner, 
              StorySplat provides the tools to bring your narratives to life. Our intuitive 
              platform makes it easy to start creating, while offering the depth needed for 
              complex storytelling. Begin your journey today and join a community of storytellers 
              shaping the future of spatial narratives.
            </Text>
          </Section>
        </ContentContainer>
      </ScrollContainer>
    </PageContainer>
  );
};

export default About;
