/**
 * StripeConfig.ts
 * Configuration file for Stripe integration, including plan details, pricing, and API endpoints
 * 
 * Updated: March 19, 2024
 * Changes: 
 * - Updated price IDs for monthly plans
 * - Added yearly pricing options
 * - Updated pricing display
 */

export const STRIPE_CONFIG = {
  PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
  PLANS: {
    PRO: {
      name: 'Pro',
      price: 9.00,
      features: [
        'Unlimited Saved Scenes',
        '50GB Storage',
        'Non Commercial Use'
      ],
      priceId: 'price_1QV3epENuSRGAF4JlKKhImz5', // Monthly Pro price ID
      yearlyPriceId: 'price_1QV3epENuSRGAF4JvuP1BCr0', // Yearly Pro price ID
      yearlyPrice: 79.00,
    },
    BUSINESS: {
      name: 'Business',
      price: 99.00,
      features: [
        'Everything in Pro',
        '100GB Storage',
        'Commercial Use License',
        'Priority Support',
        'Remove Watermark', 
        'Coming Soon: Custom Logo & Branding',
        'Coming Soon: Advanced Analytics',
      ],
      priceId: 'price_1QV3iMENuSRGAF4JVdO2kk52', // Monthly Business price ID
      yearlyPriceId: 'price_1QV3kJENuSRGAF4JJ1gqzR98', // Yearly Business price ID
      yearlyPrice: 999.00,
    }
  },
  SUCCESS_URL: `${window.location.origin}/subscription-success`,
  CANCEL_URL: `${window.location.origin}/subscription-cancel`,
  CURRENCY: 'USD',
  API: {
    CHECKOUT: 'https://us-central1-story-splat.cloudfunctions.net/api/createCheckoutSession',
    PORTAL: 'https://us-central1-story-splat.cloudfunctions.net/api/createPortalSession'
  }
};

// Test card numbers for development
export const TEST_CARDS = {
  SUCCESS: '4242424242424242',
  DECLINE: '4000000000000002',
  INSUFFICIENT_FUNDS: '4000000000009995',
  REQUIRES_3D_SECURE: '4000000000003220'
};

export const STORAGE_LIMITS = {
  FREE: .25, // GB
  PRO: 50, // GB
  BUSINESS: 100, // GB
  ENTERPRISE: -1 // Unlimited
};
