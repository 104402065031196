
import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { FiX, FiInfo, FiVolume2, FiHelpCircle } from "react-icons/fi";
import { Interaction, AudioInteractionData, InfoInteractionData, Waypoint } from "../types/SceneTypes";
import AudioManager from "../utils/AudioManager";
import { Scene } from "@babylonjs/core";


interface InteractionEditorProps {
  waypointIndex: number;
  interactions: Interaction[];
  waypoint: Waypoint;
  scene: Scene | null;
  setInteractions: (interactions: Interaction[]) => void;
  setTriggerDistance: (distance: number) => void;
  onClose: () => void;
}

// Styled components remain mostly the same, with some minor enhancements
const EditorContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1e1e1e;
  border-radius: 12px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6);
  width: 500px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Header = styled.div`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  
  &:hover {
    color: #ff6b6b;
  }
`;

const Content = styled.div`
  padding: 16px;
  max-height: 70vh;
  overflow-y: auto;
`;

const InteractionItem = styled.div`
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #353535;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
  background-color: #444;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
`;


const InteractionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const InteractionType = styled.span`
  font-weight: bold;
  text-transform: capitalize;
`;

const Button = styled.button<{ $variant?: 'primary' | 'danger' | 'secondary' }>`
  padding: 6px 12px;
  background-color: ${props => 
    props.$variant === 'primary' ? '#007BFF' : 
    props.$variant === 'danger' ? '#dc3545' : 
    props.$variant === 'secondary' ? 'transparent' : '#555555'
  };
  color: ${props => props.$variant === 'secondary' ? '#ccc' : 'white'};
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
`;

const Input = styled.input`
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 8px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 8px;
`;

const SpatialSoundSection = styled.div`
  margin-top: 16px;
  padding: 12px;
  background-color: #2c2c2c;
  border-radius: 4px;
`;

const SectionTitle = styled.h4`
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #ffffff;
`;

const InputGroup = styled.div`
  margin-bottom: 12px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: #ccc;
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  font-size: 12px;
  margin-bottom: 8px;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 8px;
  border-radius: 4px;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
`;

const SliderLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #ccc;
`;

const SliderValue = styled.span`
  font-size: 12px;
  color: #888;
  min-width: 45px;
  text-align: right;
`;

const Slider = styled.input`
  width: 100%;
  height: 4px;
  background: #444;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #007BFF;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #0056b3;
    }
  }
`;


const InteractionEditor: React.FC<InteractionEditorProps> = ({
  waypointIndex,
  waypoint,
  interactions,
  scene,
  setInteractions,
  setTriggerDistance,
  onClose,
}) => {
  const [newInteractionType, setNewInteractionType] = useState<"audio" | "info">("info");
  const [newInteractionData, setNewInteractionData] = useState<any>({});
  const [error, setError] = useState<string | null>(null);
  const nodeRef = React.useRef(null);

  const addInteraction = () => {
    // Check for existing info interaction
    const hasInfoInteraction = interactions.some(interaction => interaction.type === 'info');
    
    if (newInteractionType === 'info' && hasInfoInteraction) {
      setError("Only one info pop-up is allowed per waypoint");
      return;
    }

    const newInteraction: Interaction = {
      id: `interaction-${Date.now()}`,
      type: newInteractionType,
      data: {
        ...newInteractionData,
        ...(newInteractionType === 'audio' ? { 
          spatialSound: false, 
          stopOnExit: true,
          volume: 1,
          autoplay: false,
          loop: true 
        } : {})
      },
    };
    setInteractions([...interactions, newInteraction]);
    setNewInteractionData({});
    setError(null);
  };


  const updateInteraction = (id: string, updatedData: any) => {
    const updatedInteractions = interactions.map((interaction) =>
      interaction.id === id ? { ...interaction, data: updatedData } : interaction
    );
    setInteractions(updatedInteractions);
  };

  const removeInteraction = (id: string) => {
    const filteredInteractions = interactions.filter(
      (interaction) => interaction.id !== id
    );
    setInteractions(filteredInteractions);
  };

  // Handle drag events to prevent interference with file drops
  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };

  return (
    <Draggable handle=".handle" nodeRef={nodeRef}>
      <EditorContainer ref={nodeRef}>
      <Header className="handle">
          <Title>
            {newInteractionType === 'audio' ? <FiVolume2 /> : <FiInfo />}
            Edit Interactions - Waypoint {waypointIndex + 1}
          </Title>
          <CloseButton onClick={onClose}>
            <FiX />
          </CloseButton>
        </Header>
        <Content>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <InputGroup>
            <InputLabel>
              Trigger Distance 
              <Tooltip>
                Distance from waypoint to trigger interactions
              </Tooltip>
            </InputLabel>
            <Input
              type="number"
              value={waypoint?.triggerDistance || ""}
              onChange={(e) => setTriggerDistance(parseFloat(e.target.value))}
              placeholder="Enter Trigger Distance (meters)"
            />
          </InputGroup>

          {/* Existing interactions rendering */}
          {interactions.map((interaction) => (
            <InteractionItem key={interaction.id}>
              <InteractionHeader>
                <InteractionType>{interaction.type} Interaction</InteractionType>
                <Button $variant="danger" onClick={() => removeInteraction(interaction.id)}>
                  Remove
                </Button>
              </InteractionHeader>
              {interaction.type === "audio" && (
                <AudioInteractionForm
                  data={interaction.data as AudioInteractionData}
                  onChange={(updatedData) => updateInteraction(interaction.id, updatedData)}
                  scene={scene}
                />
              )}
              {interaction.type === "info" && (
                <InfoInteractionForm
                  data={interaction.data as InfoInteractionData}
                  onChange={(updatedData) => updateInteraction(interaction.id, updatedData)}
                />
              )}
            </InteractionItem>
          ))}
          
          {/* Add Interaction Section */}
          <InteractionItem>
            <Select
              value={newInteractionType}
              onChange={(e) => {
                setNewInteractionType(e.target.value as "audio" | "info");
                setError(null);
              }}
            >
              <option value="audio">🔊 Audio Interaction</option>
              <option value="info">ℹ️ Info Pop-up</option>
            </Select>
            <Button $variant="primary" onClick={addInteraction}>
              Add Interaction
            </Button>
          </InteractionItem>
        </Content>
      </EditorContainer>
    </Draggable>
  );
};
const AudioInteractionForm: React.FC<{
  data: AudioInteractionData;
  onChange: (data: AudioInteractionData) => void;
  scene: Scene | null;
}> = ({ data, onChange, scene }) => {
  // Update sound in real-time when settings change
  const updateSound = (updatedData: AudioInteractionData, scene: Scene | null) => {
    const sound = AudioManager.getSoundInstance(data.id);
    if (sound) {
      sound.distanceModel = updatedData.distanceModel || 'exponential';
      sound.maxDistance = updatedData.maxDistance || 20;
      sound.refDistance = updatedData.refDistance || 1;
      sound.rolloffFactor = updatedData.rolloffFactor || 1;
      sound.setVolume(updatedData.volume || 1);
      // Check if sound was playing before update
      const wasPlaying = AudioManager.isSoundPlaying(data.id);
      
      // Stop the current sound
      console.log('Stopping sound to apply updated settings, wasPlaying:', wasPlaying);
      AudioManager.stopSound(data.id);
      
      // Apply new settings by recreating the sound
        console.log('Replaying sound with updated settings');
        const sceneOrUndefined = scene || undefined;
        AudioManager.playSound(updatedData, {scene: sceneOrUndefined});
    }
    onChange(updatedData);
  };

  return (
    <>
      <InputGroup>
        <InputLabel>Audio URL:</InputLabel>
        <Input
          type="text"
          value={data.url || ""}
          onChange={(e) => onChange({ ...data, url: e.target.value })}
          placeholder="Enter Audio URL or File Path"
        />
      </InputGroup>
      
      {/* Enhanced Spatial Sound Controls */}
      <Label>
        <Checkbox
          type="checkbox"
          checked={data.spatialSound !== undefined ? data.spatialSound : true}
          onChange={(e) => {
            const spatialSoundChecked = e.target.checked;
            updateSound({ 
              ...data, 
              spatialSound: spatialSoundChecked,
              // Set default values when enabling spatial sound
              ...(spatialSoundChecked ? {
                distanceModel: "exponential",
                maxDistance: 20,
                refDistance: 1,
                rolloffFactor: 1, 
              } : {})
            }, scene);
          }}
        />
        Enable Spatial Sound
        <FiHelpCircle 
          title="Creates a realistic 3D audio effect where sound volume changes based on distance from the waypoint" 
          style={{ marginLeft: '5px', color: '#888' }} 
        />
      </Label>

      {/* Volume Slider */}
      <SliderContainer>
        <SliderLabel>
          Volume
          <SliderValue>{Math.round((data.volume || 1) * 100)}%</SliderValue>
        </SliderLabel>
        <Slider
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={data.volume !== undefined ? data.volume : 1}
          onChange={(e) => updateSound({ ...data, volume: parseFloat(e.target.value) }, scene)}
        />
      </SliderContainer>

      {data.spatialSound && (
        <SpatialSoundSection>
          <SectionTitle>
            Spatial Sound Settings
            <FiHelpCircle 
              title="These settings control how the sound fades with distance" 
              style={{ marginLeft: '5px', color: '#888' }} 
            />
          </SectionTitle>

          {/* Reference Distance Slider */}
          <SliderContainer>
            <SliderLabel>
              Reference Distance
              <SliderValue>{(data.refDistance || 1).toFixed(1)}m</SliderValue>
            </SliderLabel>
            <Slider
              type="range"
              min="0.1"
              max="10"
              step="0.1"
              value={data.refDistance || 1}
              onChange={(e) => updateSound({ ...data, refDistance: parseFloat(e.target.value) }, scene)}
            />
            <InputLabel>
              <FiHelpCircle 
                title="Distance at which the sound starts to fade. Sound is at full volume within this distance." 
                style={{ marginLeft: '5px', color: '#888' }} 
              />
            </InputLabel>
          </SliderContainer>

          {/* Maximum Distance Slider */}
          <SliderContainer>
            <SliderLabel>
              Maximum Distance
              <SliderValue>{(data.maxDistance || 20).toFixed(1)}m</SliderValue>
            </SliderLabel>
            <Slider
              type="range"
              min={data.refDistance || 1}
              max="50"
              step="0.1"
              value={data.maxDistance || 20}
              onChange={(e) => updateSound({ ...data, maxDistance: parseFloat(e.target.value) },scene)}
            />
            <InputLabel>
              <FiHelpCircle 
                title="Maximum distance at which the sound can be heard. Sound is silent beyond this distance." 
                style={{ marginLeft: '5px', color: '#888' }} 
              />
            </InputLabel>
          </SliderContainer>

          {/* Rolloff Factor Slider */}
          <SliderContainer>
            <SliderLabel>
              Rolloff Factor
              <SliderValue>{(data.rolloffFactor || 1).toFixed(1)}</SliderValue>
            </SliderLabel>
            <Slider
              type="range"
              min="0"
              max="5"
              step="0.1"
              value={data.rolloffFactor || 1}
              onChange={(e) => updateSound({ ...data, rolloffFactor: parseFloat(e.target.value) }, scene)}
            />
            <InputLabel>
              <FiHelpCircle 
                title="Controls how quickly the sound fades with distance. Higher values make the sound fade more quickly." 
                style={{ marginLeft: '5px', color: '#888' }} 
              />
            </InputLabel>
          </SliderContainer>

          <InputGroup>
            <InputLabel>
              Distance Model:
              <FiHelpCircle 
                title="Linear: Sound fades evenly with distance. Exponential: Sound fades more quickly at first, then more gradually." 
                style={{ marginLeft: '5px', color: '#888' }} 
              />
            </InputLabel>
            <Select
              value={data.distanceModel || "exponential"}
              onChange={(e) => updateSound({ ...data, distanceModel: e.target.value as "linear" | "exponential" }, scene)}
            >
              <option value="exponential">Exponential (Natural Sound)</option>
              <option value="linear">Linear (Even Fade)</option>
            </Select>
          </InputGroup>
        </SpatialSoundSection>
      )}

      {/* Playback Options */}
      <Label>
        <Checkbox
          type="checkbox"
          checked={data.stopOnExit !== undefined ? data.stopOnExit : true}
          onChange={(e) => updateSound({ ...data, stopOnExit: e.target.checked }, scene)}
        />
        Stop Sound When Leaving Waypoint
      </Label>

      <Label>
        <Checkbox
          type="checkbox"
          checked={data.autoplay !== undefined ? data.autoplay : false}
          onChange={(e) => updateSound({ ...data, autoplay: e.target.checked }, scene)}
        />
        Play on page load
      </Label>

      <Label>
        <Checkbox
          type="checkbox"
          checked={data.loop !== undefined ? data.loop : true}
          onChange={(e) => updateSound({ ...data, loop: e.target.checked }, scene)}
        />
        Loop Audio
      </Label>
    </>
  );
};

const InfoInteractionForm: React.FC<{
  data: InfoInteractionData;
  onChange: (data: InfoInteractionData) => void;
}> = ({ data, onChange }) => {
  return (
    <InputGroup>
      <InputLabel>Info Text:</InputLabel>
      <Input
        as="textarea"
        value={data.text || ""}
        onChange={(e) => onChange({ ...data, text: e.target.value })}
        placeholder="Enter Info Text"
        style={{ minHeight: "100px", resize: "vertical", width: "calc(100% - 16px)" }}
      />
    </InputGroup>
  );
};

export default InteractionEditor;

