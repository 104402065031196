/*
File: src/pages/SubscriptionSuccess.tsx
Description: This file contains the Subscription Success page component.
Changes: 2024-01-09 - Modified to render as overlay instead of full page replacement
         2024-01-10 - Added subscription tier specifics, countdown timer, and skip button
*/

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';
import { STRIPE_CONFIG } from '../utils/StripeConfig';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.95);
  z-index: 100000;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  color: white;
`;

const SuccessIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 30px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
`;

const Message = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin: 20px 0;
  color: #ddd;
`;

const RedirectMessage = styled.div`
  margin-top: 30px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const SkipButton = styled.button`
  background-color: transparent;
  border: 1px solid #999;
  color: #999;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border-color: white;
  }
`;

const SubscriptionSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const tier = searchParams.get('tier') || 'pro';
  const [countdown, setCountdown] = useState(15);

  const getTierSpecificContent = () => {
    switch (tier) {
      case STRIPE_CONFIG.PLANS.BUSINESS.priceId || STRIPE_CONFIG.PLANS.BUSINESS.yearlyPriceId: // business
        return {
          title: 'Welcome to Business Plan!',
          message: 'Your account has been upgraded to Business. You now have a Commercial Use License. Your Storage Limit is now 100GB, and you can upload unlimited splats. You also have access to priority support.',
        };
      default: // pro
        return {
          title: 'Welcome to Pro!',
          message: 'Your account has been successfully upgraded. You now have access to all pro features.',
        };
    }
  };

  useEffect(() => {
    if (sessionId) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            navigate('/editor');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      navigate('/editor');
    }
  }, [sessionId, navigate]);

  const handleSkip = () => {
    navigate('/editor');
  };

  const content = getTierSpecificContent();

  return (
    <Overlay>
      <Container>
        <SuccessIcon>
          <FiCheck />
        </SuccessIcon>
        <h1>{content.title}</h1>
        <Message>
          {content.message}
        </Message>
        <Message>
          Thank you for supporting StorySplat!
        </Message>
        <RedirectMessage>
          <span>Redirecting to dashboard in {countdown} seconds...</span>
          <SkipButton onClick={handleSkip}>Skip</SkipButton>
        </RedirectMessage>
      </Container>
    </Overlay>
  );
};

export default SubscriptionSuccess;
