// src/utils/AnalyticsMiddleware.ts

import { analytics } from './FirebaseConfig';
import { logEvent } from 'firebase/analytics';

export const trackProEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  try {
    logEvent(analytics, eventName, {
      ...properties,
      isPro: true,
      timestamp: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error tracking pro event:', error);
  }
};

export const PRO_EVENTS = {
  // Upgrade events
  UPGRADE_STARTED: 'pro_upgrade_started',
  UPGRADE_COMPLETED: 'pro_upgrade_completed',
  UPGRADE_FAILED: 'pro_upgrade_failed',

  // Checkout events
  CHECKOUT_STARTED: 'checkout_started',
  CHECKOUT_SESSION_CREATED: 'checkout_session_created',
  CHECKOUT_COMPLETED: 'checkout_completed',
  CHECKOUT_FAILED: 'checkout_failed',

  // Portal events
  PORTAL_REDIRECT_STARTED: 'portal_redirect_started',
  PORTAL_REDIRECT_SUCCESS: 'portal_redirect_success',
  PORTAL_REDIRECT_FAILED: 'portal_redirect_failed',

  // Subscription events
  SUBSCRIPTION_ACTIVATED: 'subscription_activated',
  SUBSCRIPTION_UPDATED: 'subscription_updated',
  SUBSCRIPTION_CANCELLED: 'subscription_cancelled',
  SUBSCRIPTION_PAUSED: 'subscription_paused',
  SUBSCRIPTION_RESUMED: 'subscription_resumed',

  // Payment events
  PAYMENT_SUCCEEDED: 'payment_succeeded',
  PAYMENT_FAILED: 'payment_failed',
  PAYMENT_METHOD_UPDATED: 'payment_method_updated',

  // Feature usage events
  FEATURE_ACCESSED: 'pro_feature_accessed',
  STORAGE_LIMIT_REACHED: 'storage_limit_reached',
  ANALYTICS_VIEWED: 'analytics_viewed',
  EXPORT_CREATED: 'export_created',
  CUSTOM_DOMAIN_ADDED: 'custom_domain_added',

  // Support events
  SUPPORT_REQUESTED: 'support_requested',
  FEEDBACK_SUBMITTED: 'feedback_submitted',

  // Account events
  ACCOUNT_UPDATED: 'account_updated',
  SETTINGS_CHANGED: 'settings_changed'
} as const;

// Type for event names
export type ProEventName = keyof typeof PRO_EVENTS;

// Interface for event properties
export interface ProEventProperties {
  userId?: string;
  plan?: string;
  planType?: string;
  priceId?: string;
  amount?: number;
  interval?: string;
  feature?: string;
  error?: string;
  errorCode?: string;
  errorDetails?: string;
  code?: string;
  cancellationReason?: string;
  customDomain?: string;
  storageUsed?: number;
  storageLimit?: number;
  [key: string]: any;
}

// Enhanced type-safe tracking function
export const trackProEventTyped = (
  eventName: ProEventName,
  properties?: ProEventProperties
) => {
  try {
    logEvent(analytics, PRO_EVENTS[eventName], {
      ...properties,
      isPro: true,
      timestamp: new Date().toISOString()
    });
  } catch (error) {
    console.error(`Error tracking pro event ${eventName}:`, error);
  }
};

// Event category constants
export const EVENT_CATEGORIES = {
  SUBSCRIPTION: 'subscription',
  PAYMENT: 'payment',
  FEATURE: 'feature',
  SUPPORT: 'support',
  ACCOUNT: 'account',
  CHECKOUT: 'checkout',
  PORTAL: 'portal'
} as const;

// Helper function to get event category
export const getEventCategory = (eventName: ProEventName): string => {
  if (eventName.startsWith('SUBSCRIPTION_')) return EVENT_CATEGORIES.SUBSCRIPTION;
  if (eventName.startsWith('PAYMENT_')) return EVENT_CATEGORIES.PAYMENT;
  if (eventName.startsWith('CHECKOUT_')) return EVENT_CATEGORIES.CHECKOUT;
  if (eventName.startsWith('PORTAL_')) return EVENT_CATEGORIES.PORTAL;
  if (eventName.includes('FEATURE') || eventName.includes('EXPORT') || eventName.includes('STORAGE')) return EVENT_CATEGORIES.FEATURE;
  if (eventName.includes('SUPPORT') || eventName.includes('FEEDBACK')) return EVENT_CATEGORIES.SUPPORT;
  return EVENT_CATEGORIES.ACCOUNT;
};
