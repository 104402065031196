/*
File: LightingControls.tsx
Description: Component for managing scene lighting, including adding/removing lights and controlling their properties.
Last modified: 2024-01-15
Changes: 
- Redesigned to match CollisionControls styling and functionality
- Added gizmo controls and visual indicators
- Added camera prop for positioning new lights
*/

// ... previous imports remain unchanged ...

import React, { useState, useRef } from 'react';
import Draggable from "react-draggable";
import { SceneLight } from '../../types/SceneTypes';
import { v4 as uuidv4 } from 'uuid';
import { FiChevronDown, FiChevronUp, FiInfo } from "react-icons/fi";
import {  BiMove, BiRotateRight, BiSun, BiMoon } from "react-icons/bi";
import { FiSun } from "react-icons/fi";
import styled from "styled-components";
import * as BABYLON from "@babylonjs/core";

const Container = styled.div`
  position: absolute;
  top: 450px;
  left: 1000px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 380px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Header = styled.div<{ $isCollapsed: boolean }>`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  border-bottom-right-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Subtitle = styled.h3`
  margin: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 200;
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: italic; 
`;
const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;
  &:hover {
    color: #1e90ff;
  }
`;

const Content = styled.div<{ $isCollapsed: boolean }>`
  max-height: ${(props) => (props.$isCollapsed ? "0px" : "600px")};
  opacity: ${(props) => (props.$isCollapsed ? 0 : 1)};
  padding: ${(props) => (props.$isCollapsed ? "0 16px" : "16px")};
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const ControlsGroup = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  padding: 12px;
  background-color: #2c2c2c;
  border-radius: 5px;
  border: 1px solid #3d3d3d;
`;

const ControlButton = styled.button<{ $active?: boolean }>`
  padding: 8px;
  background-color: ${props => props.$active ? '#007BFF' : '#3c3c3c'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$active ? '#0056b3' : '#4c4c4c'};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const LightItem = styled.div<{ $isSelected?: boolean }>`
  padding: 12px;
  background-color: ${props => props.$isSelected ? '#3d3d3d' : '#2c2c2c'};
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.$isSelected ? '#007BFF' : 'transparent'};
  transition: all 0.2s ease;
  
  &:hover {
    border-color: #007BFF;
    background-color: #3d3d3d;
  }
`;

const LightControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const GizmoControls = styled.div`
  display: flex;
  gap: 4px;
  margin-right: 8px;
  padding: 4px;
  background-color: #252525;
  border-radius: 4px;
`;

const GizmoButton = styled(ControlButton)`
  padding: 6px;
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const LightLabel = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
`;

const Button = styled.button<{ $variant?: "primary" | "danger" | "secondary" }>`
  padding: 8px 12px;
  background-color: ${(props) =>
    props.$variant === "primary"
      ? "#007BFF"
      : props.$variant === "danger"
      ? "#dc3545"
      : props.$variant === "secondary"
      ? "transparent"
      : "#555555"};
  color: ${(props) => (props.$variant === "secondary" ? "#ccc" : "white")};
  border: ${(props) =>
    props.$variant === "secondary" ? "1px solid #ccc" : "none"};
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  justify-content: center;
  width: 100%;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background-color: #555555;
    color: #888888;
    cursor: not-allowed;
    transform: none;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const Tooltip = styled.div<{ $show: boolean }>`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  opacity: ${props => props.$show ? 1 : 0};
  transition: opacity 0.2s ease;
  margin-bottom: 8px;
  z-index: 1000;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);
  }
`;

const HelpText = styled.div`
  padding: 12px;
  background-color: #3d3d3d;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 13px;
  color: #ddd;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #4d4d4d;
`;
interface LightingControlsProps {
  lights: SceneLight[];
  onLightsChange: (lights: SceneLight[]) => void;
  isLightEditMode: boolean;
  setIsLightEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLightIndex: number | null;
  setSelectedLightIndex: React.Dispatch<React.SetStateAction<number | null>>;
  activeControl?: "move" | "rotate" | "scale";
  setActiveControl: React.Dispatch<React.SetStateAction<"move" | "rotate" | "scale" | undefined>>;
  camera: BABYLON.Camera | null;
}

const LightingControls: React.FC<LightingControlsProps> = ({
  lights,
  onLightsChange,
  isLightEditMode,
  setIsLightEditMode,
  selectedLightIndex,
  setSelectedLightIndex,
  activeControl,
  setActiveControl,
  camera
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [selectedLightType, setSelectedLightType] = useState<SceneLight['type']>("hemispheric");
  const [tooltipText, setTooltipText] = useState<string>("");
  const nodeRef = useRef(null);

  const addLight = (type: SceneLight['type']) => {
    if (!camera) return;
    
    const cameraPosition = camera.position.clone().add(
      camera.getForwardRay().direction.scale(5)
    );

    const newLight: SceneLight = {
      id: uuidv4(),
      type,
      name: `${type.charAt(0).toUpperCase() + type.slice(1)} Light ${lights.length + 1}`,
      intensity: 1,
      color: '#ffffff',
      position: { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z },
      enabled: true,
      ...(type === 'hemispheric' && { groundColor: '#000000' }),
      ...(type === 'spot' && { 
        direction: { x: 0, y: -1, z: 0 },
        angle: 0.8,
        exponent: 2,
        range: 100
      }),
      ...(type === 'directional' && {
        direction: { x: 0, y: -1, z: 0 }
      }),
      ...(type === 'point' && {
        range: 100
      })
    };
    onLightsChange([...lights, newLight]);
    setSelectedLightIndex(lights.length);
  };

  const removeLight = (id: string) => {
    const updatedLights = lights.filter(light => light.id !== id);
    onLightsChange(updatedLights);
    if (selectedLightIndex === lights.findIndex(light => light.id === id)) {
      setSelectedLightIndex(null);
    }
  };

  const toggleLightEditMode = () => {
    setIsLightEditMode(!isLightEditMode);
    if (!isLightEditMode) {
      setSelectedLightIndex(null);
    }
  };

  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };

  const handleLightClick = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedLightIndex(index === selectedLightIndex ? null : index);
  };

  const getLightTypeIcon = (type: string) => {
    switch (type) {
      case 'point':
        return <FiSun />;
      case 'directional':
        return <BiSun />;
      case 'hemispheric':
        return <BiMoon />;
      default:
        return <FiSun />;
    }
  };

  return (
    <Draggable handle=".handle" nodeRef={nodeRef}>
      <Container
        ref={nodeRef}
        onDragEnter={handleDragEvent}
        onDragOver={handleDragEvent}
        onDragLeave={handleDragEvent}
        onDrop={handleDragEvent}
      >
        <Header 
          $isCollapsed={isCollapsed} 
          className="handle"
        >
          <Title>
            <FiSun />
            Scene Lighting
          </Title>
   
          <Subtitle>
Effects Meshes Only        
  </Subtitle>
          <ToggleButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
          </ToggleButton>
        </Header>
        <Content $isCollapsed={isCollapsed}>
          <ButtonGroup>
            <Button
              onClick={toggleLightEditMode}
              $variant={isLightEditMode ? "danger" : "primary"}
            >
              {isLightEditMode ? "Exit Light Edit Mode" : "Enter Light Edit Mode"}
            </Button>
          </ButtonGroup>

          {isLightEditMode && (
            <>
              <HelpText>
                <FiInfo size={16} />
                Click on a light to edit it with gizmos. Use the toolbar to add new lights.
              </HelpText>

              <ControlsGroup>
                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Hemispheric Light")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedLightType === "hemispheric"}
                    onClick={() => setSelectedLightType("hemispheric")}
                  >
                    <BiMoon />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Hemispheric Light"}>
                    Hemispheric Light
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Point Light")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedLightType === "point"}
                    onClick={() => setSelectedLightType("point")}
                  >
                    <FiSun />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Point Light"}>
                    Point Light
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Directional Light")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedLightType === "directional"}
                    onClick={() => setSelectedLightType("directional")}
                  >
                    <BiSun />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Directional Light"}>
                    Directional Light
                  </Tooltip>
                </TooltipContainer>

                <Button
                  onClick={() => addLight(selectedLightType)}
                  $variant="primary"
                >
                  <FiSun /> Add Light
                </Button>
              </ControlsGroup>
            </>
          )}

          {isLightEditMode && lights.map((light, index) => (
            <LightItem 
              key={light.id}
              onClick={(e) => handleLightClick(index, e)}
              $isSelected={selectedLightIndex === index}
            >
              <LightLabel>
                {getLightTypeIcon(light.type)}
                {light.name}
              </LightLabel>
              <LightControls>
                {selectedLightIndex === index && (
                  <GizmoControls>
                    <TooltipContainer 
                      onMouseEnter={() => setTooltipText("Move Tool")}
                      onMouseLeave={() => setTooltipText("")}
                    >
                      <GizmoButton
                        $active={activeControl === "move"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveControl("move");
                        }}
                      >
                        <BiMove />
                      </GizmoButton>
                      <Tooltip $show={tooltipText === "Move Tool"}>
                        Move Tool (G)
                      </Tooltip>
                    </TooltipContainer>

                    <TooltipContainer 
                      onMouseEnter={() => setTooltipText("Rotate Tool")}
                      onMouseLeave={() => setTooltipText("")}
                    >
                      <GizmoButton
                        $active={activeControl === "rotate"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveControl("rotate");
                        }}
                      >
                        <BiRotateRight />
                      </GizmoButton>
                      <Tooltip $show={tooltipText === "Rotate Tool"}>
                        Rotate Tool (R)
                      </Tooltip>
                    </TooltipContainer>
                  </GizmoControls>
                )}
                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Remove Light")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeLight(light.id);
                    }}
                    $variant="danger"
                  >
                    Remove
                  </Button>
                  <Tooltip $show={tooltipText === "Remove Light"}>
                    Remove this light
                  </Tooltip>
                </TooltipContainer>
              </LightControls>
            </LightItem>
          ))}

          {isLightEditMode && lights.length === 0 && (
            <HelpText style={{ textAlign: 'center', justifyContent: 'center' }}>
              No lights added yet. Use the toolbar above to add lights.
            </HelpText>
          )}

          {!isLightEditMode && lights.length > 0 && (
            <HelpText style={{ textAlign: 'center', justifyContent: 'center' }}>
              Enter edit mode to modify lights
            </HelpText>
          )}

          {selectedLightIndex !== null && isLightEditMode && (
            <div className="light-properties bg-gray-700 p-4 rounded">
              <h4 className="text-white mb-4">Light Properties</h4>
              
              <div className="mb-4">
                <label className="block text-white mb-2">Name</label>
                <input
                  type="text"
                  value={lights[selectedLightIndex].name}
                  onChange={(e) => {
                    const updatedLights = [...lights];
                    updatedLights[selectedLightIndex] = {
                      ...updatedLights[selectedLightIndex],
                      name: e.target.value
                    };
                    onLightsChange(updatedLights);
                  }}
                  className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-white mb-2">Intensity</label>
                <input
                  type="range"
                  min="0"
                  max="2"
                  step="0.1"
                  value={lights[selectedLightIndex].intensity}
                  onChange={(e) => {
                    const updatedLights = [...lights];
                    updatedLights[selectedLightIndex] = {
                      ...updatedLights[selectedLightIndex],
                      intensity: parseFloat(e.target.value)
                    };
                    onLightsChange(updatedLights);
                  }}
                  className="w-full"
                />
              </div>

              <div className="mb-4">
                <label className="block text-white mb-2">Color</label>
                <input
                  type="color"
                  value={lights[selectedLightIndex].color}
                  onChange={(e) => {
                    const updatedLights = [...lights];
                    updatedLights[selectedLightIndex] = {
                      ...updatedLights[selectedLightIndex],
                      color: e.target.value
                    };
                    onLightsChange(updatedLights);
                  }}
                  className="w-full"
                />
              </div>

              {lights[selectedLightIndex].type === 'hemispheric' && (
                <div className="mb-4">
                  <label className="block text-white mb-2">Ground Color</label>
                  <input
                    type="color"
                    value={lights[selectedLightIndex].groundColor}
                    onChange={(e) => {
                      const updatedLights = [...lights];
                      updatedLights[selectedLightIndex] = {
                        ...updatedLights[selectedLightIndex],
                        groundColor: e.target.value
                      };
                      onLightsChange(updatedLights);
                    }}
                    className="w-full"
                  />
                </div>
              )}

              {(lights[selectedLightIndex].type === 'point' || lights[selectedLightIndex].type === 'spot') && (
                <div className="mb-4">
                  <label className="block text-white mb-2">Range</label>
                  <input
                    type="number"
                    value={lights[selectedLightIndex].range}
                    onChange={(e) => {
                      const updatedLights = [...lights];
                      updatedLights[selectedLightIndex] = {
                        ...updatedLights[selectedLightIndex],
                        range: parseFloat(e.target.value)
                      };
                      onLightsChange(updatedLights);
                    }}
                    className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                  />
                </div>
              )}

              {(lights[selectedLightIndex].type === 'directional' || lights[selectedLightIndex].type === 'spot') && (
                <div className="grid grid-cols-3 gap-2 mb-4">
                  <div>
                    <label className="block text-white mb-2">Direction X</label>
                    <input
                      type="number"
                      value={lights[selectedLightIndex].direction?.x ?? 0}
                      onChange={(e) => {
                        const updatedLights = [...lights];
                        updatedLights[selectedLightIndex] = {
                          ...updatedLights[selectedLightIndex],
                          direction: {
                            ...updatedLights[selectedLightIndex].direction ?? { x: 0, y: 0, z: 0 },
                            x: parseFloat(e.target.value)
                          }
                        };
                        onLightsChange(updatedLights);
                      }}
                      className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-white mb-2">Direction Y</label>
                    <input
                      type="number"
                      value={lights[selectedLightIndex].direction?.y ?? 0}
                      onChange={(e) => {
                        const updatedLights = [...lights];
                        updatedLights[selectedLightIndex] = {
                          ...updatedLights[selectedLightIndex],
                          direction: {
                            ...updatedLights[selectedLightIndex].direction ?? { x: 0, y: 0, z: 0 },
                            y: parseFloat(e.target.value)
                          }
                        };
                        onLightsChange(updatedLights);
                      }}
                      className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-white mb-2">Direction Z</label>
                    <input
                      type="number"
                      value={lights[selectedLightIndex].direction?.z ?? 0}
                      onChange={(e) => {
                        const updatedLights = [...lights];
                        updatedLights[selectedLightIndex] = {
                          ...updatedLights[selectedLightIndex],
                          direction: {
                            ...updatedLights[selectedLightIndex].direction ?? { x: 0, y: 0, z: 0 },
                            z: parseFloat(e.target.value)
                          }
                        };
                        onLightsChange(updatedLights);
                      }}
                      className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                    />
                  </div>
                </div>
              )}

              {lights[selectedLightIndex].type === 'spot' && (
                <>
                  <div className="mb-4">
                    <label className="block text-white mb-2">Angle (radians)</label>
                    <input
                      type="number"
                      step="0.1"
                      value={lights[selectedLightIndex].angle}
                      onChange={(e) => {
                        const updatedLights = [...lights];
                        updatedLights[selectedLightIndex] = {
                          ...updatedLights[selectedLightIndex],
                          angle: parseFloat(e.target.value)
                        };
                        onLightsChange(updatedLights);
                      }}
                      className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-white mb-2">Exponent</label>
                    <input
                      type="number"
                      step="0.1"
                      value={lights[selectedLightIndex].exponent}
                      onChange={(e) => {
                        const updatedLights = [...lights];
                        updatedLights[selectedLightIndex] = {
                          ...updatedLights[selectedLightIndex],
                          exponent: parseFloat(e.target.value)
                        };
                        onLightsChange(updatedLights);
                      }}
                      className="w-full px-2 py-1 bg-gray-600 text-white rounded"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </Content>
      </Container>
    </Draggable>
  );
};

export default LightingControls;
