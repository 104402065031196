/*
File: navigationSystem.ts
Description: Handles camera movement, waypoints, and path following functionality
Created: 2024-02-19
Last Modified: 2024-02-20 - Added hasContent class to waypointInfo for conditional padding
*/

import { GenerateHTMLProps } from '../types';

export const generateNavigationSystem = (props: GenerateHTMLProps): string => {
  const { 
    scrollSpeed, 
    defaultCameraMode,
    cameraDampeningRef,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes,
    loopMode,
    autoPlaySpeed,
    uiOptions
  } = props;

  return `
    // Create control points and rotations from waypoints
    const controlPoints = waypoints.map(
      (wp) => new BABYLON.Vector3(wp.x, wp.y, wp.z)
    );
    const rotations = waypoints.map(
      (wp) => new BABYLON.Quaternion(wp.rotation._x, wp.rotation._y, wp.rotation._z, wp.rotation._w).normalize()
    );

    // Generate path from control points
    let path = [];
    if (controlPoints.length >= 2) {
      const positionCurve = BABYLON.Curve3.CreateCatmullRomSpline(
        controlPoints,
        20,
        false
      );
      path = positionCurve.getPoints();
    } else if (controlPoints.length === 1) {
      path = [controlPoints[0]];
    }

    function changeCameraMode(selectedMode) {
      if (${JSON.stringify(allowedCameraModes)}.includes(selectedMode)) {
        cameraMode = selectedMode;
        userControl = cameraMode === 'explore';

        trackCameraMode(selectedMode);

        if (BABYLON.VirtualJoystick.Canvas) {
          BABYLON.VirtualJoystick.Canvas.style.zIndex =
            cameraMode === 'tour' ? '-10' : '10';
        }

        updateScrollControlsVisibility();
        updateModeToggleUI();
      }
    }

    function updateScrollControlsVisibility() {
      const scrollControlsContent = document.getElementById('scrollControlsContent');
      if (scrollControlsContent) {
        if (cameraMode === 'explore') {
          scrollControlsContent.classList.add('hidden');
        } else {
          scrollControlsContent.classList.remove('hidden');
        }
      }
    }

    function updateModeToggleUI() {
      const modes = ['explore', 'tour', 'hybrid'];
      modes.forEach(function(mode) {
        const button = document.getElementById('mode' + capitalizeFirstLetter(mode));
        if (button) {
          if (cameraMode === mode) {
            button.classList.add('selected');
          } else {
            button.classList.remove('selected');
          }
        }
      });
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function showWaypointInfo(waypoint) {
      const waypointInfo = document.getElementById('waypointInfo');

      if (waypointInfo) {
        waypointInfo.innerHTML = '';
        waypointInfo.classList.remove('hasContent');

        let hasContent = false;

        if (waypoint.name) {
          const nameElement = document.createElement('h3');
          nameElement.style.fontWeight = 'bold';
          nameElement.style.margin = '0 0 8px 0';
          nameElement.style.textAlign = 'center';
          nameElement.style.marginTop = '10px';
          nameElement.textContent = waypoint.name;
          waypointInfo.appendChild(nameElement);
          hasContent = true;
        }

        const infoInteraction = waypoint.interactions.find(
          (interaction) => interaction.type === 'info'
        );

        if (
          infoInteraction &&
          infoInteraction.data &&
          infoInteraction.data.text &&
          '${uiOptions?.infoPosition}' === 'controls'
        ) {
          const descElement = document.createElement('p');
          descElement.style.textAlign = 'center';
          descElement.textContent = infoInteraction.data.text;
          waypointInfo.appendChild(descElement);
          hasContent = true;
        }

        if (hasContent) {
          waypointInfo.classList.add('hasContent');
        }
        waypointInfo.style.display = 'block';
      }
    }

    function updateActiveWaypoint() {
      let closestWaypoint = null;
      let minDistance = Infinity;

      waypoints.forEach((wp, index) => {
        const distance = BABYLON.Vector3.Distance(
          camera.position,
          new BABYLON.Vector3(wp.x, wp.y, wp.z)
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestWaypoint = wp;
        }

        const triggerDistance = wp.triggerDistance ?? 1.0;
        if (distance <= triggerDistance) {
          if (!activeWaypoints.has(index)) {
            activeWaypoints.add(index);
            trackWaypointReached(wp.name || \`Waypoint \${index + 1}\`, index);
            executeInteractions(wp.interactions, index);
          }
        } else {
          if (activeWaypoints.has(index)) {
            activeWaypoints.delete(index);
            reverseInteractions(wp.interactions);
          }
        }
      });

      if (closestWaypoint) {
        showWaypointInfo(closestWaypoint);
      }
    }

    function handleButtonScroll(direction) {
      if (cameraMode === 'explore') {
        return;
      }
      if ('${scrollButtonMode}' === 'percentage') {
        adjustScroll(direction * ${scrollAmount} / 100);
      } else {
        moveToWaypoint(direction);
      }
    }

    function adjustScroll(amount) {
      const pathLength = path.length;
      if (pathLength > 1) {
        const scrollIncrement = (pathLength - 1) * amount;
        scrollTarget += scrollIncrement;

        if (scrollTarget < 0) scrollTarget = 0;
        if (scrollTarget > path.length - 1) scrollTarget = path.length - 1;

        userControl = false;
      }
    }

    function moveToWaypoint(direction) {
      const subdivisionsPerSegment = 20;
      let currentWaypointIndex = Math.round(scrollPosition / subdivisionsPerSegment);
      let nextWaypointIndex = currentWaypointIndex + direction;

      nextWaypointIndex = Math.max(0, Math.min(nextWaypointIndex, waypoints.length - 1));

      scrollTarget = nextWaypointIndex * subdivisionsPerSegment;
      userControl = false;
    }

    function updateScrollUI(percentage) {
      const scrollPercentage = document.getElementById('scrollPercentage');
      const progressBar = document.getElementById('progressBar');
      if (scrollPercentage && progressBar) {
        scrollPercentage.textContent = \`\${Math.round(percentage)}%\`;
        progressBar.style.width = \`\${percentage}%\`;
      }
    }

    window.addEventListener('wheel', (event) => {
      if (cameraMode === 'tour' || cameraMode === 'hybrid') {
        userControl = false;
        scrollTarget += event.deltaY * ${scrollSpeed};
        if (scrollTarget < 0) scrollTarget = 0;
        if (scrollTarget > path.length - 1) {
          scrollTarget = path.length - 1;
        }
        return;
      }

      if (cameraMode === 'explore') {
        userControl = true;
      }
    });

    scene.onPointerObservable.add(function (evt) {
      if (evt.type === BABYLON.PointerEventTypes.POINTERDOWN) {
        if (cameraMode === 'explore' || cameraMode === 'hybrid') {
          userControl = true;
        } else {
          userControl = false;
        }
      }
    });

    window.addEventListener('keydown', function () {
      if (cameraMode === 'explore' || cameraMode === 'hybrid') {
        userControl = true;
      } else {
        userControl = false;
      }
    });
  `;
};
