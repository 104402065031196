import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../utils/FirebaseConfig';
import { User, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp, onSnapshot, collection, query, where, getDocs } from 'firebase/firestore';
import { UserProfile } from '../types/UserTypes';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  userProfile: UserProfile | null;
  hasAcceptedLicense: boolean;
  updateLicenseAcceptance: (accepted: boolean) => Promise<void>;
  getIdToken: () => Promise<string>;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [hasAcceptedLicense, setHasAcceptedLicense] = useState(false);

  async function updateLicenseAcceptance(accepted: boolean) {
    if (!currentUser) {
      throw new Error('No user logged in');
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, {
        hasAcceptedLicense: accepted,
        licenseAcceptedAt: accepted ? new Date() : null
      }, { merge: true });

      // Update local state
      setHasAcceptedLicense(accepted);
      
      // Update userProfile state
      setUserProfile(prev => {
        if (!prev) return null;
        return {
          ...prev,
          hasAcceptedLicense: accepted,
          licenseAcceptedAt: accepted ? new Date() : null
        };
      });
    } catch (error) {
      console.error('Error updating license acceptance:', error);
      throw error;
    }
  }

  // Generate URL-friendly slug from display name
  const generateSlug = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric chars with hyphen
      .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
      .substring(0, 30); // Limit length to 30 chars
  };

  // Check if slug is unique and modify if needed
  const getUniqueSlug = async (baseSlug: string, currentUserId: string): Promise<string> => {
    let currentSlug = baseSlug;
    let counter = 1;
    let isUnique = false;

    while (!isUnique) {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('publicProfile.displayNameSlug', '==', currentSlug),
        where('uid', '!=', currentUserId) // Exclude current user's document
      );
      
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        isUnique = true;
      } else {
        currentSlug = `${baseSlug}-${counter}`;
        counter++;
      }
    }

    return currentSlug;
  };

  async function createUserProfile(user: User) {
    try {
      const userRef = doc(db, 'users', user.uid);
      
      // First check if the user profile already exists
      const userDoc = await getDoc(userRef);
      const existingData = userDoc.data();

      // Generate a unique slug from display name
      const baseSlug = generateSlug(user.displayName || 'user');
      const uniqueSlug = await getUniqueSlug(baseSlug, user.uid);

      // Create the new profile data, preserving existing data
      const profileData: Partial<UserProfile> = {
        uid: user.uid,
        email: user.email || '',
        displayName: user.displayName || '',
        createdAt: existingData?.createdAt || serverTimestamp(),
        storageUsed: existingData?.storageUsed || 0,
        maxStorage: existingData?.maxStorage || 5,
        maxStorageBytes: existingData?.maxStorageBytes || (250 * 1024 * 1024), // 250MB default for new users
        hasAcceptedLicense: existingData?.hasAcceptedLicense || false,
        licenseAcceptedAt: existingData?.licenseAcceptedAt || null,
        splats: existingData?.splats || [],
        isPro: existingData?.isPro || false,
        publicProfile: {
          ...(existingData?.publicProfile || {}), // Preserve all existing publicProfile data
          displayName: existingData?.publicProfile?.displayName || user.displayName || '',
          displayNameSlug: existingData?.publicProfile?.displayNameSlug || uniqueSlug,
          isPublic: existingData?.publicProfile?.isPublic ?? false // Preserve existing isPublic or default to false for new users
        }
      };

      // Use merge: true to preserve any existing data not specified above
      await setDoc(userRef, profileData, { merge: true });
      
      // Update the license acceptance state
      setHasAcceptedLicense(profileData.hasAcceptedLicense || false);
      
      console.log('User profile created/updated successfully');

    } catch (error) {
      console.error('Error creating/updating user profile:', error);
      throw error;
    }
  }

  async function ensureUserHasSlug(user: User, currentProfile: UserProfile) {
    // Check if the user's public profile exists and has a slug
    if (!currentProfile.publicProfile?.displayNameSlug) {
      try {
        const baseSlug = generateSlug(user.displayName || 'user');
        const uniqueSlug = await getUniqueSlug(baseSlug, user.uid);
        
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          publicProfile: {
            ...(currentProfile.publicProfile || {}), // Preserve all existing publicProfile fields
            displayName: currentProfile.publicProfile?.displayName || user.displayName || '',
            displayNameSlug: uniqueSlug
          }
        }, { merge: true });
        
        console.log('Added missing slug for existing user:', uniqueSlug);
      } catch (error) {
        console.error('Error ensuring user has slug:', error);
      }
    }
  }

  async function signIn() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      if (!result.user) {
        throw new Error('No user data returned from auth');
      }

      await createUserProfile(result.user);
    } catch (error) {
      console.error('Error during sign in:', error);
      throw error;
    }
  }

  async function signOut() {
    try {
      await auth.signOut();
      setUserProfile(null);
      setHasAcceptedLicense(false);
    } catch (error) {
      console.error('Error during sign out:', error);
      throw error;
    }
  }

  async function getIdToken(): Promise<string> {
    if (!currentUser) {
      throw new Error('No user logged in');
    }
    return currentUser.getIdToken();
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.uid);
      setCurrentUser(user);
      setLoading(true);

      if (user) {
        // Set up real-time listener for user profile
        const userRef = doc(db, 'users', user.uid);
        
        const unsubscribeDoc = onSnapshot(
          userRef,
          async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data() as UserProfile;
              console.log('User profile updated:', data);
              
              // Ensure user has a slug
              await ensureUserHasSlug(user, data);
              
              setUserProfile(data);
              setHasAcceptedLicense(data.hasAcceptedLicense || false);
            } else {
              // If no profile exists, create one
              console.log('Creating new user profile');
              await createUserProfile(user);
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error listening to user profile:', error);
            setLoading(false);
          }
        );

        return () => {
          unsubscribeDoc();
        };
      } else {
        setUserProfile(null);
        setHasAcceptedLicense(false);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
    loading,
    signIn,
    signOut,
    userProfile,
    hasAcceptedLicense,
    updateLicenseAcceptance,
    getIdToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
