/*
File: useSceneFiles.ts
Description: Hook for managing scene file operations, including saving, loading, and exporting
Last modified: 2024-02-14
Changes: 
- Added ensureThumbnail to UseSceneFilesProps interface
- Added thumbnailBlob handling in upload process
- Improved error handling and feedback
- Updated UIOptions to use UIType enum
*/

import { useCallback } from "react";
import * as BABYLON from "@babylonjs/core";
import { SaveFile, SceneConfig, SerializedMeshData, UIOptions, SceneLight, CameraMode, XRMode, AROptions, SceneType } from "../types/SceneTypes";
import { generateExportedHTML } from "../tools/GenerateExportedHtml";
import { Hotspot } from '../../src/types/SceneTypes';
import { toast } from "react-toastify";
import { UIType } from "../tools/html-generation/types";

// Default light configuration
const DEFAULT_LIGHT: SceneLight = {
  id: "default",
  type: "hemispheric",
  name: "Default Light",
  intensity: 1,
  color: "#ffffff",
  position: { x: 0, y: 1, z: 0 },
  groundColor: "#000000",
  enabled: true
};

// Helper function to convert Vector3 to number array
const fromVector3 = (vector: BABYLON.Vector3): [number, number, number] => {
  return [vector.x, vector.y, vector.z];
};

// Helper function to convert number array to object format for export
const toExportFormat = (arr: [number, number, number]) => {
  return { _x: arr[0], _y: arr[1], _z: arr[2] };
};

interface UseSceneFilesProps {
  scrollSpeed: number;
  animationFrames: number;
  cameraMovementSpeed: number;
  cameraRotationSensitivity: number;
  backgroundColor: string;
  waypoints: any[];
  loadedModelUrl: string | null;
  hotspots: Hotspot[];
  uiColor: string;
  transitionSpeed: number;
  scrollButtonMode: "percentage" | "waypoint";
  scrollAmount: number;
  collisionMeshesRef: React.MutableRefObject<BABYLON.Mesh[]>;
  allowedCameraModes: string[];
  loopMode: boolean;
  autoPlayEnabled: boolean;
  autoPlaySpeed: number;
  uiOptions: {
    infoPosition: 'popup' | 'controls';
    buttonPosition: "inline" | "below";
    showStartExperience?: boolean;
    debugMode?: boolean;
    uiType: UIType;
    hideWatermark?: boolean;
  };
  sceneTitle: string;
  collisionMeshesData: SerializedMeshData[];
  setScrollSpeed: (speed: number) => void;
  setAnimationFrames: (frames: number) => void;
  setCameraMovementSpeed: (speed: number) => void;
  setCameraRotationSensitivity: (sensitivity: number) => void;
  setBackgroundColor: (color: string) => void;
  setWaypoints: (waypoints: any[]) => void;
  setLoadedModelUrl: (url: string | null) => void;
  setHotspots: (hotspots: Hotspot[]) => void;
  setUiColor: (color: string) => void;
  setTransitionSpeed: (speed: number) => void;
  setScrollButtonMode: (mode: "percentage" | "waypoint") => void;
  setScrollAmount: (amount: number) => void;
  setAllowedCameraModes: (modes: string[]) => void;
  setLoopMode: (mode: boolean) => void;
  setAutoPlayEnabled: (enabled: boolean) => void;
  setAutoPlaySpeed: (speed: number) => void;
  setUIOptions: (options: UIOptions) => void;
  setCurrentLoadedSceneId: (id: string | null) => void;
  setCollisionMeshes: (meshes: BABYLON.Mesh[]) => void;
  setCollisionMeshesData: (meshes: SerializedMeshData[]) => void;
  openWaypointsMenu?: () => void;
  setSceneTitle: (title: string) => void;
  lights: SceneLight[];
  setLights: (lights: SceneLight[]) => void;
  thumbnailBlob?: Blob | null;
  ensureThumbnail?: () => Promise<Blob | null>;
}

export const useSceneFiles = ({
  scrollSpeed,
  animationFrames,
  cameraMovementSpeed,
  cameraRotationSensitivity,
  backgroundColor,
  waypoints,
  loadedModelUrl,
  hotspots,
  uiColor,
  transitionSpeed,
  scrollButtonMode,
  scrollAmount,
  collisionMeshesRef,
  allowedCameraModes,
  loopMode,
  autoPlayEnabled,
  autoPlaySpeed,
  uiOptions,
  sceneTitle,
  collisionMeshesData,
  setScrollSpeed,
  setAnimationFrames,
  setCameraMovementSpeed,
  setCameraRotationSensitivity,
  setBackgroundColor,
  setWaypoints,
  setLoadedModelUrl,
  setHotspots,
  setUiColor,
  setTransitionSpeed,
  setScrollButtonMode,
  setScrollAmount,
  setAllowedCameraModes,
  setLoopMode,
  setAutoPlayEnabled,
  setAutoPlaySpeed,
  setUIOptions,
  setCurrentLoadedSceneId,
  setCollisionMeshes,
  setCollisionMeshesData,
  openWaypointsMenu,
  setSceneTitle,
  lights,
  setLights
}: UseSceneFilesProps) => {
  const saveToJson = useCallback(() => {
    // Get collision mesh data from the three-node hierarchy
    const collisionMeshesData = collisionMeshesRef.current.map((mesh) => {
      // Get the parent nodes (scalingNode and rotationNode)
      const scalingNode = mesh.parent as BABYLON.TransformNode;
      const rotationNode = scalingNode?.parent as BABYLON.TransformNode;

      // If we have the proper hierarchy, use it. Otherwise, fall back to mesh properties
      return {
        meshData: mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind),
        position: rotationNode ? fromVector3(rotationNode.position) : fromVector3(mesh.position),
        rotation: rotationNode ? fromVector3(rotationNode.rotation) : fromVector3(mesh.rotation),
        scaling: scalingNode ? fromVector3(scalingNode.scaling) : fromVector3(mesh.scaling),
        meshType: (mesh.name.includes('sphere') ? 'sphere' : 
                  mesh.name.includes('cube') ? 'cube' : 
                  mesh.name.includes('custom') ? 'custom' : 'plane') as "sphere" | "cube" | "custom" | "plane"
      };
    });

    const saveData: SaveFile = {
      scrollSpeed,
      animationFrames,
      cameraMovementSpeed,
      cameraRotationSensitivity,
      backgroundColor,
      waypoints,
      loadedModelUrl,
      hotspots,
      uiColor,
      transitionSpeed,
      scrollButtonMode,
      scrollAmount,
      collisionMeshesData,
      allowedCameraModes,
      loopMode,
      autoPlayEnabled,
      autoPlaySpeed,
      uiOptions,
      sceneTitle,
      lights: lights.length > 0 ? lights : [DEFAULT_LIGHT]
    };

    const jsonString = JSON.stringify(saveData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = sceneTitle + ".json";
    a.click();
    URL.revokeObjectURL(url);

    return saveData;
  }, [
    scrollSpeed,
    animationFrames,
    cameraMovementSpeed,
    cameraRotationSensitivity,
    backgroundColor,
    waypoints,
    loadedModelUrl,
    hotspots,
    uiColor,
    transitionSpeed,
    scrollButtonMode,
    scrollAmount,
    collisionMeshesRef,
    allowedCameraModes,
    loopMode,
    autoPlayEnabled,
    autoPlaySpeed,
    uiOptions,
    sceneTitle,
    lights
  ]);

  const saveToJsonNoDownload = useCallback(() => {
    // Get collision mesh data from the three-node hierarchy
    const collisionMeshesData = collisionMeshesRef.current.map((mesh) => {
      // Get the parent nodes (scalingNode and rotationNode)
      const scalingNode = mesh.parent as BABYLON.TransformNode;
      const rotationNode = scalingNode?.parent as BABYLON.TransformNode;

      // If we have the proper hierarchy, use it. Otherwise, fall back to mesh properties
      return {
        meshData: mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind),
        position: rotationNode ? fromVector3(rotationNode.position) : fromVector3(mesh.position),
        rotation: rotationNode ? fromVector3(rotationNode.rotation) : fromVector3(mesh.rotation),
        scaling: scalingNode ? fromVector3(scalingNode.scaling) : fromVector3(mesh.scaling),
        meshType: (mesh.name.includes('sphere') ? 'sphere' : 
                  mesh.name.includes('cube') ? 'cube' : 
                  mesh.name.includes('custom') ? 'custom' : 'plane') as "sphere" | "cube" | "custom" | "plane"
      };
    });

    const saveData: SaveFile = {
      scrollSpeed,
      animationFrames,
      cameraMovementSpeed,
      cameraRotationSensitivity,
      backgroundColor,
      waypoints,
      loadedModelUrl,
      hotspots,
      uiColor,
      transitionSpeed,
      scrollButtonMode,
      scrollAmount,
      collisionMeshesData,
      allowedCameraModes,
      loopMode,
      autoPlayEnabled,
      autoPlaySpeed,
      uiOptions,
      sceneTitle,
      lights: lights.length > 0 ? lights : [DEFAULT_LIGHT]
    };

    return saveData;
  }, [
    scrollSpeed,
    animationFrames,
    cameraMovementSpeed,
    cameraRotationSensitivity,
    backgroundColor,
    waypoints,
    loadedModelUrl,
    hotspots,
    uiColor,
    transitionSpeed,
    scrollButtonMode,
    scrollAmount,
    collisionMeshesRef,
    allowedCameraModes,
    loopMode,
    autoPlayEnabled,
    autoPlaySpeed,
    uiOptions,
    sceneTitle,
    lights
  ]);

  const loadSaveData = useCallback(
    (saveData: SaveFile, sceneId?: string) => {
      console.log("Load save data:", saveData);
      try {
        // Validate saveData structure
        if (!saveData || typeof saveData !== 'object') {
          throw new Error('Invalid save data format');
        }

        // Load and validate lights data
        if (Array.isArray(saveData.lights) && saveData.lights.length > 0) {
          // Validate each light object
          const validatedLights = saveData.lights.map(light => ({
            id: light.id || "default",
            type: light.type || "hemispheric",
            name: light.name || "Default Light",
            intensity: typeof light.intensity === 'number' ? light.intensity : 1,
            color: light.color || "#ffffff",
            position: {
              x: typeof light.position?.x === 'number' ? light.position.x : 0,
              y: typeof light.position?.y === 'number' ? light.position.y : 1,
              z: typeof light.position?.z === 'number' ? light.position.z : 0
            },
            groundColor: light.groundColor || "#000000",
            enabled: typeof light.enabled === 'boolean' ? light.enabled : true
          }));
          setLights(validatedLights);
        } else {
          // Use default light if no valid lights data
          setLights([DEFAULT_LIGHT]);
        }

        // Ensure waypoints exists and is an array
        const waypointsToLoad = Array.isArray(saveData.waypoints) ? saveData.waypoints : [];
        const hotspotsToLoad = Array.isArray(saveData.hotspots) ? saveData.hotspots : [];

        // Reconstruct waypoints with validation
        const reconstructedWaypoints = waypointsToLoad.map((wp) => {
          if (!wp.rotation) {
            wp.rotation = new BABYLON.Quaternion(0, 0, 0, 1);
          } else {
            wp.rotation = new BABYLON.Quaternion(
              wp.rotation._x ?? 0,
              wp.rotation._y ?? 0,
              wp.rotation._z ?? 0,
              wp.rotation._w ?? 1
            ).normalize();
          }
          return { ...wp };
        });

        // Reconstruct hotspots with proper vector handling
        const reconstructedHotspots = hotspotsToLoad.map((h) => {
          // Handle position vector
          const position = new BABYLON.Vector3(
            h.position?._x ?? h.position?.x ?? 0,
            h.position?._y ?? h.position?.y ?? 0,
            h.position?._z ?? h.position?.z ?? 0
          );

          // Handle scale vector
          const scale = new BABYLON.Vector3(
            h.scale?._x ?? h.scale?.x ?? 1,
            h.scale?._y ?? h.scale?.y ?? 1,
            h.scale?._z ?? h.scale?.z ?? 1
          );

          // Handle rotation vector
          const rotation = new BABYLON.Vector3(
            h.rotation?._x ?? h.rotation?.x ?? 0,
            h.rotation?._y ?? h.rotation?.y ?? 0,
            h.rotation?._z ?? h.rotation?.z ?? 0
          );

          return {
            ...h,
            position,
            scale,
            rotation
          };
        });

        // Set values with fallbacks to defaults
        setScrollSpeed(saveData.scrollSpeed ?? 0.1);
        setAnimationFrames(saveData.animationFrames ?? 120);
        setCameraMovementSpeed(saveData.cameraMovementSpeed ?? 0.2);
        setCameraRotationSensitivity(saveData.cameraRotationSensitivity ?? 4000);
        setBackgroundColor(saveData.backgroundColor ?? "#7D7D7D");
        setUiColor(saveData.uiColor ?? "#000000");
        setWaypoints(reconstructedWaypoints);
        setLoadedModelUrl(saveData.loadedModelUrl ?? null);
        setHotspots(reconstructedHotspots);
        setTransitionSpeed(saveData.transitionSpeed ?? 0.5);
        setScrollButtonMode(saveData.scrollButtonMode ?? "waypoint");
        setScrollAmount(saveData.scrollAmount ?? 10);
        setAllowedCameraModes(saveData.allowedCameraModes ?? ["tour", "explore", "auto"]);
        setLoopMode(saveData.loopMode ?? false);
        setAutoPlaySpeed(saveData.autoPlaySpeed ?? 0.05);
        setAutoPlayEnabled(saveData.autoPlayEnabled ?? false);
        console.log("Load save data: sceneTitle", saveData.sceneTitle);
        setSceneTitle(saveData.sceneTitle ?? "Untitled Scene");
        
        if (Array.isArray(saveData.collisionMeshesData)) {
          setCollisionMeshesData(saveData.collisionMeshesData);
        } else {
          setCollisionMeshesData([]);
        }

        if (saveData.uiOptions) {
          setUIOptions({
            infoPosition: saveData.uiOptions.infoPosition ?? "popup",
            buttonPosition: saveData.uiOptions.buttonPosition ?? "inline",
            showStartExperience: saveData.uiOptions.showStartExperience ?? false,
            debugMode: saveData.uiOptions.debugMode ?? false,
            uiType: saveData.uiOptions.uiType ?? UIType.Standard,
            hideWatermark: saveData.uiOptions.hideWatermark ?? false
          });
        } else {
          // Set default UI options if none exist
          setUIOptions({
            infoPosition: "popup",
            buttonPosition: "inline",
            showStartExperience: false,
            debugMode: false,
            uiType: UIType.Standard,
            hideWatermark: false
          });
        }

        // Set the currentLoadedSceneId if provided
        if (sceneId) {
          setCurrentLoadedSceneId(sceneId);
        }

        // If waypoints exist and openWaypointsMenu callback is provided, call it
        if (reconstructedWaypoints.length > 0 && openWaypointsMenu) {
          openWaypointsMenu();
        }
      } catch (error) {
        console.error("Error loading save data:", error);
        alert("Error loading save data. Please make sure it's a valid save file.");
      }
    },
    [
      setScrollSpeed,
      setAnimationFrames,
      setCameraMovementSpeed,
      setCameraRotationSensitivity,
      setBackgroundColor,
      setUiColor,
      setWaypoints,
      setLoadedModelUrl,
      setHotspots,
      setTransitionSpeed,
      setScrollButtonMode,
      setScrollAmount,
      setAllowedCameraModes,
      setLoopMode,
      setAutoPlaySpeed,
      setAutoPlayEnabled,
      setUIOptions,
      setCurrentLoadedSceneId,
      setCollisionMeshesData,
      openWaypointsMenu,
      setSceneTitle,
      setLights
    ]
  );

  const loadFromJson = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const file = event.target.files?.[0];
        if (!file) {
          throw new Error('No file selected');
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const content = e.target?.result;
            if (typeof content !== 'string') {
              throw new Error('Invalid file content');
            }

            const saveData = JSON.parse(content) as SaveFile;
            loadSaveData(saveData);
          } catch (error) {
            console.error("Error parsing save data:", error);
            alert("Error loading save data. Please make sure it's a valid save file.");
          }
        };

        reader.onerror = () => {
          throw new Error('Error reading file');
        };

        reader.readAsText(file);
      } catch (error) {
        console.error("Error loading save data:", error);
        alert("Error loading save data. Please make sure it's a valid save file.");
      }
    },
    [loadSaveData]
  );

  const handleExportConfirm = useCallback(
    (
      modelUrl: string,
      includeScrollControls: boolean,
      defaultCameraMode: CameraMode,
      allowedCameraModes: string[],
      includeXR: boolean,
      xrMode: XRMode,
      arOptions: AROptions,
      newUIOptions: typeof uiOptions,
      currentUser: { uid: string; displayName: string },
      sceneId: string,
      collisionMeshesData: SerializedMeshData[],
      sceneType: SceneType
    ) => {
      const htmlContent = generateExportedHTML(
        modelUrl,
        includeScrollControls,
        waypoints,
        backgroundColor,
        cameraMovementSpeed,
        cameraRotationSensitivity,
        scrollSpeed,
        animationFrames,
        hotspots,
        defaultCameraMode,
        0.05, // cameraDampening
        uiColor,
        transitionSpeed,
        scrollButtonMode,
        scrollAmount,
        allowedCameraModes,
        loopMode,
        autoPlaySpeed,
        autoPlayEnabled,
        collisionMeshesData,
        includeXR,
        xrMode,
        arOptions,
        newUIOptions,
        sceneTitle,
        sceneId || "",
        currentUser.uid,
        currentUser.displayName,
        lights.length > 0 ? lights : [DEFAULT_LIGHT],
        sceneType // sceneType is optional
      );

      const blob = new Blob([htmlContent], { type: "text/html" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_scene.html";
      a.click();
      URL.revokeObjectURL(url);
    },
    [
      waypoints,
      backgroundColor,
      cameraMovementSpeed,
      cameraRotationSensitivity,
      scrollSpeed,
      animationFrames,
      hotspots,
      uiColor,
      transitionSpeed,
      scrollButtonMode,
      scrollAmount,
      loopMode,
      autoPlaySpeed,
      autoPlayEnabled,
      sceneTitle,
      collisionMeshesRef,
      lights
    ]
  );

  return {
    saveToJsonNoDownload,
    saveToJson,
    loadFromJson,
    loadSaveData,
    handleExportConfirm,
  };
};
