/*
File: src/pages/SubscriptionCancel.tsx
Description: This file contains the Subscription Cancel page component.
Changes: 2024-01-09 - Modified to render as overlay instead of full page replacement
*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.95);
  z-index: 100000;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  color: white;
`;

const CancelIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 30px;
  background-color: #dc3545;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
`;

const Message = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin: 20px 0;
  color: #ddd;
`;

const RetryButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const SubscriptionCancel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Overlay>
      <Container>
        <CancelIcon>
          <FiX />
        </CancelIcon>
        <h1>Upgrade Cancelled</h1>
        <Message>
          Your upgrade process was cancelled. No charges have been made to your account.
        </Message>
        <Message>
          If you experienced any issues during the process, please feel free to try again
          or contact our support team.
        </Message>
        <RetryButton onClick={() => navigate('/upgrade-to-pro')}>
          Try Again
        </RetryButton>
      </Container>
    </Overlay>
  );
};

export default SubscriptionCancel;
