import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  /* Only hide overflow for the canvas/scene, not for modals and UI elements */
  & > canvas {
    overflow: hidden;
  }
`;

interface SceneContainerProps {
  children: React.ReactNode;
}

const SceneContainer: React.FC<SceneContainerProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default SceneContainer;
