/*
File: template-pro.ts
Description: Professional template with bottom navigation and top info layout
Created: 2024-02-14
Last Modified: 2024-02-19 - Added condition to hide scroll controls in explore mode
*/

import { GenerateHTMLProps } from './types';
import { generateStyles } from './styles';
import { generateWaypointInteractionCode } from './waypointInteractions';
import { generateAnalyticsCode } from './analytics';
import { generateMainScript } from './mainScript';
import { isMobileDevice } from './helpers';

const generateWatermark = (userName: string, sceneId: string): string => {
  return `
    <div class="storysplat-watermark">
      Created with <a href="https://storysplat.com?ref=${sceneId}" target="_blank">StorySplat</a>
    </div>
  `;
};

export const generateTemplate = (props: GenerateHTMLProps): string => {
  const {
    includeScrollControls,
    uiOptions,
    uiColor,
    name,
    sceneId,
    userId,
    userName,
    defaultCameraMode,
    autoPlayEnabled,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes
  } = props;

  const showStartExperience = uiOptions.showStartExperience;

  return `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>${name}</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  <style>
    ${generateStyles({
      uiColor,
      isMobileDevice: isMobileDevice(),
      uiOptions
    })}

    /* Pro template specific styles */
    .storysplat-watermark {
      position: fixed;
      bottom: 10px;
      right: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      z-index: 1000;
      pointer-events: none;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    }

    .storysplat-watermark a {
      color: ${uiColor};
      text-decoration: none;
      pointer-events: auto;
    }

    #helpButton {
      position: fixed;
      top: 10px;
      left: 10px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      transition: all 0.2s ease;
    }

    #helpButton:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    #helpPanel {
      position: fixed;
      top: 55px;
      left: 10px;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 20px;
      border-radius: 8px;
      max-width: 320px;
      z-index: 999;
      display: none;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    }

    #helpPanel.visible {
      display: block;
      animation: fadeIn 0.2s ease;
    }

    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(-5px); }
      to { opacity: 1; transform: translateY(0); }
    }

    #helpPanel h3 {
      margin: 0 0 15px 0;
      font-size: 16px;
      font-weight: 500;
      color: white;
    }

    #helpPanel p {
      margin: 8px 0;
      line-height: 1.4;
      font-size: 14px;
      opacity: 0.9;
    }

    #helpPanel strong {
      color: ${uiColor};
      font-weight: 500;
    }

    #waypointInfo {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 0px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      z-index: 100;
    }
    #waypointInfo.hasContent {
      padding: 15px;
    }

    #scrollControls {
      position: fixed;
      flex-direction: row;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      padding: 15px;
      display: none; /* Hidden by default */
      justify-content: space-between;
      align-items: center;
      z-index: 100;
    }

    #scrollControls.visible {
      display: flex;
    }

    #scrollControlsContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;
    }

    #progressBarContainer {
      width: 100%;
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
      margin: 10px 0;
    }

    #progressBar {
      height: 100%;
      background: ${uiColor};
      transition: width 0.3s ease;
    }

    #scrollPercentage {
      color: white;
      font-size: 14px;
      opacity: 0.9;
    }

    #prevButton, #nextButton {
      background: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.2s ease;
      font-size: 14px;
      min-width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    #prevButton:hover, #nextButton:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    .button-icon {
      font-size: 16px;
      opacity: 0.8;
    }

    #modeToggleContainer {
      position: fixed;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 100;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .mode-button {
      background: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 13px;
      text-align: center !important;
      min-width: 80px;
    }

    .mode-button:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    .mode-button.selected {
      background: ${uiColor};
    }

    #fullscreenButton {
      position: fixed;
      top: 10px;
      right: 10px;
      width: 36px;
      height: 36px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 4px;
      cursor: pointer;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s ease;
    }

    #fullscreenButton:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    #fullscreenButton svg {
      width: 20px;
      height: 20px;
      fill: white;
      opacity: 0.9;
    }

    #muteButton {
      position: fixed;
      bottom: 10px;
      left: 10px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 13px;
      cursor: pointer;
      z-index: 1000;
      transition: background 0.2s ease;
    }

    #muteButton:hover {
      background: rgba(0, 0, 0, 0.7);
    }

    @media (max-width: 768px) {
      #scrollControls {
        padding: 10px;
      }

      #scrollControlsContent {
        margin: 0 60px;
      }

      #prevButton, #nextButton {
        padding: 6px 12px;
        min-width: 60px;
        font-size: 13px;
      }

      .mode-button {
        padding: 6px 10px;
        font-size: 12px;
        min-width: 70px;
      }

      #waypointInfo {
        padding: 12px;
        font-size: 14px;
      }
    }
  </style>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-L2NG77PKGG"></script>
  <script>
    ${generateAnalyticsCode({
      sceneId,
      userId,
      sceneName: name,
      userName
    })}
  </script>
</head>
<body>
  <button id="helpButton" title="Toggle Help">?</button>
  <div id="helpPanel">
    <h3>Controls & Help</h3>
    <p><strong>Camera Modes:</strong></p>
    <p>• Tour - Follow predefined path</p>
    <p>• Explore - Free movement</p>
    <p>• Hybrid - Mix of both</p>
    <p><strong>Navigation:</strong></p>
    <p>• Mouse/Touch - Look around</p>
    <p>• WASD/Arrows - Move camera</p>
    <p>• Q/E - Move up/down</p>
    <p>• Scroll - Move along path</p>
  </div>

  <script>
    window.cameraMode = '${defaultCameraMode}';
    window.autoPlayEnabled = ${autoPlayEnabled};
  </script>

  <div id="preloader">
    <div id="preloader-content">
      <div id="preloader-media">
        <img id="preloader-image" src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fimages%2FStorySplat.webp?alt=media&token=953e8ab3-1865-4ac1-a98d-b548b7066bda" alt="StorySplat Logo" />
        <lottie-player id="preloader-lottie" 
          src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Flotties%2FstorySplatLottie.json?alt=media&token=d7edc19d-9cb8-4c6e-a94c-cba1d2b65d5e"
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
      </div>
    </div>
  </div>

  <button id="fullscreenButton" title="Toggle Fullscreen">
    <svg id="expandIcon" viewBox="0 0 24 24">
      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
    </svg>
    <svg id="compressIcon" viewBox="0 0 24 24" style="display: none;">
      <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>
    </svg>
  </button>

  ${showStartExperience ? `
  <div id="startButtonContainer">
    <button id="startButton">Start Experience</button>
  </div>
  ` : ''}

  <canvas id="renderCanvas"></canvas>

  <div id="hotspotContent"></div>
  <div id="infoPopup"></div>
  
  <div id="waypointInfo"></div>

  <div id="modeToggleContainer">
    ${allowedCameraModes.includes('explore') ? 
      `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>` : ''}
    ${allowedCameraModes.includes('tour') ? 
      `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>` : ''}
    ${allowedCameraModes.includes('hybrid') ? 
      `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>` : ''}
  </div>

  ${includeScrollControls ? `
    <div id="scrollControls">
      <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
        <span class="button-icon">←</span>
        <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
      </button>

      <div id="scrollControlsContent">
        <div id="scrollPercentage">0%</div>
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
      </div>

      <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
        <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
        <span class="button-icon">→</span>
      </button>
    </div>
  ` : ''}

  <button id="muteButton">🔊 Mute</button>

  ${!uiOptions.hideWatermark ? generateWatermark(userName, sceneId) : ''}

  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylon-7.38.0.js?alt=media&token=848987a3-68a6-47b5-8f7b-0e42185b7a09" onerror="this.onerror=null;this.src='https://cdn.babylonjs.com/babylon.js'"></script>
  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylonjs.loaders.min.js?alt=media&token=789d9d2f-c308-419c-9899-2650e62bdae1" onerror="this.onerror=null;this.src='https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js'"></script>

  <script>
    ${generateWaypointInteractionCode({ uiOptions })}
    ${generateMainScript(props)}

    const helpButton = document.getElementById('helpButton');
    const helpPanel = document.getElementById('helpPanel');
    let helpVisible = false;

    helpButton.addEventListener('click', () => {
      helpVisible = !helpVisible;
      helpPanel.classList.toggle('visible', helpVisible);
    });

    document.addEventListener('click', (event) => {
      if (helpVisible && !helpPanel.contains(event.target) && event.target !== helpButton) {
        helpVisible = false;
        helpPanel.classList.remove('visible');
      }
    });

    function updateCameraModeButtons() {
      const buttons = document.querySelectorAll('.mode-button');
      buttons.forEach(button => {
        const mode = button.id.replace('mode', '').toLowerCase();
        button.classList.toggle('selected', mode === window.cameraMode);
      });

      // Update scroll controls visibility based on camera mode
      const scrollControls = document.getElementById('scrollControls');
      if (scrollControls) {
        scrollControls.classList.toggle('visible', window.cameraMode !== 'explore');
      }
    }

    const originalChangeCameraMode = window.changeCameraMode;
    window.changeCameraMode = function(mode) {
      originalChangeCameraMode(mode);
      updateCameraModeButtons();
    };

    updateCameraModeButtons();
  </script>
</body>
</html>
  `;
};
