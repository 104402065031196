export const VERSION = '1.3.5.3';

export const VERSION_CHANGES = [
  'Added Support for SPZ and Spherical Harmonics',
  'UI Improvements',
' Upgrades to the hotspot system (more to come)',
'Save more export settings with saved splats',
'Comment System Fixed ',
'Bug Fixes'
];
