/*
File: waypointInteractions.ts
Description: Manages waypoint interactions including audio and info popups
Last modified: 2024-02-22 16:30
Changes: 
- Enhanced stop on exit functionality for spatial audio
- Improved audio interaction handling for spatial sounds
- Added audio analytics tracking
*/

interface WaypointInteractionsProps {
  uiOptions: {
    infoPosition: 'popup' | 'controls';
    buttonPosition: "inline" | "below";
    showStartExperience?: boolean;
  };
}

export const generateWaypointInteractionCode = ({
  uiOptions
}: WaypointInteractionsProps): string => {
  const showStartExperience = uiOptions.showStartExperience;

  return `
// Simplified AudioManager for exported HTML
class AudioManager {
  isMuted;
  activeSounds;

  constructor() {
    this.isMuted = false;
    this.activeSounds = new Map();
  }

  playSound(audioData, options = {}) {
    if (typeof this.isMuted === 'undefined') {
      this.isMuted = false;
    }

    if (this.isMuted) return;

    const { 
      scene, 
      position, 
      waypointIndex 
    } = options;

    const id = audioData.id;
    const url = audioData.url;

    // Check if sound is already playing
    const existingSound = this.activeSounds.get(id);
    if (existingSound) {
      if (existingSound.isPlaying) {
        return;
      }

      existingSound.dispose();
      this.activeSounds.delete(id);
    }

    const sound = new BABYLON.Sound(
      id,
      url,
      scene,
      () => {
        if (BABYLON.Engine.audioEngine.audioContext.state === 'suspended') {
          BABYLON.Engine.audioEngine.audioContext.resume().then(() => {
            if (!sound.isPlaying) {
              sound.play();
              // Track audio start
              trackAudioInteraction(id, 'play');
            }
          });
        } else {
          if (!sound.isPlaying) {
            sound.play();
            // Track audio start
            trackAudioInteraction(id, 'play');
          }
        }
      },
      {
        loop: audioData.loop !== undefined ? audioData.loop : true,
        volume: audioData.volume !== undefined ? audioData.volume : 1,
        spatialSound: audioData.spatialSound !== undefined ? audioData.spatialSound : false,
        distanceModel: audioData.distanceModel || "exponential",
        maxDistance: audioData.maxDistance || 100,
        refDistance: audioData.refDistance || 1,
        rolloffFactor: audioData.rolloffFactor || 1,
      }
    );

    sound.metadata = {
      stopOnExit: audioData.stopOnExit,
      spatialSound: audioData.spatialSound
    };

    if (audioData.spatialSound && position) {
      sound.setPosition(position);
    }

    this.activeSounds.set(id, sound);
  }

  stopSound(id) {
    const sound = this.activeSounds.get(id);
    if (sound) {
      sound.stop();
      sound.dispose();
      this.activeSounds.delete(id);
      // Track audio stop
      trackAudioInteraction(id, 'stop');
    }
  }

  stopAllSounds() {
    this.activeSounds.forEach((sound, id) => {
      sound.stop();
      sound.dispose();
      // Track each audio stop
      trackAudioInteraction(id, 'stop');
    });
    this.activeSounds.clear();
  }

  isSoundPlaying(id) {
    const sound = this.activeSounds.get(id);
    return sound ? sound.isPlaying : false;
  }

  mute() {
    this.isMuted = true;
    this.activeSounds.forEach((sound, id) => {
      if (sound.isPlaying) {
        sound.pause();
        // Track audio mute
        trackAudioInteraction(id, 'mute');
      }
    });
  }

  unmute() {
    this.isMuted = false;
    this.activeSounds.forEach((sound, id) => {
      sound.play();
      // Track audio unmute
      trackAudioInteraction(id, 'unmute');
    });
  }
}

// Initialize AudioManager
const audioManager = new AudioManager();

function playAudio(interactionData, waypointIndex) {
  audioManager.playSound(
    { ...interactionData, id: interactionData.id }, 
    { 
      scene, 
      position: waypointIndex !== undefined && waypoints[waypointIndex] 
        ? new BABYLON.Vector3(
            waypoints[waypointIndex].x, 
            waypoints[waypointIndex].y, 
            waypoints[waypointIndex].z
          ) 
        : undefined
    }
  );
}

function stopAudio(interactionData) {
  audioManager.stopSound(interactionData.id);
}

function executeInteractions(interactions, waypointIndex) {
  interactions.forEach((interaction) => {
    switch (interaction.type) {
      case "audio":
        playAudio({ ...interaction.data, id: interaction.id }, waypointIndex);
        break;
      case "info":
        if ('${uiOptions.infoPosition}' === 'popup') {
          showInfoPopup(interaction.data);
        }
        break;
    }
  });
}

function reverseInteractions(interactions) {
  interactions.forEach((interaction) => {
    switch (interaction.type) {
      case "audio":
        const data = interaction.data;
        const sound = audioManager.activeSounds.get(interaction.id);
        
        if (sound && sound.metadata.stopOnExit) {
          stopAudio({ ...data, id: interaction.id });
        }
        break;
      case "info":
        if ('${uiOptions.infoPosition}' === 'popup') {
          hideInfoPopup();
        }
        break;
    }
  });
}

// Update the mute button functionality
const muteButton = document.getElementById('muteButton');
muteButton.textContent = audioManager.isMuted ? '🔇 Unmute' : '🔊 Mute';
muteButton.addEventListener('click', function() {
  if (audioManager.isMuted) {
    audioManager.unmute();
    muteButton.textContent = '🔊 Mute';
  } else {
    audioManager.mute();
    muteButton.textContent = '🔇 Unmute';
  }
});

${showStartExperience ? `
// Handle autoplay on page load
document.getElementById('startButton').addEventListener('click', function() {
  document.getElementById('startButtonContainer').style.display = 'none';

  if (BABYLON.Engine.audioEngine.audioContext.state === 'suspended') {
    BABYLON.Engine.audioEngine.audioContext.resume();
  }

  // Initialize autoplay sounds
  waypoints.forEach((wp, index) => {
    wp.interactions.forEach((interaction) => {
      if (interaction.type === 'audio' && interaction.data.autoplay === true) {
        playAudio({ ...interaction.data, id: interaction.id }, index);
      }
    });
  });
});
` : `
/* // Initialize autoplay sounds immediately when preloader is disabled
waypoints.forEach((wp, index) => {
  wp.interactions.forEach((interaction) => {
    if (interaction.type === 'audio' && interaction.data.autoplay === true) {
      playAudio({ ...interaction.data, id: interaction.id }, index);
    }
  });
}); */
`}`;
};
