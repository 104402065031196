/*
File: styles-minimal.ts
Description: Styles specific to the minimal template
Created: 2024-02-20
*/

import { adjustColorBrightness } from './helpers';

interface StylesProps {
  uiColor: string;
  isMobileDevice: boolean;
  uiOptions: {
    buttonPosition: "inline" | "below";
  };
}

export const generateStylesMinimal = ({
  uiColor,
  isMobileDevice,
  uiOptions
}: StylesProps): string => {
  return `
    /* Base Styles */
    html, body { 
      margin: 0; 
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-family: system-ui, -apple-system, sans-serif;
    }
    
    #renderCanvas { 
      width: 100vw; 
      height: 100vh; 
      touch-action: none;
      display: block;
    }

    /* Preloader Styles */
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }

    #preloader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1e1e1e;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100000;
      transition: opacity 0.5s ease-out;
    }

    #preloader.hidden {
      opacity: 0;
      pointer-events: none;
    }

    #preloader-content {
      display: flex;
      align-items: center;
      padding: 40px;
    }

    #preloader-media {
      display: flex;
      align-items: center;
      gap: 40px;
    }

    #preloader-image {
      height: 200px;
      width: auto;
      object-fit: contain;
      filter: invert(1);
      margin-right: -100px;
    }

    #preloader-lottie {
      height: 200px;
      width: 200px;
    }

    @media (max-width: 768px) {
      #preloader-image {
        height: 100px;
        margin-right: -50px;
      }
      
      #preloader-lottie {
        height: 100px;
        width: 100px;
      }
    }
    
    /* Button Styles */
    .button {
      background: rgba(0, 0, 0, 0.3);
      border: none;
      color: white;
      padding: 4px 8px;
      font-size: 12px;
      border-radius: 3px;
      cursor: pointer;
      transition: background 0.2s;
      margin: 0 2px;
    }
    .button:hover { background: rgba(0, 0, 0, 0.5); }
    
    /* Waypoint Info Styles */
    #waypointInfo {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      text-align: center;
      z-index: 1000;
      pointer-events: none;
      display: none;
    }
    
    /* Scroll Controls Styles */
    #scrollControls {
      position: fixed;
      bottom: 0;
      left: calc(50vw - 75px);
      width: 150px;
      background: rgba(0, 0, 0, 0.2);
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
    
    #scrollControlsContent {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
    
    #scrollPercentage {
      font-size: 18px;
      color: white;
    }
    #progressBarContainer {
      width: 90%;
      max-width: 300px;
      height: 3px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      overflow: hidden;
    }
    
    #progressBar {
      height: 100%;
      background: ${uiColor};
      transition: width 0.3s;
      width: 0%;
    }
    
    #scrollButtons {
      display: flex;
      justify-content: center;
      gap: 5px;
    }
    
    /* Mode Toggle Styles */
    #modeToggle {
      display: flex;
      gap: 5px;
      justify-content: center;
      margin-top: 5px;
    }
    
    .mode-button {
      background: rgba(0, 0, 0, 0.3);
      border: none;
      color: white;
      padding: 3px 6px;
      font-size: 11px;
      border-radius: 2px;
      cursor: pointer;
    }
    
    .mode-button.active {
      background: ${uiColor};
    }
    
    /* Start Button Styles */
    #startButton {
      padding: 20px 40px;
      font-size: 24px;
      background-color: rgba(255, 255, 255, 0.25);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
   /* Start Experience Button Styles */
    #startButtonContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5000;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }

    /* Control Button Styles */
    #fullscreenButton {
      position: fixed;
      top: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0);
      border: none;
      padding: 5px;
      border-radius: 3px;
      cursor: pointer;
      z-index: 100000;
    }
    
    #fullscreenButton svg {
      width: 20px;
      height: 20px;
      fill: white;
    }
    
    #muteButton {
      position: fixed;
      bottom: 10px;
      left: 10px;
      background: rgba(0, 0, 0, 0.3);
      border: none;
      color: white;
      padding: 5px 8px;
      border-radius: 3px;
      font-size: 12px;
      cursor: pointer;
      z-index: 1000;
    }
    
    /* UI Overlay Styles */
    .ui-overlay {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      color: white;
      padding: 8px;
      text-align: center;
      font-size: 12px;
      z-index: 1000;
    }
    
    .ui-overlay p {
      margin: 3px 0;
    }

    /* Content Display Styles */
    #hotspotContent {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 20px;
      border-radius: 10px;
      z-index: 1001;
      max-width: 300px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      display: none;
      font-size: 14px;
    }

    #infoPopup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 20px;
      border-radius: 10px;
      z-index: 1002;
      max-width: 80%;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      display: none;
      font-size: 16px;
    }

    /* Inline Button Position Styles */
    ${uiOptions.buttonPosition === "inline"
      ? `
    #scrollControlsContentInline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    `
      : ""}
  `;
};