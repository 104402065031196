import React, { useRef } from 'react';
import styled from 'styled-components';

interface FileDropZoneProps {
  infoTextRef: React.RefObject<HTMLDivElement>;
  onFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFileLoaded?: boolean;
}

const DropZoneContainer = styled.div`
`;

const DropZoneText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  z-index: 100;

  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  
  .loading-bar {
    width: 100%;
    height: 4px;
    background: #333;
    margin-top: 15px;
    border-radius: 2px;
    overflow: hidden;
    
    .progress {
      width: 100%;
      height: 100%;
      background: #4CAF50;
      animation: loading 2s infinite ease-in-out;
      transform-origin: 0% 50%;
    }
  }
`;

const FileInputButton = styled.button<{ $isVisible: boolean }>`
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 101;
  display: ${props => props.$isVisible ? 'block' : 'none'};
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileDropZone: React.FC<FileDropZoneProps> = ({ 
  infoTextRef, 
  onFileSelect,
  isFileLoaded = false
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <DropZoneContainer>
      <DropZoneText ref={infoTextRef}>
        <div className="message">
          Drop your .spz, .splat, .ply, .gltf, or .glb file here to load
        </div>
      </DropZoneText>
      <FileInputButton 
        onClick={handleButtonClick} 
        $isVisible={!isFileLoaded}
      >
        Or Click to Browse Files
      </FileInputButton>
      <HiddenFileInput 
        type="file" 
        ref={fileInputRef} 
        onChange={onFileSelect}
        accept=".splat,.ply,.gltf,.glb"
      />
    </DropZoneContainer>
  );
};

export default FileDropZone;
