// [Previous imports and styled components remain unchanged...]
/*
* ManageProfile.tsx
* Created: 2024-01-09 15:40
* Description: Page component for managing user's public profile settings
* Changes: 
* - Updated styling to match app theme and added back button
* - 2024-01-16: Removed show email checkbox as per requirements
* - 2024-01-16: Enhanced color picker styling to show selected color
* - 2024-01-17: Removed header image option to simplify profile customization
* - 2024-01-25: Added displayNameSlug generation and validation
* - 2024-01-25: Fixed TypeScript errors and improved type safety
* - 2024-01-26: Added proper slug validation to match Firestore rules requirements
* - 2024-01-27: Enhanced slug uniqueness check and error handling
* - 2024-01-29: Made display name non-unique, added separate slug editing with validation
*/

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { PublicProfileSettings } from '../types/UserTypes';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../utils/FirebaseConfig';
import styled from 'styled-components';
import { FiArrowLeft, FiUser, FiLink, FiGlobe, FiSettings, FiEdit2 } from 'react-icons/fi';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.95);
  z-index: 100000;
  overflow-y: auto;
`;

const Container = styled.div`
  margin: 40px auto;
  max-width: 800px;
  padding: 20px;
  color: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  flex-grow: 1;
  text-align: center;
`;

const Card = styled.div`
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: #4CAF50;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.8);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }

  &[type="color"] {
    height: 40px;
    padding: 2px;
    cursor: pointer;
    background: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    
    &::-webkit-color-swatch {
      border: none;
      border-radius: 2px;
    }
    
    &::-moz-color-swatch {
      border: none;
      border-radius: 2px;
    }
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  input {
    width: 16px;
    height: 16px;
  }

  label {
    margin: 0;
    cursor: pointer;
  }
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  margin-top: 16px;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const Message = styled.div<{ $isError?: boolean }>`
  padding: 12px;
  border-radius: 4px;
  margin-top: 16px;
  background-color: ${props => props.$isError ? 'rgba(220, 53, 69, 0.2)' : 'rgba(76, 175, 80, 0.2)'};
  color: ${props => props.$isError ? '#dc3545' : '#4CAF50'};
`;

const ColorPreview = styled.div<{ $color: string }>`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${props => props.$color};
  margin-left: 10px;
  border: 1px solid #444;
`;

const ColorInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SlugPreview = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

const SlugInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const defaultProfileSettings: PublicProfileSettings = {
  isPublic: false,
  showEmail: false,
  displayName: '',
  displayNameSlug: '',
  bio: '',
  website: '',
  proTier: 'free',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
  },
  customization: {
    accentColor: '#007bff',
  },
};


// Custom debounce utility function
function debounce<T extends (...args: any[]) => Promise<any>>(
  func: T,
  wait: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    return new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        const result = await func(...args);
        resolve(result);
      }, wait);
    });
  };
}

const ManageProfile: React.FC = () => {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [profileSettings, setProfileSettings] = useState<PublicProfileSettings>(defaultProfileSettings);
  const [isEditingSlug, setIsEditingSlug] = useState(false);

  // Generate URL-friendly slug from display name
  const generateSlug = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric chars with hyphen
      .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
      .substring(0, 30); // Limit length to 30 chars
  };

  // Validate slug format according to Firestore rules
  const validateSlug = (slug: string): boolean => {
    const isValidFormat = /^[a-z0-9-]+$/.test(slug);
    const isValidLength = slug.length >= 3 && slug.length <= 30;
    return isValidFormat && isValidLength;
  };

  // Check if slug is unique
  const checkSlugUniqueness = useCallback(async (slug: string, currentUserId: string) => {
    if (!slug) return false;
    
    try {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('publicProfile.displayNameSlug', '==', slug)
      );
      
      const querySnapshot = await getDocs(q);
      
      // Slug is unique if no documents found or the only document is the current user's
      const isUnique = querySnapshot.empty || 
                      (querySnapshot.size === 1 && querySnapshot.docs[0].id === currentUserId);
      
      return isUnique;
    } catch (error) {
      console.error('Error checking slug uniqueness:', error);
      throw error;
    }
  }, []);

  // Handle display name change
  const handleDisplayNameChange = (name: string) => {
    const newSlug = generateSlug(name);
    
    setProfileSettings(prev => ({
      ...prev,
      displayName: name,
      displayNameSlug: isEditingSlug ? prev.displayNameSlug : newSlug
    }));

    setMessage('');
    setIsError(false);
  };

  // Handle slug change
  const handleSlugChange = (slug: string) => {
    setProfileSettings(prev => ({
      ...prev,
      displayNameSlug: slug.toLowerCase()
    }));

    if (!validateSlug(slug)) {
      setMessage('URL slug must be 3-30 characters, using only lowercase letters, numbers, and hyphens.');
      setIsError(true);
    } else {
      setMessage('');
      setIsError(false);
    }
  };

useEffect(() => {
  if (userProfile?.publicProfile) {
    const currentSettings = userProfile.publicProfile;
    const initialSlug = currentSettings.displayNameSlug || generateSlug(currentSettings.displayName || '');
    
    setProfileSettings({
      ...defaultProfileSettings,
      ...currentSettings,
      displayNameSlug: initialSlug,
      displayName: currentSettings.displayName || userProfile.displayName || '',
      proTier: userProfile.proTier || 'free',
      socialLinks: {
        ...defaultProfileSettings.socialLinks,
        ...currentSettings.socialLinks,
      },
      customization: {
        ...defaultProfileSettings.customization,
        ...currentSettings.customization,
      },
    });
  } else if (userProfile?.displayName) {
    const initialSlug = generateSlug(userProfile.displayName);
    setProfileSettings({
      ...defaultProfileSettings,
      displayName: userProfile.displayName,
      displayNameSlug: initialSlug,
      proTier: userProfile.proTier || 'free',
    });
  }
}, [userProfile]);

// [Previous code remains unchanged until handleSubmit...]

const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setLoading(true);
  setMessage('');
  setIsError(false);

  try {
    // Validate display name and slug for public profiles
    if (profileSettings.isPublic) {
      if (!profileSettings.displayName) {
        throw new Error('Display name is required for public profiles.');
      }

      if (!profileSettings.displayNameSlug) {
        throw new Error('URL slug is required for public profiles.');
      }

      if (!validateSlug(profileSettings.displayNameSlug)) {
        throw new Error('URL slug must be 3-30 characters, using only lowercase letters, numbers, and hyphens.');
      }

      const isUnique = await checkSlugUniqueness(profileSettings.displayNameSlug, currentUser?.uid || '');
      if (!isUnique) {
        throw new Error('This URL slug is already taken. Please choose a different one.');
      }
    }

    if (!currentUser?.uid) {
      throw new Error('User not authenticated.');
    }

    // Ensure proTier is always up to date with the user's current tier
    const updatedProfileSettings = {
      ...profileSettings,
      proTier: userProfile?.proTier || 'free'
    };

    const userRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userRef, {
      'publicProfile': updatedProfileSettings,
    });
    
    setMessage('Profile settings updated successfully! Your profile is now available at /profile/' + profileSettings.displayNameSlug);
    setIsError(false);
    setIsEditingSlug(false);
  } catch (error) {
    setMessage(error instanceof Error ? error.message : 'Error updating profile settings. Please try again.');
    setIsError(true);
    console.error('Error updating profile:', error);
  }

  setLoading(false);
};
 
return (
  <Overlay>
    <Container>
      <Header>
        <BackButton onClick={() => navigate('/')}>
          <FiArrowLeft />
          Back to Dashboard
        </BackButton>
        <Title>Manage Public Profile</Title>
      </Header>

      <form onSubmit={handleSubmit}>
        <Card>
          <CardTitle>
            <FiUser />
            Profile Visibility
          </CardTitle>
          <Checkbox>
            <input
              type="checkbox"
              id="isPublic"
              checked={profileSettings.isPublic}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                isPublic: e.target.checked,
              }))}
            />
            <label htmlFor="isPublic">Make profile public</label>
          </Checkbox>
        </Card>

        <Card>
          <CardTitle>
            <FiUser />
            Basic Information
          </CardTitle>
          <FormGroup>
            <Label>Display Name</Label>
            <Input
              type="text"
              value={profileSettings.displayName}
              onChange={(e) => handleDisplayNameChange(e.target.value)}
              required={profileSettings.isPublic}
            />
            <SlugPreview>
              Profile URL: /profile/{profileSettings.displayNameSlug}
              <EditButton 
                type="button"
                onClick={() => setIsEditingSlug(!isEditingSlug)}
                disabled={!profileSettings.isPublic}
              >
                <FiEdit2 />
              </EditButton>
            </SlugPreview>
            {isEditingSlug && (
              <SlugInputGroup>
                <Input
                  type="text"
                  value={profileSettings.displayNameSlug}
                  onChange={(e) => handleSlugChange(e.target.value)}
                  placeholder="Enter custom URL slug"
                  required={profileSettings.isPublic}
                />
              </SlugInputGroup>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Bio</Label>
            <Textarea
              value={profileSettings.bio}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                bio: e.target.value,
              }))}
              rows={4}
            />
          </FormGroup>
          <FormGroup>
            <Label>Website</Label>
            <Input
              type="url"
              value={profileSettings.website}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                website: e.target.value,
              }))}
            />
          </FormGroup>
        </Card>

        <Card>
          <CardTitle>
            <FiLink />
            Social Links
          </CardTitle>
          <FormGroup>
            <Label>Twitter URL</Label>
            <Input
              type="url"
              value={profileSettings.socialLinks?.twitter || ''}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                socialLinks: {
                  ...prev.socialLinks,
                  twitter: e.target.value,
                },
              }))}
            />
          </FormGroup>
          <FormGroup>
            <Label>GitHub URL</Label>
            <Input
              type="url"
              value={profileSettings.socialLinks?.github || ''}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                socialLinks: {
                  ...prev.socialLinks,
                  github: e.target.value,
                },
              }))}
            />
          </FormGroup>
          <FormGroup>
            <Label>LinkedIn URL</Label>
            <Input
              type="url"
              value={profileSettings.socialLinks?.linkedin || ''}
              onChange={(e) => setProfileSettings(prev => ({
                ...prev,
                socialLinks: {
                  ...prev.socialLinks,
                  linkedin: e.target.value,
                },
              }))}
            />
          </FormGroup>
        </Card>

        <Card>
          <CardTitle>
            <FiSettings />
            Customization
          </CardTitle>
          <FormGroup>
            <Label>Accent Color</Label>
            <ColorInputGroup>
              <Input
                type="color"
                value={profileSettings.customization?.accentColor || '#007bff'}
                onChange={(e) => setProfileSettings(prev => ({
                  ...prev,
                  customization: {
                    ...prev.customization,
                    accentColor: e.target.value,
                  },
                }))}
              />
              <ColorPreview $color={profileSettings.customization?.accentColor || '#007bff'} />
            </ColorInputGroup>
          </FormGroup>
        </Card>

        {message && (
          <Message $isError={isError}>
            {message}
          </Message>
        )}

        <Button 
          type="submit" 
          disabled={loading || (profileSettings.isPublic && isError)}
        >
          {loading ? 'Saving...' : 'Save Profile Settings'}
        </Button>
      </form>
    </Container>
  </Overlay>
);
};

export default ManageProfile;

