/*
File: LoadSaveExportMenu.tsx
Description: Menu component for loading, saving, and exporting scenes
Last modified: 2024-01-24
Changes:
- Use !isModelLocal to disable remote URL input when model is loaded
- Prevent further modifications to remote URL input
- Add check for saved splat before allowing HTML export
*/

import React, { useState } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import {
  FiFolder,
  FiSave,
  FiUpload,
  FiDownload,
  FiTrash2,
  FiChevronDown,
  FiChevronUp,
  FiMenu,
} from "react-icons/fi";
import plyToSplat from "../tools/plyToSplat";
import { Waypoint } from "../types/SceneTypes";

// Define the interface for custom props
interface LoadSaveExportMenuProps {
  setLoadedModelUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setIsModelLocal: React.Dispatch<React.SetStateAction<boolean>>;
  customModelUrl: string;
  setCustomModelUrl: React.Dispatch<React.SetStateAction<string>>;
  handleExport: () => void;
  resetSettings: () => void;
  saveToJson: () => void;
  loadFromJson: (event: React.ChangeEvent<HTMLInputElement>) => void;
  waypoints: Waypoint[];
  isModelLocal: boolean;
  loadedModelUrl: string | null;
  currentLoadedSceneId: string | null;
}
const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
`;

const TooltipText = styled.span<{ xOffset: number }>`
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(${props => props.xOffset}px);
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  line-height: 1.4;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 200px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 280px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  @media (max-width: 480px) {
    width: 90%;
    left: 5%;
  }
`;

// Draggable Header
const DraggableHeader = styled.div<{ $isOpen: boolean }>`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: ${({ $isOpen }) => ($isOpen ? "0" : "8px")};
  border-bottom-left-radius: ${({ $isOpen }) => ($isOpen ? "0" : "8px")};
  transition: border-radius 0.3s ease;
  cursor: move;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px; /* Adjusted for consistency */
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1e90ff; /* DodgerBlue on hover */
  }
`;

const Section = styled.div`
  margin-top: 12px;
`;

const SectionTitle = styled.h4`
  margin: 0 0 8px 0;
  font-size: 16px; /* Consistent with Load/Save title */
  color: #ffffff;
  display: flex;
  align-items: center;
`;

const Button = styled.button<{ $variant?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px 10px; /* Adjust padding based on requirement */
  margin-bottom: 8px;
  background-color: ${({ $variant }) => {
    switch ($variant) {
      case "primary":
        return "#007bff";
      case "success":
        return "#28a745";
      case "info":
        return "#17a2b8";
      case "danger":
        return "#dc3545";
      case "secondary":
        return "#555555";
      default:
        return "#555555";
    }
  }};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  height: 40px; /* Fixed height for Save/Load buttons */

  &:hover {
    opacity: 0.9;
  }

  svg {
    margin-right: 8px;
    flex-shrink: 0;
    /* Consistent icon size */
    width: 18px;
    height: 18px;
  }
`;

// New styled component for a thinner button
const ThinButton = styled(Button)`
  height: 32px; /* Reduced height for a thinner appearance */
  padding: 4px 8px;
  font-size: 12px;
  width: auto;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 1px solid #555555;
  border-radius: 4px;
  background-color: #2c2c2c;
  color: #ffffff;
  font-size: 14px;

  &:focus {
    border-color: #1e90ff;
    outline: none;
    box-shadow: 0 0 5px rgba(30, 144, 255, 0.5);
  }
`;

// New styled component for horizontal layout
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #17a2b8;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  height: 24px; /* Fixed height for consistency */

  &:hover {
    opacity: 0.9;
  }

  svg {
    margin-right: 8px;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const HorizontalLine = styled.hr`
  border: 1px solid #555555;
  margin: 16px 0;
`;

const CollapsibleSection = styled.div`
  margin-top: 8px;
`;

const CollapsibleHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  color: #ffffff;
`;

const CollapsibleContent = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
`;

const SmallButton = styled(Button)`
  padding: 4px 8px;
  font-size: 12px;
  height: auto;
  margin-bottom: 4px;
`;

const CollapsibleMenuContainer = styled.div<{ $isOpen: boolean }>`
  max-height: ${({ $isOpen }) => ($isOpen ? "1000px" : "0px")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const MainHeader = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ $isOpen }) => ($isOpen ? "0" : "0")};
`;
const MainTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
`;


const LoadSaveExportMenu: React.FC<LoadSaveExportMenuProps> = ({
  setLoadedModelUrl,
  setIsModelLocal,
  customModelUrl,
  setCustomModelUrl,
  handleExport,
  resetSettings,
  saveToJson,
  loadFromJson,
  waypoints,
  isModelLocal,
  loadedModelUrl,
  currentLoadedSceneId
}) => {
  const baseURL = "https://assets.babylonjs.com/splats/";
  const models = [
    "gs_Sqwakers_trimed.splat",
    "gs_Skull.splat",
    "gs_Plants.splat",
    "gs_Fire_Pit.splat",
  ];
  const modelNames = ["Sqwakers", "Skull", "Plants", "Fire Pit"];

  const loadModel = (url: string) => {
    setLoadedModelUrl(url);
    setIsModelLocal(false);
  };

  const handleClear = () => {
    const confirmClear = window.confirm(
      "Are you sure you want to clear all settings?"
    );
    if (!confirmClear) return;
    resetSettings();
  };

  const [isDraggingDisabled, setIsDraggingDisabled] =
    React.useState<boolean>(false);
  const [isDefaultSplatsOpen, setIsDefaultSplatsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleFocus = () => setIsDraggingDisabled(true);
  const handleBlur = () => setIsDraggingDisabled(false);

  const handlePlyFileConvert = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      plyToSplat([event.target.files[0]]);
    }
  };


  const handleExportClick = () => {
    if (!waypoints || waypoints.length === 0) {
      alert("Please add at least one waypoint before exporting.");
      return;
    }

    // Check if splat is saved before allowing export
    if (!currentLoadedSceneId) {
      alert("Please login (if needed) and save your splat before exporting to HTML. This ensures you can have unlimited HTML exports while maintaining the 5 splat limit for free accounts.");
      return;
    }

    handleExport();
  };
  const nodeRef = React.useRef(null);

  // Handle drag events to prevent interference with file drops
  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };


  return (
    <Draggable handle=".handle" nodeRef={nodeRef}>
      <MenuContainer
        ref={nodeRef}
        onDragEnter={handleDragEvent}
        onDragOver={handleDragEvent}
        onDragLeave={handleDragEvent}
        onDrop={handleDragEvent}
      >
        <DraggableHeader
          $isOpen={isMenuOpen}
          className="handle"
          onDragEnter={handleDragEvent}
          onDragOver={handleDragEvent}
          onDragLeave={handleDragEvent}
          onDrop={handleDragEvent}
        >
          <MainHeader $isOpen={isMenuOpen}>
            <MainTitle>
              <FiMenu size={18} style={{ marginRight: "8px" }} />
              File
            </MainTitle>
            <ToggleButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <FiChevronUp /> : <FiChevronDown />}
            </ToggleButton>
          </MainHeader>
        </DraggableHeader>
        <CollapsibleMenuContainer $isOpen={isMenuOpen}>
          <div style={{ padding: "16px" }}>
            <SectionTitle>
              <FiMenu size={18} style={{ marginRight: "8px" }} /> Tools
            </SectionTitle>
            <FileInputLabel htmlFor="ply-to-splat-input">
              <FiUpload size={18} /> Convert PLY to Splat
            </FileInputLabel>
            <HiddenFileInput
              id="ply-to-splat-input"
              type="file"
              accept=".ply"
              onChange={handlePlyFileConvert}
            />

            <HorizontalLine />

            <Header>
              <Title>
                <FiFolder size={20} style={{ marginRight: "8px" }} /> Local File Management
              </Title>
            </Header>

            <Section>
              <Tooltip>
                <Button $variant="primary" onClick={handleExportClick}>
                  <FiDownload size={18} /> Export Scene To HTML
                </Button>
                <TooltipText xOffset={0}>
                  {!currentLoadedSceneId 
                    ? "Login and save your scene to enable HTML export." 
                    : "Export your scene to a standalone HTML file"}
                </TooltipText>
              </Tooltip>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                <Button
                  $variant="success"
                  style={{ flex: 1 }}
                  onClick={saveToJson}
                >
                  <FiDownload size={18} /> Save Json
                </Button>
                <FileInputLabel htmlFor="load-json">
                  <FiUpload size={18} /> Load From Json
                </FileInputLabel>
                <HiddenFileInput
                  id="load-json"
                  type="file"
                  accept=".json"
                  onChange={loadFromJson}
                />
              </div>
            </Section>
            {!loadedModelUrl && (
              <Section>
                <SectionTitle>
                  <FiUpload size={18} style={{ marginRight: "8px" }} /> Load
                  Remote Splats (URL)
                </SectionTitle>

                <>
                  <Row>
                    <Input
                      type="text"
                      placeholder="Enter custom splat URL"
                      value={customModelUrl}
                      onChange={(e) => setCustomModelUrl(e.target.value)}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{ flex: 1 }}
                    />
                    <ThinButton
                      $variant="success"
                      onClick={() => {
                        if (customModelUrl) {
                          loadModel(customModelUrl);
                        } else {
                          alert("Please enter a valid URL.");
                        }
                      }}
                      style={{ flex: "0 0 auto" }}
                    >
                      <FiUpload size={16} /> Load
                    </ThinButton>
                  </Row>

                  <CollapsibleSection>
                    <CollapsibleHeader
                      onClick={() =>
                        setIsDefaultSplatsOpen(!isDefaultSplatsOpen)
                      }
                    >
                      {isDefaultSplatsOpen ? (
                        <FiChevronUp />
                      ) : (
                        <FiChevronDown />
                      )}{" "}
                      Load Example Splats
                    </CollapsibleHeader>
                    <CollapsibleContent $isOpen={isDefaultSplatsOpen}>
                      {models.map((splat, index) => (
                        <SmallButton
                          key={index}
                          $variant="secondary"
                          onClick={() => loadModel(baseURL + splat)}
                        >
                          {modelNames[index]}
                        </SmallButton>
                      ))}
                    </CollapsibleContent>
                  </CollapsibleSection>
                </>
              </Section>
            )}

            <HorizontalLine />

            <Button $variant="danger" onClick={handleClear}>
              <FiTrash2 size={18} /> Reset All
            </Button>
          </div>
        </CollapsibleMenuContainer>
      </MenuContainer>
    </Draggable>
  );
};

export default LoadSaveExportMenu;
