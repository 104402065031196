import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { functions } from '../utils/FirebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { FiRefreshCw, FiBarChart2, FiUsers, FiClock, FiArrowLeft } from 'react-icons/fi';


const PageContainer = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  color: white;
  background-color: #222; 
  border-radius: 10px; 
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); 
  z-index: 100000;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 28px; 
  font-weight: bold; 
`;

const BackButton = styled.button`
  background: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 10px; 

  &:hover {
    background: #444;
  }
`;

const RefreshButton = styled.button`
  background: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #45a049;
  }

  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const TimeframeSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const TimeframeButton = styled.button<{ active: boolean }>`
  padding: 10px 20px; 
  background: ${props => props.active ? '#4CAF50' : '#333'};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; 

  &:hover {
    background: ${props => props.active ? '#45a049' : '#444'};
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 30px; 
  margin-bottom: 30px;
`;

const MetricCard = styled.div`
  background: #2c2c2c;
  padding: 30px; 
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
`;

const MetricValue = styled.div`
  font-size: 36px; 
  font-weight: bold;
  color: #4CAF50;
  margin: 20px 0; 
`;

const MetricLabel = styled.div`
  color: #999;
  font-size: 16px; 
`;

const ChartContainer = styled.div`
  background: #2c2c2c;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
`;

const ChartTitle = styled.h2`
  font-size: 20px; 
  margin: 0 0 20px 0;
  font-weight: bold; 
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
`;

const ErrorMessage = styled.div`
  background: #ff55551a;
  color: #ff5555;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

interface SceneAnalytics {
  totalViews: number;
  uniqueVisitors: number;
  averageSessionDuration: number;
  waypointInteractions: {
    waypointName: string;
    count: number;
  }[];
  cameraModeUsage: {
    mode: string;
    percentage: number;
  }[];
}

interface SceneDetails {
  title: string;
  description: string;
  url: string;
}

const Analytics: React.FC = () => {
  const [analytics, setAnalytics] = useState<SceneAnalytics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeframe, setTimeframe] = useState<'7d' | '30d' | '90d' | 'all'>('30d');
  const [sceneDetails, setSceneDetails] = useState<SceneDetails | null>(null); 
  const { sceneId } = useParams<{ sceneId: string }>();
  const navigate = useNavigate();

  const fetchAnalytics = async () => {
    if (!sceneId) {
      setError('No scene ID provided');
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const getSceneAnalytics = httpsCallable<any, SceneAnalytics>(
        functions,
        'getSceneAnalytics'
      );

      const result = await getSceneAnalytics({
        sceneId,
        timeframe,
      });

      setAnalytics(result.data);
    } catch (err) {
      console.error('Error fetching analytics:', err);
      setError('Failed to load analytics data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSceneDetails = async () => {
    if (!sceneId) {
      setError('No scene ID provided');
      setLoading(false);
      return;
    }
    try {
      const getSceneDetails = httpsCallable<any, SceneDetails>(functions, 'getSceneDetails'); 
      const result = await getSceneDetails({ sceneId });
      setSceneDetails(result.data);
    } catch (err) {
      console.error('Error fetching scene details:', err);
      setError('Failed to load scene details. Please try again later.');
    }
  };

  useEffect(() => {
    fetchAnalytics();
    fetchSceneDetails(); 
  }, [sceneId, timeframe]);

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m`;
    }
    const hours = Math.floor(minutes / 60);
    return `${hours}h ${minutes % 60}m`;
  };

  const formatNumber = (num: number): string => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
  };

  const defaultAnalytics = {
    totalViews: 0,
    uniqueVisitors: 0,
    averageSessionDuration: 0,
    waypointInteractions: [],
    cameraModeUsage: [],
  };

  return (
    <PageContainer>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <FiArrowLeft /> Back
        </BackButton>
        {sceneDetails && ( 
          <>
            <Title>{sceneDetails.title}</Title>
            <SceneDescription>{sceneDetails.description}</SceneDescription>
            <SceneLink href={sceneDetails.url} target="_blank" rel="noopener noreferrer">
              View Scene
            </SceneLink>
          </>
        )}
        <RefreshButton onClick={fetchAnalytics} disabled={loading}>
          <FiRefreshCw /> Refresh
        </RefreshButton>
      </Header>

      <TimeframeSelector>
        <TimeframeButton
          active={timeframe === '7d'}
          onClick={() => setTimeframe('7d')}
        >
          7 Days
        </TimeframeButton>
        <TimeframeButton
          active={timeframe === '30d'}
          onClick={() => setTimeframe('30d')}
        >
          30 Days
        </TimeframeButton>
        <TimeframeButton
          active={timeframe === '90d'}
          onClick={() => setTimeframe('90d')}
        >
          90 Days
        </TimeframeButton>
        <TimeframeButton
          active={timeframe === 'all'}
          onClick={() => setTimeframe('all')}
        >
          All Time
        </TimeframeButton>
      </TimeframeSelector>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {loading ? (
        <LoadingOverlay>Loading analytics...</LoadingOverlay>
      ) : (
        <>
          <MetricsGrid>
            <MetricCard>
              <FiBarChart2 size={32} /> 
              <MetricValue>{formatNumber((analytics || defaultAnalytics).totalViews)}</MetricValue>
              <MetricLabel>Total Views</MetricLabel>
            </MetricCard>
            <MetricCard>
              <FiUsers size={32} /> 
              <MetricValue>{formatNumber((analytics || defaultAnalytics).uniqueVisitors)}</MetricValue>
              <MetricLabel>Unique Visitors</MetricLabel>
            </MetricCard>
            <MetricCard>
              <FiClock size={32} /> 
              <MetricValue>
                {formatDuration((analytics || defaultAnalytics).averageSessionDuration)}
              </MetricValue>
              <MetricLabel>Average Session Duration</MetricLabel>
            </MetricCard>
          </MetricsGrid>

          {((analytics || defaultAnalytics).waypointInteractions.length > 0) && (
            <ChartContainer>
              <ChartTitle>Most Visited Waypoints</ChartTitle>
              <WaypointChart>
                {((analytics || defaultAnalytics).waypointInteractions).slice(0, 5).map((wp, index) => (
                  <div key={wp.waypointName} style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                    borderBottom: index < 4 ? '1px solid #444' : 'none'
                  }}>
                    <span>{wp.waypointName}</span>
                    <span>{formatNumber(wp.count)} visits</span>
                  </div>
                ))}
              </WaypointChart>
            </ChartContainer>
          )}

          {((analytics || defaultAnalytics).cameraModeUsage.length > 0) && (
            <ChartContainer>
              <ChartTitle>Camera Mode Usage</ChartTitle>
              <CameraModeChart>
                {((analytics || defaultAnalytics).cameraModeUsage).map(mode => (
                  <div key={mode.mode} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}>
                    <span style={{ textTransform: 'capitalize' }}>{mode.mode} Mode</span>
                    <div style={{ 
                      width: '60%', 
                      background: '#444',
                      height: '20px',
                      borderRadius: '10px',
                      overflow: 'hidden'
                    }}>
                      <div style={{
                        width: `${mode.percentage}%`,
                        height: '100%',
                        background: '#4CAF50',
                        transition: 'width 0.3s ease'
                      }} />
                    </div>
                    <span>{mode.percentage}%</span>
                  </div>
                ))}
              </CameraModeChart>
            </ChartContainer>
          )}
        </>
      )}
    </PageContainer>
  );
};

const SceneDescription = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #aaa; 
`;

const SceneLink = styled.a`
  background: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-left: 10px;
  font-size: 16px; 
`;

const WaypointChart = styled.div`
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
`;

const CameraModeChart = styled.div`
  border: 1px solid #444;
  padding: 10px;
  border-radius: 5px;
`;

export default Analytics;
