/*
File: hotspotSystem.ts
Description: Handles hotspot creation, management, and interactions
Created: 2024-02-19
Updated: 2024-03-19 - Added AR-specific content display using 3D text planes
*/

import { GenerateHTMLProps } from '../types';

export const generateHotspotSystem = (props: GenerateHTMLProps): string => {
  const { hotspots, uiColor } = props;

  return `
    // Initialize global variables
    const hotspots = ${JSON.stringify(hotspots)};
    console.log('Initializing hotspots:', hotspots);
    
    let isInAR = false;
    let contentPlane = null;
    let xrHelper = null;

    // Initialize XR event handling
    function initializeXREvents() {
      if (!scene.xrHelper) {
        console.log('Waiting for XR initialization...');
        setTimeout(initializeXREvents, 100);
        return;
      }
      
      xrHelper = scene.xrHelper;
      xrHelper.baseExperience.onStateChangedObservable.add((state) => {
        isInAR = state === BABYLON.WebXRState.IN_XR;
        if (!isInAR && contentPlane) {
          contentPlane.setEnabled(false);
        }
      });
    }

    // Start XR event initialization
    initializeXREvents();

    function createSphereMesh(h, scene) {
      const mesh = BABYLON.MeshBuilder.CreateSphere(
        \`hotspot-\${h.id}\`,
        { diameter: 0.2 },
        scene
      );
      
      const material = new BABYLON.StandardMaterial(
        \`hotspot-material-\${h.id}\`,
        scene
      );
      material.diffuseColor = BABYLON.Color3.FromHexString(h.color);
      material.emissiveColor = BABYLON.Color3.FromHexString(h.color).scale(0.5);
      mesh.material = material;
      return mesh;
    }

    // Create AR content plane
    function createContentPlane() {
      if (contentPlane) {
        contentPlane.dispose();
      }

      contentPlane = BABYLON.MeshBuilder.CreatePlane("contentPlane", {
        width: 1,
        height: 0.5
      }, scene);
      
      const material = new BABYLON.StandardMaterial("contentPlaneMat", scene);
      material.diffuseColor = BABYLON.Color3.Black();
      material.specularColor = BABYLON.Color3.Black();
      material.emissiveColor = BABYLON.Color3.White();
      material.backFaceCulling = false;
      contentPlane.material = material;
      
      // Hide initially
      contentPlane.setEnabled(false);
      
      return contentPlane;
    }

    // Update content plane position relative to camera
    function updateContentPlanePosition() {
      if (!contentPlane || !contentPlane.isEnabled()) return;
      
      const camera = scene.activeCamera;
      const distance = 2; // 2 meters in front of camera
      
      // Get forward direction of camera
      const forward = camera.getForwardRay().direction;
      
      // Position plane in front of camera
      contentPlane.position = camera.position.add(forward.scale(distance));
      
      // Make plane face camera
      contentPlane.lookAt(camera.position);
    }

    // Register scene before render to update content plane
    scene.registerBeforeRender(() => {
      updateContentPlanePosition();
    });

    function showARContent(hotspot) {
      if (!contentPlane) {
        contentPlane = createContentPlane();
      }

      // Create dynamic texture for text
      const texture = new BABYLON.DynamicTexture("contentTexture", {
        width: 512,
        height: 256
      }, scene);
      
      const ctx = texture.getContext();
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, 512, 256);
      
      // Add text
      const fontSize = 24;
      ctx.fillStyle = "white";
      ctx.font = \`\${fontSize}px Arial\`;
      
      // Title
      ctx.fillText(hotspot.title, 20, 40);
      
      // Information (with word wrap)
      if (hotspot.information) {
        const words = hotspot.information.split(' ');
        let line = '';
        let y = 80;
        
        words.forEach(word => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          
          if (metrics.width > 480) {
            ctx.fillText(line, 20, y);
            line = word + ' ';
            y += fontSize + 10;
          } else {
            line = testLine;
          }
        });
        
        ctx.fillText(line, 20, y);
      }
      
      texture.update();
      
      // Apply texture to plane
      contentPlane.material.diffuseTexture = texture;
      contentPlane.material.opacityTexture = texture;
      
      contentPlane.setEnabled(true);
    }

    hotspots.forEach((h) => {
      console.log('Creating hotspot:', h);
      let mesh;

      if (h.type === 'image' && h.imageUrl) {
        mesh = BABYLON.MeshBuilder.CreatePlane(
          \`hotspot-\${h.id}\`,
          { size: 1 },
          scene
        );
        
        const material = new BABYLON.StandardMaterial(
          \`hotspot-material-\${h.id}\`,
          scene
        );

        const texture = new BABYLON.Texture(h.imageUrl, scene, false, false, undefined, 
          () => {
            console.error('Failed to load hotspot image, falling back to sphere');
            mesh.dispose();
            mesh = createSphereMesh(h, scene);
            setupHotspotBehavior(mesh, h);
          }
        );
        
        material.diffuseTexture = texture;
        material.emissiveColor = BABYLON.Color3.White();
        material.backFaceCulling = false;
        
        mesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        mesh.material = material;
      } else {
        mesh = createSphereMesh(h, scene);
      }
      
      mesh.position = new BABYLON.Vector3(
        h.position._x,
        h.position._y,
        h.position._z
      );
      
      mesh.scaling = new BABYLON.Vector3(
        h.scale._x,
        h.scale._y,
        h.scale._z
      );

      setupHotspotBehavior(mesh, h);
      
      console.log('Hotspot positioned at:', mesh.position);
    });

    function setupHotspotBehavior(mesh, h) {
      mesh.actionManager = new BABYLON.ActionManager(scene);
      
      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOverTrigger,
          () => {
            console.log('Hotspot hover:', h.title);
            if (h.type !== 'image') {
              mesh.material.emissiveColor = BABYLON.Color3.FromHexString(h.color);
            }
            if (h.activationMode === 'hover' && !isInAR) {
              trackHotspotInteraction(h.title, 'hover');
              showHotspotContent(h);
            }
          }
        )
      );

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOutTrigger,
          () => {
            if (h.type !== 'image') {
              mesh.material.emissiveColor = BABYLON.Color3.FromHexString(h.color).scale(0.5);
            }
            if (h.activationMode === 'hover' && !isInAR) {
              hideHotspotContent();
            }
          }
        )
      );

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            console.log('Hotspot clicked:', h.title);
            if (h.activationMode === 'click') {
              trackHotspotInteraction(h.title, 'click');
              if (isInAR) {
                showARContent(h);
              } else {
                showHotspotContent(h);
              }
            }
          }
        )
      );
    }

    function showHotspotContent(hotspot) {
      if (isInAR) {
        showARContent(hotspot);
        return;
      }

      const hotspotContent = document.getElementById('hotspotContent');
      hotspotContent.innerHTML = \`
        <h3>\${hotspot.title}</h3>
        \${hotspot.photoUrl ? \`<img src="\${hotspot.photoUrl}" alt="\${hotspot.title}" style="width: 100%; margin-bottom: 10px; border-radius: 5px;">\` : ''}
        \${hotspot.information ? \`<p>\${hotspot.information}</p>\` : ''}
        \${hotspot.activationMode === 'click' ? '<button onclick="hideHotspotContent()" style="width: 100%; padding: 10px; background-color: #4CAF50; border: none; color: white; cursor: pointer; border-radius: 5px;">Close</button>' : ''}
      \`;
      hotspotContent.style.display = 'block';
      positionHotspotContent(hotspotContent);
    }

    function hideHotspotContent() {
      if (isInAR && contentPlane) {
        contentPlane.setEnabled(false);
        return;
      }

      const hotspotContent = document.getElementById('hotspotContent');
      hotspotContent.style.display = 'none';
    }

    function showInfoPopup(data) {
      const infoPopup = document.getElementById('infoPopup');
      infoPopup.innerHTML = \`
        <p>\${data.text}</p>
        <button onclick="hideInfoPopup()" style="width: 100%; padding: 10px; background-color: ${uiColor}; border: none; color: white; cursor: pointer; border-radius: 5px;">Close</button>
      \`;
      infoPopup.style.display = 'block';
    }

    function hideInfoPopup() {
      const infoPopup = document.getElementById('infoPopup');
      const waypointInfo = document.getElementById('waypointInfo');
      infoPopup.style.display = 'none';
      if(waypointInfo){
        waypointInfo.style.display = 'none';
      }
    }

    function positionHotspotContent(element) {
      const rect = element.getBoundingClientRect();
      let left = scene.pointerX + 10;
      let top = scene.pointerY + 10;

      if (left + rect.width > window.innerWidth) {
        left = window.innerWidth - rect.width - 10;
      }
      if (top + rect.height > window.innerHeight) {
        top = window.innerHeight - rect.height - 10;
      }

      element.style.left = \`\${left}px\`;
      element.style.top = \`\${top}px\`;
    }
  `;
};
