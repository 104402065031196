/*
 * UserDashboard.tsx
 * Last modified: 2024-02-14
 * Changes:
 * - Added preview image capture button to active scene section
 * - Improved button layout and styling
 * Previous changes: Added thumbnailBlob support for preview images
 */

import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { StorageManager } from "../utils/StorageManager";
import styled from "styled-components";
import { UserSplat } from "../types/UserTypes";
import {
  FiUpload,
  FiCopy,
  FiTrash2,
  FiExternalLink,
  FiEdit,
  FiRefreshCw,
  FiBarChart2,
  FiLogOut,
  FiChevronLeft,
  FiChevronRight,
  FiSave,
  FiGlobe,
  FiCamera,
} from "react-icons/fi";
import {
  AROptions,
  CameraMode,
  SaveFile,
  UIOptions,
  XRMode,
} from "../types/SceneTypes";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../utils/FirebaseConfig";
import config from "../config";
import { toast } from "react-toastify";
import { useMobileDetection } from "../hooks/useMobileDetection";
import { userInfo } from "os";
import { useSceneState } from "../hooks/useSceneState";
import ExportPopup from "./ExportPopup";

// Styled Components
const DashboardContainer = styled.div<{
  isCollapsed: boolean;
  isSignInState: boolean;
  isMobile: boolean;
}>`
  position: fixed;
  top: ${(props) => (props.isMobile ? "0" : "10px")};
  right: ${(props) => (props.isMobile ? "0" : "10px")};
  background-color: #1e1e1e;
  padding: ${(props) =>
    props.isCollapsed || props.isSignInState ? "10px" : "20px"};
  border-radius: ${(props) => (props.isMobile ? "0" : "8px")};
  color: white;
  z-index: 10000;
  width: ${(props) =>
    props.isMobile
      ? "92% !important"
      : props.isCollapsed || props.isSignInState
        ? "220px"
        : "300px"};
  height: ${(props) =>
    props.isMobile && !props.isSignInState
      ? "100vh"
      : props.isMobile && props.isSignInState
        ? ""
        : "auto"};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: ${(props) =>
    props.isMobile
      ? "100vh"
      : props.isCollapsed || props.isSignInState
        ? "auto"
        : "calc(100vh - 40px)"};
  display: flex;
  flex-direction: column;
  transition:
    width 0.3s ease,
    padding 0.3s ease,
    max-height 0.3s ease;
  overflow: hidden;
`;

const Header = styled.div<{ isCollapsed: boolean; isSignInState: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) =>
    !props.isCollapsed && !props.isSignInState ? "16px" : "0px"};
  height: ${(props) => (props.isSignInState ? "0px" : "auto")};
  padding: ${(props) => (props.isSignInState ? "0px" : "initial")};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
const TitleContainerOuter = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h3<{ isCollapsed: boolean }>`
  margin: 0;
  font-size: ${(props) => (props.isCollapsed ? "16px" : "18px")};
  display: flex;
  align-items: center;
`;

const ProBadge = styled.span<{
  tier: "free" | "pro" | "business" | "enterprise" | "Admin";
}>`
  background: ${(props) =>
    props.tier === "free"
      ? "linear-gradient(45deg, #808080, #a9a9a9)"
      : props.tier === "Admin"
        ? "linear-gradient(45deg, #800080, #DA70D6)"
        : props.tier === "business"
          ? "linear-gradient(45deg, #2196F3, #64B5F6)"
          : "linear-gradient(45deg, #ffd700, #ffa500)"};
  color: ${(props) =>
    props.tier === "free" || props.tier === "Admin" || props.tier === "business" ? "white" : "black"};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderButtons = styled.div<{ isSignInState: boolean }>`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  flex-wrap: nowrap !important;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
  flex-wrap: wrap;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Button = styled.button`
  padding: 6px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const StorageInfo = styled.div`
  background-color: #2c2c2c;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  flex-shrink: 0;
`;

const ProgressBar = styled.div<{ $percent: number }>`
  width: 100%;
  height: 4px;
  background-color: #444;
  border-radius: 2px;
  margin-top: 8px;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    width: ${(props) => props.$percent}%;
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease;
  }
`;

const UploadButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #ff5555;
  font-size: 14px;
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(255, 85, 85, 0.1);
  border-radius: 4px;
  flex-shrink: 0;
`;

const SplatList = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
`;

const SplatItem = styled.div`
  background-color: #2c2c2c;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const SplatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const SplatName = styled.h4`
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const VisibilityToggle = styled.button<{ $isPublic: boolean }>`
  background: none;
  border: none;
  color: ${(props) => (props.$isPublic ? "#4CAF50" : "#666")};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }

  svg {
    transition: color 0.2s;
  }
`;

const UpdateButton = styled.button`
  padding: 8px 12px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const UpdateSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;
`;

const UpdateButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
`;

const SignInButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #45a049;
  }
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    margin-bottom: 4px;
  }
`;

interface UserDashboardProps {
  getSceneConfig: () => any;
  isModelLocal: boolean;
  modelUrl: string | null;
  localModelFile: File | null;
  getJsonSave: () => SaveFile;
  loadFromJsonSaveFile: (jsonData: SaveFile, sceneId?: string) => void;
  uiOptions: UIOptions;
  setUIOptions: (options: UIOptions) => void;
  setShowLicenseAgreement: (show: boolean) => void;
  currentLoadedSceneId: string | null;
  setCurrentLoadedSceneId: (id: string | null) => void;
  sceneTitle: string;
  setSceneTitle: (title: string) => void;
  analyticsEnabled: boolean;
  onCapturePreview: () => Promise<Blob | null>;
  previewImageBlob: Blob | null;
}

const UserDashboard: React.FC<UserDashboardProps> = ({
  getSceneConfig,
  isModelLocal,
  modelUrl,
  localModelFile,
  getJsonSave,
  loadFromJsonSaveFile,
  uiOptions,
  setUIOptions,
  setShowLicenseAgreement,
  currentLoadedSceneId,
  setCurrentLoadedSceneId,
  sceneTitle,
  setSceneTitle,
  analyticsEnabled,
  onCapturePreview,
  previewImageBlob,
}) => {
  const { currentUser, userProfile, signIn, signOut, hasAcceptedLicense } =
    useAuth();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [updatingScene, setUpdatingScene] = useState<UserSplat | null>(null);
  const [splatAnalytics, setSplatAnalytics] = useState<any>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMobileDetection();
  const sceneState = useSceneState();

  useEffect(() => {
    const fetchAnalytics = async () => {
      if (!analyticsEnabled || !currentLoadedSceneId || !userProfile?.isPro)
        return;

      try {
        const getSceneAnalytics = httpsCallable<any, any>(
          functions,
          "getSceneAnalytics"
        );

        const result = await getSceneAnalytics({
          sceneId: currentLoadedSceneId,
          timeframe: "30d",
        });

        setSplatAnalytics(result.data);
      } catch (error) {
        console.error("Error fetching analytics:", error);
      }
    };

    fetchAnalytics();
  }, [analyticsEnabled, currentLoadedSceneId, userProfile?.isPro]);

  const toggleSplatVisibility = async (splat: UserSplat) => {
    if (!currentUser) return;

    try {
      await StorageManager.updateSplatVisibility(
        currentUser.uid,
        splat,
        !splat.isPublic
      );

      toast.success(`Splat is now ${!splat.isPublic ? "public" : "private"}`);
    } catch (error) {
      console.error("Error updating splat visibility:", error);
      toast.error("Failed to update splat visibility");
    }
  };
  const handleUpload = async (
    description: string,
    includeXR: boolean,
    xrMode: XRMode,
    arOptions: AROptions,
    includeScrollControls: boolean,
    defaultCameraMode: CameraMode,
    allowedCameraModes: string[],
    newUIOptions: UIOptions,
    sceneId: string,
    newSceneTitle: string
  ) => {
    console.log("Handle upload: newSceneTitle: ", newSceneTitle);
    const jsonSave = getJsonSave();
    if (!currentUser || !userProfile) return;

    if (!hasAcceptedLicense) {
      setShowLicenseAgreement(true);
      return;
    }

    if (!modelUrl && !localModelFile) {
      setUploadError("No model loaded to save");
      return;
    }

    // Check if there are any waypoints
    if (!jsonSave.waypoints || jsonSave.waypoints.length === 0) {
      setUploadError("Please add at least one waypoint before uploading");
      return;
    }

    try {
      setIsUploading(true);
      setUploadError(null);
      setShowUploadPopup(false);

      const sceneConfig = getSceneConfig();
      sceneConfig.description = description;
      sceneConfig.includeXR = includeXR;
      sceneConfig.xrMode = xrMode;
      sceneConfig.arOptions = arOptions;
      sceneConfig.includeScrollControls = includeScrollControls;
      sceneConfig.defaultCameraMode = defaultCameraMode;
      sceneConfig.allowedCameraModes = allowedCameraModes;
      sceneConfig.uiOptions = newUIOptions;

      if (isModelLocal && localModelFile) {
        await StorageManager.uploadSplat(
          currentUser.uid,
          localModelFile,
          sceneConfig,
          sceneId,
          (progress: any) => {
            setUploadProgress(progress);
          },
          jsonSave,
          newSceneTitle ?? sceneTitle,
          previewImageBlob,
          onCapturePreview
        );
      } else if (modelUrl) {
        await StorageManager.saveSplatConfig(
          currentUser.uid,
          modelUrl,
          sceneConfig,
          jsonSave,
          sceneId,
          newSceneTitle ?? sceneTitle,
          previewImageBlob,
          onCapturePreview
        );
      }
      setCurrentLoadedSceneId(sceneId);
    } catch (error: any) {
      console.error("Upload error:", error);
      setUploadError(error.message);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };
  const handleSaveFile = async () => {
    if (!currentUser || !currentLoadedSceneId || !userProfile) return;

    try {
      setIsUploading(true);
      setUploadError(null);

      const jsonSave = getJsonSave();
      console.log("Save file: sceneTitle: ", jsonSave.sceneTitle);
      // Ensure title is set in save file
      jsonSave.sceneTitle = sceneTitle;

      await StorageManager.updateSaveFile(
        currentUser.uid,
        jsonSave,
        currentLoadedSceneId
      );

      toast.success("Save file updated successfully!");
    } catch (error: any) {
      console.error("Save file update error:", error);
      setUploadError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpdate = () => {
    if (!currentUser || !currentLoadedSceneId || !userProfile) return;

    const currentSplat = userProfile.splats.find(
      (splat) => splat.id === currentLoadedSceneId
    );
    if (!currentSplat) {
      console.error("Could not find current splat");
      return;
    }

    setUpdatingScene(currentSplat);
    setShowUpdatePopup(true);
  };

  const handleUpdateConfirm = async (
    description: string,
    includeXR: boolean,
    xrMode: XRMode,
    arOptions: AROptions,
    includeScrollControls: boolean,
    defaultCameraMode: CameraMode,
    allowedCameraModes: string[],
    newUIOptions: UIOptions,
    sceneId: string,
    newSceneTitle: string
  ) => {
    if (!updatingScene || !currentUser || !currentLoadedSceneId) return;

    try {
      setIsUploading(true);
      setUploadError(null);
      setShowUpdatePopup(false);

      const sceneConfig = getSceneConfig();
      sceneConfig.description = description;
      sceneConfig.includeXR = includeXR;
      sceneConfig.xrMode = xrMode;
      sceneConfig.arOptions = arOptions;
      sceneConfig.includeScrollControls = includeScrollControls;
      sceneConfig.defaultCameraMode = defaultCameraMode;
      sceneConfig.allowedCameraModes = allowedCameraModes;
      sceneConfig.uiOptions = newUIOptions;
      const jsonSave = getJsonSave();

      // Update title in save file
      console.log("Update confirm: sceneTitle: ", newSceneTitle);
      jsonSave.sceneTitle = newSceneTitle || sceneTitle;
      const updatedSceneTitle = newSceneTitle || sceneTitle;

      if (isModelLocal && localModelFile) {
        await StorageManager.updateSplat(
          currentUser.uid,
          localModelFile,
          sceneConfig,
          (progress: any) => {
            setUploadProgress(progress);
          },
          jsonSave,
          sceneId,
          updatedSceneTitle,
          previewImageBlob
        );
      } else if (modelUrl) {
        await StorageManager.updateSplatConfig(
          currentUser.uid,
          modelUrl,
          sceneConfig,
          jsonSave,
          sceneId,
          updatedSceneTitle,
          previewImageBlob
        );
      }

      toast.success("Scene updated successfully!");
    } catch (error: any) {
      console.error("Update error:", error);
      setUploadError(error.message);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      setUpdatingScene(null);
    }
  };

  const handleCopyEmbed = async (embedCode: string) => {
    try {
      await navigator.clipboard.writeText(embedCode);
      alert("Embed code copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy embed code:", error);
    }
  };

  const handleEdit = async (splat: UserSplat) => {
    if (!currentUser) return;
    try {
      const jsonData = await StorageManager.getSplatConfig(
        currentUser.uid,
        splat.name,
        splat.splatUrl
      );
      loadFromJsonSaveFile(jsonData, splat.id);
    } catch (error) {
      console.error("Error loading saved scene:", error);
    }
  };

  const handleDelete = async (splat: UserSplat) => {
    if (
      !currentUser ||
      !window.confirm("Are you sure you want to delete this scene?")
    )
      return;

    try {
      setIsDeletingId(splat.id);
      await StorageManager.deleteSplat(currentUser.uid, splat);
      setIsDeletingId(null);
      if (splat.id === currentLoadedSceneId) {
        setCurrentLoadedSceneId(null);
      }
    } catch (error) {
      console.error("Error deleting scene:", error);
      setIsDeletingId(null);
    }
  };

  const formatStorage = (bytes: number): string => {
    const gb = bytes / (1024 * 1024 * 1024);
    return `${gb.toFixed(2)} GB`;
  };

  const formatNumber = (num: number): string => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num.toString();
  };

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m`;
    }
    const hours = Math.floor(minutes / 60);
    return `${hours}h ${minutes % 60}m`;
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const isSignInState = !currentUser;

  if (isSignInState) {
    return (
      <DashboardContainer
        isCollapsed={false}
        isSignInState={true}
        isMobile={isMobile}
      >
        <Header isCollapsed={false} isSignInState={true}></Header>
        <SignInButton onClick={signIn}>Sign In to Save Scenes</SignInButton>
      </DashboardContainer>
    );
  }

  const storageUsedPercent =
    ((userProfile?.storageUsed || 0) / (userProfile?.maxStorageBytes || 1)) *
    100;

  const splats = userProfile?.splats || [];
  const splatsCount = splats.length;
  const maxSplats = userProfile?.maxSplats || userProfile?.maxStorage || 5;
  return (
    <DashboardContainer
      isCollapsed={isCollapsed}
      isSignInState={false}
      isMobile={isMobile}
    >
      <TitleContainerOuter>
        <TitleContainer>
          <Title isCollapsed={isCollapsed}>StorySplat</Title>
          <ProBadge tier={userProfile?.proTier || "free"}>
            {userProfile?.proTier?.toUpperCase() || "FREE"}
          </ProBadge>
        </TitleContainer>

        {!isMobile && (
          <ToggleButton
            onClick={toggleCollapse}
            title={isCollapsed ? "Expand" : "Collapse"}
          >
            {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
          </ToggleButton>
        )}
      </TitleContainerOuter>

      <Header isCollapsed={isCollapsed} isSignInState={false}>
        <RightSection>
          {!isCollapsed && (
            <HeaderButtons isSignInState={false}>
              {config.publicProfilesEnabled && (
                <>
                  <Button
                    onClick={() => navigate("/manage-profile")}
                    style={{ backgroundColor: "#2196F3" }}
                  >
                    Manage Profile
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        `/profile/${userProfile?.publicProfile?.displayNameSlug}`,
                        "_blank"
                      )
                    }
                    style={{ backgroundColor: "#4CAF50" }}
                  >
                    View Profile
                  </Button>
                </>
              )}
              {(config.stripeIntegrationEnabled ||
               ( config.stripeExperimentalFeatures?.enabled && 
                userProfile?.betaFeatures?.includes('stripe-beta'))) &&
                (userProfile?.isPro ? (
                  <Button onClick={() => navigate("/manage-subscription")}>
                    Account Settings
                  </Button>
                ) : (
                  <Button onClick={() => navigate("/upgrade-to-pro")}      style={{ backgroundColor: "#d4af37" }}>
                    Upgrade to Pro
                  </Button>
                ))}
            </HeaderButtons>
          )}
          {!isCollapsed && (
            <IconButton onClick={signOut} title="Sign Out">
              <FiLogOut />
            </IconButton>
          )}
        </RightSection>
      </Header>
      {!isCollapsed && (
        <>
          <StorageInfo>
            <div>
              Saved Scenes: {splatsCount}/{maxSplats > 0 ? maxSplats : " Unlimited"}
            </div>
            <div>
              Storage: {formatStorage(userProfile?.storageUsed || 0)}/
              { userProfile && userProfile?.maxStorageBytes > 0 ? formatStorage(userProfile?.maxStorageBytes || 0) : " Unlimited"}
            </div>
            <ProgressBar $percent={storageUsedPercent} />
          </StorageInfo>

          <UploadButton
            onClick={() => {
              if (!hasAcceptedLicense) {
                setShowLicenseAgreement(true);
                return;
              }
              setShowUploadPopup(true);
            }}
            disabled={
              isUploading ||
              (!modelUrl && !localModelFile) ||
             ((splatsCount >= maxSplats) && maxSplats > 0)
            }
          >
            <FiSave />
            {isUploading
              ? `Uploading... ${Math.round(uploadProgress)}%`
              : "Save Project"}
          </UploadButton>

          {uploadError && <ErrorMessage>{uploadError}</ErrorMessage>}
          <SplatList>
            {splats.map((splat: UserSplat) => (
              <SplatItem key={splat.id}>
                <SplatHeader>
                  <SplatName>{splat.name}</SplatName>
                  <ButtonGroup>
                    <VisibilityToggle
                      onClick={() => toggleSplatVisibility(splat)}
                      title={splat.isPublic ? "Make private" : "Make public"}
                      $isPublic={splat.isPublic || false}
                    >
                      <FiGlobe />
                    </VisibilityToggle>
                    <IconButton
                      onClick={() => handleCopyEmbed(splat.embedCode)}
                      title="Copy embed code"
                    >
                      <FiCopy />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        window.open(
                          "/" +
                            userProfile?.publicProfile?.displayNameSlug +
                            "?sceneId=" +
                            splat.id,
                          "_blank"
                        )
                      }
                      title="Open in new tab"
                    >
                      <FiExternalLink />
                    </IconButton>
                    <IconButton
                      onClick={() => handleEdit(splat)}
                      title="Edit scene"  
                       >
                      <FiEdit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(splat)}
                      title="Delete scene"
                      disabled={isDeletingId === splat.id}
                      style={{ opacity: isDeletingId === splat.id ? 0.5 : 1 }}
                    >
                      <FiTrash2 />
                    </IconButton>
                  </ButtonGroup>
                </SplatHeader>
                {splat.description && (
                  <div style={{ fontSize: "12px", opacity: 0.8 }}>
                    {splat.description}
                  </div>
                )}
                {analyticsEnabled && userProfile?.isPro && (
                  <Button
                    onClick={() => navigate(`/analytics/${splat.id}`)}
                    style={{ marginTop: "8px", width: "100%" }}
                  >
                    <FiBarChart2 style={{ marginRight: "8px" }} />
                    View Analytics
                  </Button>
                )}
                {currentLoadedSceneId === splat.id && (
                  <UpdateSection>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#4CAF50",
                        fontStyle: "italic",
                        marginBottom: "4px",
                      }}
                    >
                      Currently editing
                    </div>
                    <UpdateButtonsContainer>
                      <ButtonRow>
                        <UpdateButton
                          onClick={handleSaveFile}
                          disabled={isUploading}
                          style={{ flex: 1 }}
                        >
                          <FiSave />
                          Save File
                        </UpdateButton>
                        <Tooltip data-tooltip="Will also save your file">
                          <UpdateButton
                            onClick={handleUpdate}
                            disabled={isUploading}
                            style={{ flex: 1 }}
                          >
                            <FiRefreshCw />
                            Update Published Splat
                          </UpdateButton>
                        </Tooltip>
                      </ButtonRow>
                      <UpdateButton
                        onClick={onCapturePreview}
                        disabled={isUploading}
                        style={{ backgroundColor: "#2196F3" }}
                      >
                        <FiCamera style={{ marginRight: "8px" }} />
                        Set Preview Image
                      </UpdateButton>
                    </UpdateButtonsContainer>
                  </UpdateSection>
                )}
              </SplatItem>
            ))}
          </SplatList>

          {showUploadPopup && (
            <ExportPopup
              onUpload={handleUpload}
              onCancel={() => setShowUploadPopup(false)}
              initialUIOptions={uiOptions}
              onUIOptionsChange={setUIOptions}
              initialSceneId={undefined}
              isPro={userProfile?.isPro || false}
              isBusiness={(userProfile?.proTier === "business" || userProfile?.proTier === "enterprise" || userProfile?.proTier === "Admin") || false}
              sceneTitle={sceneTitle}
              setSceneTitle={setSceneTitle}
              mode="upload"
            />
          )}

          {showUpdatePopup && updatingScene && (
            <ExportPopup
              onUpload={handleUpdateConfirm}
              onCancel={() => {
                setShowUpdatePopup(false);
                setUpdatingScene(null);
              }}
              initialUIOptions={uiOptions}
              onUIOptionsChange={setUIOptions}
              isUpdate={true}
              initialDescription={updatingScene.description || ""}
              initialSceneId={updatingScene.id}
              isBusiness={(userProfile?.proTier === "business" || userProfile?.proTier === "enterprise" || userProfile?.proTier === "Admin") || false}
              isPro={userProfile?.isPro || false}
              sceneTitle={updatingScene.name}
              setSceneTitle={setSceneTitle}
              mode="upload"
            />
          )}
        </>
      )}
    </DashboardContainer>
  );
};

export default UserDashboard;
