import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiX, FiInfo } from 'react-icons/fi';

const Overlay = styled.div<{ $show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: ${props => props.$show ? 1 : 0};
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const PopupContainer = styled.div<{ $show: boolean }>`
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 24px;
  max-width: 500px;
  width: 90%;
  position: relative;
  transform: translateY(${props => props.$show ? '0' : '20px'});
  transition: transform 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled.h2`
  margin: 0 0 16px 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.5rem;
`;

const Content = styled.div`
  color: #ddd;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #45a049;
  }
`;

interface VersionPopupProps {
  version: string;
  changes: string[];
  onDismiss: () => void;
}

const VersionPopup: React.FC<VersionPopupProps> = ({ version, changes, onDismiss }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDismiss = () => {
    setShow(false);
    setTimeout(onDismiss, 300);
  };

  return (
    <Overlay $show={show}>
      <PopupContainer $show={show}>
        <CloseButton onClick={handleDismiss}>
          <FiX size={24} />
        </CloseButton>
        <Title>
          <FiInfo size={24} />
          Version {version} Released
        </Title>
        <Content>
          <p>What's new in this version:</p>
          <ul>
            {changes.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
        </Content>
        <Button onClick={handleDismiss}>Got it!</Button>
      </PopupContainer>
    </Overlay>
  );
};

export default VersionPopup;
