/*
File: template-minimal.ts
Description: A minimal visual style version of the HTML template that maintains all functionality
Created: 2024-02-08
Last Modified: 2024-02-14
Changes: 
- Fixed waypoint info positioning to top of screen
- Improved UI element centering
- Adjusted z-index values for proper layering
- Removed inline styles in favor of generateStylesMinimal from styles.ts
- Added selected state for camera mode buttons
- Added conditional rendering for waypoint info div
- Added hover states for prev and next buttons
- Added collapsible help section with ? icon
- Removed movement instructions overlay
*/

import { GenerateHTMLProps } from './types';
import { generateStylesMinimal } from './styles-minimal';
import { generateWaypointInteractionCode } from './waypointInteractions';
import { generateAnalyticsCode } from './analytics';
import { generateMainScript } from './mainScript';
import { isMobileDevice } from './helpers';

const generateWatermark = (userName: string, sceneId: string): string => {
  return `
    <div class="storysplat-watermark" style="
      position: fixed;
      bottom: 5px;
      right: 5px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      padding: 3px 6px;
      border-radius: 3px;
      font-size: 10px;
      z-index: 1000;
      pointer-events: none;
      font-family: system-ui, -apple-system, sans-serif;
    ">
      Created with <a href="https://storysplat.com?ref=${sceneId}" target="_blank" 
        style="color: #4CAF50; text-decoration: none; pointer-events: auto;">
        StorySplat
      </a>
    </div>
  `;
};

export const generateTemplate = (props: GenerateHTMLProps): string => {
  const {
    includeScrollControls,
    uiOptions,
    uiColor,
    name,
    sceneId,
    userId,
    userName,
    defaultCameraMode,
    autoPlayEnabled,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes
  } = props;

  const showStartExperience = uiOptions.showStartExperience;

  return `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>${name}</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  <style>
    ${generateStylesMinimal({
      uiColor,
      isMobileDevice: isMobileDevice(),
      uiOptions
    })}
    
    #prevButton, #nextButton {
      transition: background-color 0.3s, color 0.3s;
    }
    #prevButton:hover, #nextButton:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .mode-button.selected {
      background-color: rgba(255, 255, 255, 0.3);
      font-weight: bold;
    }

    #helpButton {
      position: fixed;
      top: 10px;
      left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      transition: background-color 0.3s;
    }



    #helpPanel {
      position: fixed;
      top: 100px;
      left: 10px;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 15px;
      border-radius: 8px;
      max-width: 300px;
      z-index: 999;
      display: none;
      font-family: system-ui, -apple-system, sans-serif;
      font-size: 14px;
    }

    #helpPanel.visible {
      display: block;
    }

    #helpPanel h3 {
      margin: 0 0 10px 0;
      font-size: 16px;
    }

    #helpPanel p {
      margin: 5px 0;
      line-height: 1.4;
    }
  </style>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-L2NG77PKGG"></script>
  <script>
    ${generateAnalyticsCode({
      sceneId,
      userId,
      sceneName: name,
      userName
    })}
  </script>
</head>
<body>
  <button id="helpButton" title="Toggle Help">?</button>
  <div id="helpPanel">
    <h3>Controls & Help</h3>
    <p><strong>Camera Modes:</strong></p>
    <p>• Tour - Follow predefined path</p>
    <p>• Explore - Free movement</p>
    <p>• Hybrid - Mix of both</p>
    <p><strong>Navigation:</strong></p>
    <p>• Mouse/Touch - Look around</p>
    <p>• WASD/Arrows - Move camera</p>
    <p>• Q/E - Move up/down</p>
    <p>• Scroll - Move along path</p>
  </div>

  <script>
    window.cameraMode = '${defaultCameraMode}';
    window.autoPlayEnabled = ${autoPlayEnabled};
  </script>

  <div id="preloader">
    <div id="preloader-content">
      <div id="preloader-media">
        <img id="preloader-image" src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fimages%2FStorySplat.webp?alt=media&token=953e8ab3-1865-4ac1-a98d-b548b7066bda" alt="StorySplat Logo" />
        <lottie-player id="preloader-lottie" 
          src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Flotties%2FstorySplatLottie.json?alt=media&token=d7edc19d-9cb8-4c6e-a94c-cba1d2b65d5e"
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
      </div>
    </div>
  </div>

  <button id="fullscreenButton" title="Toggle Fullscreen">
    <svg id="expandIcon" viewBox="0 0 24 24">
      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
    </svg>
    <svg id="compressIcon" viewBox="0 0 24 24" style="display: none;">
      <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>
    </svg>
  </button>

  ${showStartExperience ? `
  <div id="startButtonContainer">
    <button id="startButton">Start Experience</button>
  </div>
  ` : ''}
  
  <canvas id="renderCanvas"></canvas>

  <div id="hotspotContent"></div>
  <div id="infoPopup"></div>
  
  <div id="waypointInfo" style="display: none;"></div>

  ${includeScrollControls
    ? `
    <div id="scrollControls">
      <div id="scrollControlsContent">
        <div id="scrollPercentage">0%</div>
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
        ${uiOptions.buttonPosition === "inline"
          ? `
        <div id="scrollControlsContentInline">
          <div id="scrollButtons">
            <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
              <span class="button-icon">←</span>
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
            </button>
            <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
              <span class="button-icon">→</span>
            </button>
          </div>
        </div>
        `
          : `
        <div id="scrollButtons">
          <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
            <span class="button-icon">←</span>
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
          </button>
          <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
            <span class="button-icon">→</span>
          </button>
        </div>
        `}
      </div>
      <div id="modeToggleContainer">
        <div id="modeToggle">
          ${allowedCameraModes.includes('explore')
            ? `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>`
            : ''}
          ${allowedCameraModes.includes('tour')
            ? `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>`
            : ''}
          ${allowedCameraModes.includes('hybrid')
            ? `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>`
            : ''}
        </div>
      </div>
    </div>
    `
    : `
    <div id="modeToggleContainer">
      <div id="modeToggle">
        ${allowedCameraModes.includes('explore')
          ? `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>`
          : ''}
        ${allowedCameraModes.includes('tour')
          ? `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>`
          : ''}
        ${allowedCameraModes.includes('hybrid')
          ? `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>`
          : ''}
      </div>
    </div>
    `}

  <button id="muteButton">🔊 Mute</button>

  ${generateWatermark(userName, sceneId)}

  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylon-7.38.0.js?alt=media&token=848987a3-68a6-47b5-8f7b-0e42185b7a09" onerror="this.onerror=null;this.src='https://cdn.babylonjs.com/babylon.js'"></script>
  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylonjs.loaders.min.js?alt=media&token=789d9d2f-c308-419c-9899-2650e62bdae1" onerror="this.onerror=null;this.src='https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js'"></script>

  <script>
    ${generateWaypointInteractionCode({ uiOptions })}
    ${generateMainScript(props)}

    const helpButton = document.getElementById('helpButton');
    const helpPanel = document.getElementById('helpPanel');
    let helpVisible = false;

    helpButton.addEventListener('click', () => {
      helpVisible = !helpVisible;
      helpPanel.classList.toggle('visible', helpVisible);
    });

    document.addEventListener('click', (event) => {
      if (helpVisible && !helpPanel.contains(event.target) && event.target !== helpButton) {
        helpVisible = false;
        helpPanel.classList.remove('visible');
      }
    });

    function updateCameraModeButtons() {
      const buttons = document.querySelectorAll('.mode-button');
      buttons.forEach(button => {
        const mode = button.id.replace('mode', '').toLowerCase();
        button.classList.toggle('selected', mode === window.cameraMode);
      });
    }

    const originalChangeCameraMode = window.changeCameraMode;
    window.changeCameraMode = function(mode) {
      originalChangeCameraMode(mode);
      updateCameraModeButtons();
    };

    updateCameraModeButtons();
  </script>
</body>
</html>
  `;
};
