/*
 * PublicProfile.tsx
 * Created: 2024-01-09 15:45
 * Last Modified: 2024-01-25 14:30
 * Description: Public-facing profile page with Story Splat branding and color accents
 * Changes:
 * - Added StorySplat logo, introduced color pops, enhanced visual branding
 * - Modified to only fetch and display public profile data
 * - Added error handling for non-public profiles
 * - 2024-01-16: Updated to use custom accent color from profile settings
 * - 2024-01-17: Improved visual hierarchy and added carousel for story splats
 * - 2024-01-17: Added splat titles/descriptions, pull buttons, and optimized iframe loading
 * - 2024-01-24: Implemented comprehensive light/dark theme toggle functionality
 * - 2024-01-24: Replaced watermark with StorySplat header button
 * - 2024-01-25: Updated to use displayNameSlug for profile URLs
 */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../utils/FirebaseConfig";
import { UserProfile } from "../types/UserTypes";
import config from "../config";
import StorySplatLogo from "../images/StorySplat.webp";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import {
  FiChevronLeft,
  FiChevronRight,
  FiGlobe,
  FiGithub,
  FiLinkedin,
  FiSun,
  FiMoon,
  FiExternalLink,
} from "react-icons/fi";

// [Previous styled components remain unchanged...]

// Theme definitions with comprehensive color mapping
const lightTheme = {
  mode: "light",
  background: "#f4f4f4",
  text: "#333",
  secondaryText: "#666",
  containerBackground: "rgba(255, 255, 255, 0.9)",
  containerBorder: "rgba(0, 0, 0, 0.1)",
  socialLinkBackground: "rgba(0, 0, 0, 0.05)",
  scrollbarTrack: "rgba(0, 0, 0, 0.1)",
  scrollbarThumb: "rgba(0, 0, 0, 0.3)",
  loadingOverlay: "rgba(255, 255, 255, 0.9)",
};

const darkTheme = {
  mode: "dark",
  background: "rgba(0, 0, 0, 0.95)",
  text: "white",
  secondaryText: "rgba(255, 255, 255, 0.9)",
  containerBackground: "rgba(255, 255, 255, 0.05)",
  containerBorder: "rgba(255, 255, 255, 0.1)",
  socialLinkBackground: "rgba(255, 255, 255, 0.1)",
  scrollbarTrack: "rgba(255, 255, 255, 0.1)",
  scrollbarThumb: "rgba(255, 255, 255, 0.3)",
  loadingOverlay: "rgba(0, 0, 0, 0.9)",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  overflow-y: auto;
  padding: 40px 20px;
  box-sizing: border-box;
  transition:
    background-color 0.3s,
    color 0.3s;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const ProfileHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
  padding: 30px;
  background: ${(props) => props.theme.containerBackground};
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.containerBorder};
  position: relative;
`;
const ThemeToggle = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;
const StorySplatButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const DisplayName = styled.h1<{ $color: string }>`
  font-size: 36px;
  margin: 0;
  color: ${(props) => props.$color};
  margin-bottom: 20px;
`;

const Bio = styled.p`
  font-size: 18px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  color: ${(props) => props.theme.secondaryText};
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;

const SocialLink = styled.a<{ $color: string }>`
  color: ${(props) => props.$color};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.socialLinkBackground};
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => props.$color}33;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  margin: 40px 0;
  background: ${(props) => props.theme.containerBackground};
  padding: 30px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.containerBorder};
`;

const CarouselTitle = styled.h2<{ $color: string }>`
  font-size: 24px;
  margin-bottom: 20px;
  color: ${(props) => props.$color};
  text-align: center;
`;

const CarouselContent = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-radius: 8px;
  background: ${(props) =>
    props.theme.mode === "dark" ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.1)"};
`;

const CarouselSlide = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.$active ? 1 : 0)};
  visibility: ${(props) => (props.$active ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const CarouselButton = styled.button<{
  $color: string;
  $direction: "left" | "right";
}>`
  position: absolute;
  top: 50%;
  ${(props) => (props.$direction === "left" ? "left: 10px" : "right: 10px")};
  transform: translateY(-50%);
  background: ${(props) => props.$color}33;
  border: none;
  color: ${(props) => props.theme.text};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
  z-index: 2;

  &:hover {
    background: ${(props) => props.$color}66;
  }
`;

const Watermark = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  mix-blend-mode: ${(props) =>
    props.theme.mode === "dark" ? "screen" : "multiply"};
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.loadingOverlay};
  color: ${(props) => props.theme.text};
  z-index: 1000;
`;

const SplatInfo = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const SplatTitle = styled.h3<{ $color: string }>`
  font-size: 20px;
  margin: 0;
  color: ${(props) => props.$color};
`;

const SplatDescription = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.secondaryText};
  margin: 10px 0;
  line-height: 1.4;
`;

const PullButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbarTrack};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbarThumb};
    border-radius: 4px;
  }
`;

const PullButton = styled.button<{ $active: boolean; $color: string }>`
  background: ${(props) =>
    props.$active ? props.$color + "33" : "rgba(255, 255, 255, 0.1)"};
  border: 1px solid
    ${(props) => (props.$active ? props.$color : "rgba(255, 255, 255, 0.2)")};
  color: ${(props) => props.theme.text};
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:hover {
    background: ${(props) =>
      props.$active ? props.$color + "33" : "rgba(255, 255, 255, 0.15)"};
  }

  span.title {
    font-weight: bold;
    color: ${(props) => (props.$active ? props.$color : props.theme.text)};
  }

  span.description {
    font-size: 12px;
    color: ${(props) => props.theme.secondaryText};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const PublicProfile: React.FC = () => {
  const { displayNameSlug } = useParams<{ displayNameSlug: string }>();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<Pick<
    UserProfile,
    "displayName" | "publicProfile" | "splats"
  > | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [theme, setTheme] = useState<"light" | "dark">("dark");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  const handleStorySplatClick = () => {
    window.open("https://storysplat.com", "_blank");
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (!config.publicProfilesEnabled) {
        setError("Public profiles are currently disabled.");
        setLoading(false);
        return;
      }

      try {
        // Query users collection by displayNameSlug
        const usersRef = collection(db, "users");
        const q = query(
          usersRef,
          where("publicProfile.displayNameSlug", "==", displayNameSlug),
          where("publicProfile.isPublic", "==", true)
        );

        const querySnapshot = await getDocs(q);

        console.log(
          "querySnapshot:",
          querySnapshot,
          displayNameSlug,
          querySnapshot.empty
        );

        if (querySnapshot.empty) {
          setError("Profile not found.");
          setLoading(false);
          return;
        }

        // Check for multiple profiles with same slug (shouldn't happen, but handle it)
        if (querySnapshot.size > 1) {
          console.error(
            "Multiple profiles found with same displayNameSlug:",
            displayNameSlug
          );
          setError("Error loading profile. Please try again later.");
          setLoading(false);
          return;
        }

        const userData = querySnapshot.docs[0].data();
        console.log("userData:", userData);

        const publicData = {
          displayName:
            userData.publicProfile?.displayName || userData.displayName,
          publicProfile: userData.publicProfile,
          splats: (userData.splats || []).filter(
            (splat: any) => splat.isPublic
          ),
        };

        console.log("publicData:", publicData);

        setProfile(publicData);
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError("Error loading profile.");
      }
      setLoading(false);
    };

    fetchProfile();
  }, [displayNameSlug]);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? (profile?.splats.length || 1) - 1 : prev - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) =>
      prev === (profile?.splats.length || 1) - 1 ? 0 : prev + 1
    );
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <GlobalStyle />
        <LoadingOverlay>
          <div style={{ textAlign: "center" }}>
            <img
              src={StorySplatLogo}
              alt="Story Splat Logo"
              style={{ width: "100px", marginBottom: "20px", opacity: 0.7 }}
            />
            Loading Profile...
          </div>
        </LoadingOverlay>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <GlobalStyle />
        <LoadingOverlay>
          <div style={{ textAlign: "center" }}>
            <img
              src={StorySplatLogo}
              alt="Story Splat Logo"
              style={{ width: "100px", marginBottom: "20px", opacity: 0.7 }}
            />
            {error}
          </div>
        </LoadingOverlay>
      </ThemeProvider>
    );
  }

  if (!profile) {
    return null;
  }

  const accentColor =
    profile.publicProfile?.customization?.accentColor || "#00ffff";
  const publicSplats = profile.splats.filter((splat) => splat.isPublic);

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Container>
        <ContentWrapper>
          <ProfileHeader>
            <StorySplatButton
              onClick={handleStorySplatClick}
              title="Visit StorySplat"
            >
              <span>
                StorySplat <FiExternalLink />
              </span>
            </StorySplatButton>
            <ThemeToggle
              onClick={toggleTheme}
              title={`Switch to ${theme === "dark" ? "light" : "dark"} mode`}
            >
              {theme === "dark" ? <FiSun size={24} /> : <FiMoon size={24} />}
            </ThemeToggle>
            <DisplayName $color={accentColor}>
              {profile.publicProfile?.displayName || profile.displayName}
            </DisplayName>
            {profile.publicProfile?.bio && (
              <Bio>{profile.publicProfile.bio}</Bio>
            )}
            <SocialLinks>
              {profile.publicProfile?.website && (
                <SocialLink
                  href={profile.publicProfile.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  $color={accentColor}
                >
                  <FiGlobe /> Website
                </SocialLink>
              )}
              {profile.publicProfile?.socialLinks?.github && (
                <SocialLink
                  href={profile.publicProfile.socialLinks.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  $color={accentColor}
                >
                  <FiGithub /> GitHub
                </SocialLink>
              )}
              {profile.publicProfile?.socialLinks?.linkedin && (
                <SocialLink
                  href={profile.publicProfile.socialLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  $color={accentColor}
                >
                  <FiLinkedin /> LinkedIn
                </SocialLink>
              )}
            </SocialLinks>
          </ProfileHeader>
          {publicSplats.length > 0 && (
            <CarouselContainer>
              <CarouselTitle $color={accentColor}>Story Splats</CarouselTitle>
              <CarouselContent>
                {publicSplats.map((splat, index) => (
                  <CarouselSlide
                    key={splat.id}
                    $active={currentSlide === index}
                  >
                    {currentSlide === index && (
                      <CarouselIframe
                        src={splat.htmlUrl}
                        title={splat.name}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      />
                    )}
                  </CarouselSlide>
                ))}
                <CarouselButton
                  onClick={handlePrevSlide}
                  $color={accentColor}
                  $direction="left"
                >
                  <FiChevronLeft size={24} />
                </CarouselButton>
                <CarouselButton
                  onClick={handleNextSlide}
                  $color={accentColor}
                  $direction="right"
                >
                  <FiChevronRight size={24} />
                </CarouselButton>
              </CarouselContent>

              <SplatInfo>
                <SplatTitle $color={accentColor}>
                  {publicSplats[currentSlide].name}
                </SplatTitle>
                {publicSplats[currentSlide].description && (
                  <SplatDescription>
                    {publicSplats[currentSlide].description}
                  </SplatDescription>
                )}
              </SplatInfo>

              <PullButtonsContainer>
                {publicSplats.map((splat, index) => (
                  <PullButton
                    key={splat.id}
                    onClick={() => setCurrentSlide(index)}
                    $active={currentSlide === index}
                    $color={accentColor}
                  >
                    <div style={{display: 'flex', flexDirection: 'row' ,  width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span className="title">{splat.name}</span>
                        {splat.description && (
                          <span className="description">
                            {splat.description}
                          </span>
                        )}
                      </div>
                      <span className="link">
                        <a
                          href={splat.htmlUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: accentColor }}
                        >
                          Open in new tab
                        </a>
                      </span>
                    </div>
                  </PullButton>
                ))}
              </PullButtonsContainer>
            </CarouselContainer>
          )}
        </ContentWrapper>
      </Container>
    </ThemeProvider>
  );
};

export default PublicProfile;
