// [Previous code remains unchanged until state declarations...]

// [Previous code remains unchanged until imports]
import React, { useState, useEffect, Suspense } from 'react';
import { useParams, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { collection, query, where, getDocs, limit, doc, updateDoc, increment, getDoc, runTransaction, Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../utils/FirebaseConfig';
import { Player } from '@lottiefiles/react-lottie-player';

import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import { UserSplat, Comment, Like } from '../types/UserTypes';
import StorySplat from '../images/StorySplat.webp';
import storySplatLottie from '../images/storySplatLottie.json';

// Defensive rendering functions
export const renderLikes = (likes?: Like[]) => {
  if (!likes || !Array.isArray(likes)) return 0;
  return likes.length;
};

export const renderComments = (comments?: Comment[]) => {
  if (!comments || !Array.isArray(comments)) return [];
  return comments
    .filter(comment => 
      comment && 
      typeof comment.id === 'string' && 
      typeof comment.userId === 'string' && 
      (comment.createdAt instanceof Date || (comment.createdAt as unknown as Timestamp).toDate)
    )
    .map(comment => ({
      ...comment,
      createdAtString: comment.createdAt instanceof Date 
        ? comment.createdAt.toLocaleDateString()
        : (comment.createdAt as unknown as Timestamp).toDate().toLocaleDateString()
    }));
};


// Types
interface Splat {
  id: string;
  name: string;
  description?: string;
  htmlUrl: string;
  thumbnailUrl: string;
  createdAt: Date;
  views?: number;
  likes?: Like[];
  isPublic: boolean;
  userId?: string;
  displayNameSlug?: string;
}

interface UserData {
  displayName: string;
  publicProfile: {
    displayName: string;
    displayNameSlug: string;
    isPublic: boolean;
  };
  splats: Splat[];
}

// [Styled components and other code remain unchanged...]

const LinkSection = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledLink = styled.a`
  color: #007bff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    text-decoration: underline;
  }
`;

// Styled Components
const PageContainer = styled.div`
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  height: 64px;
  box-sizing: border-box;
`;

const Logo = styled(Link)`
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  margin-right: 40px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #666;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Container = styled.div<{ showSidebar: boolean}>`
  display: grid;
  grid-template-columns: 1fr ${props => props.showSidebar ? '300px' : '0'};
  gap: 20px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 40px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
`;
const IframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  background: #000;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1200px) {
    height: 600px;
    max-height: 600px;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

// Custom confined preloader components
const ConfinedPreloaderContainer = styled.div<{ $isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: ${({ $isLoading }) => $isLoading ? 'auto' : 'none'};
  opacity: ${({ $isLoading }) => $isLoading ? 1 : 0};
  transition: opacity 0.5s ease-out;
`;

const ConfinedContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

const ConfinedMediaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ConfinedStoryImage = styled.img`
  height: 200px;
  width: auto;
  object-fit: contain;
  filter: invert(1);
  margin-right: -50px;

  @media (max-width: 768px) {
    height: 100px;
    margin-right: -25px;
  }
`;

const ConfinedStyledPlayer = styled(Player)`
  height: 200px;
  width: 200px;

  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;

const InfoSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
`;

const Title = styled.h1`
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #333;
`;

const Description = styled.p`
  margin: 0;
  color: #666;
  line-height: 1.6;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 20px;
  height: fit-content;
  max-height: calc(90vh - 84px);
  overflow-y: auto;
  padding-right: 20px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
`;

const SidebarTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;

const RelatedSplat = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const RelatedThumbnail = styled.div<{ url: string }>`
  width: 100%;
  height: 150px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
`;

const RelatedInfo = styled.div`
  padding: 10px;
`;

const RelatedTitle = styled.h3`
  margin: 0 0 5px 0;
  font-size: 16px;
  color: #333;
`;

const RelatedMeta = styled.div`
  font-size: 14px;
  color: #666;
`;

const LoadingState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-size: 18px;
  color: #666;
`;

const ErrorState = styled.div`
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ff4444;
  max-width: 600px;
  margin: 40px auto;
`;

const ErrorTitle = styled.h2`
  color: #ff4444;
  margin-bottom: 20px;
`;

const ErrorDescription = styled.p`
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const ErrorActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;
`;

const ActionButton = styled(Link)`
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;

  &.primary {
    background: #2c2c2c;
    color: white;
    &:hover {
      background: #404040;
    }
  }

  &.secondary {
    background: #f0f0f0;
    color: #333;
    &:hover {
      background: #e0e0e0;
    }
  }
`;

const NonPublicMessage = styled.div`
  text-align: center;
  padding: 20px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 8px;
  color: #856404;
  margin-top: 20px;
`;
const SocialSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
`;

const LikeButton = styled.button<{ isLiked: boolean }>`
  background: ${props => props.isLiked ? '#ff4081' : '#f0f0f0'};
  color: ${props => props.isLiked ? 'white' : '#333'};
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.isLiked ? '#ff1744' : '#e0e0e0'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const ScrollContainer = styled.div<{ isOverIframe: boolean }>`
  overflow-y: scroll !important;
  height: calc(100vh - 64px); /* 64px is the height of the Toolbar */
  overflow-y: ${({ isOverIframe }) => (isOverIframe ? 'hidden !important' : 'scroll')};
  margin-right: ${({ isOverIframe }) => (!isOverIframe ? '0' : '16px')};
  margin-left: 16px;


`;

const CommentSection = styled.div`
  margin-top: 20px;
`;

const CommentList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CommentItem = styled.div`
  background: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CommentAuthor = styled.span`
  font-weight: 500;
  color: #333;
`;

const CommentDate = styled.span`
  color: #666;
  font-size: 0.9em;
`;

const CommentContent = styled.p`
  margin: 0;
  color: #444;
`;

const CommentInput = styled.textarea`
  width: 96%;
  min-height: 80px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 12px;
  resize: vertical;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const CommentButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 8px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #0056b3;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const LoginPrompt = styled.div`
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;
`;
// [Previous imports remain unchanged...]

const ProBadge = styled.span<{
  tier: "free" | "pro" | "business" | "enterprise" | "Admin";
}>`
  background: ${(props) =>
    props.tier === "free"
      ? "linear-gradient(45deg, #808080, #a9a9a9)"
      : props.tier === "Admin"
        ? "linear-gradient(45deg, #800080, #DA70D6)"
        : props.tier === "business"
          ? "linear-gradient(45deg, #2196F3, #64B5F6)"
          : "linear-gradient(45deg, #ffd700, #ffa500)"};
  color: ${(props) =>
    props.tier === "free" || props.tier === "Admin" || props.tier === "business" ? "white" : "black"};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
`;

const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
`;

// Confined version of the Preloader component
const ConfinedPreloader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <ConfinedPreloaderContainer $isLoading={isLoading}>
      <ConfinedContentContainer>
        <ConfinedMediaContainer>
          <ConfinedStoryImage src={StorySplat} alt="StorySplat Logo" />
          <ConfinedStyledPlayer
            autoplay
            loop
            src={storySplatLottie}
          />
        </ConfinedMediaContainer>
      </ConfinedContentContainer>
    </ConfinedPreloaderContainer>
  );
};

const SplatView: React.FC = () => {
  const { displayNameSlug } = useParams<{ displayNameSlug: string }>();
  const [searchParams] = useSearchParams();
  const sceneId = searchParams.get('sceneId');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentSplat, setCurrentSplat] = useState<UserSplat | null>(null);
  const [relatedSplats, setRelatedSplats] = useState<UserSplat[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const [isPrivateSplat, setIsPrivateSplat] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isOverIframe, setIsOverIframe] = useState(false);

  const trackSplatView = async (userId: string, splatId: string) => {
    try {
      const trackView = httpsCallable(functions, 'socialTrackSplatView');
      await trackView({ splatOwnerId: userId, splatId });
    } catch (error) {
      console.error('Error tracking view:', error);
    }
  };

  const handleLike = async () => {
    if (!currentUser || !currentSplat || !userData || !currentSplat.isPublic) return;
  
    try {
      const handleLikeFunc = httpsCallable(functions, 'socialHandleLike');
      const result = await handleLikeFunc({
        userId: currentUser.uid,
        splatOwnerId: userData.id,
        splatId: currentSplat.id
      });

      // Update local state based on Cloud Function result
      const { likes, isLiked } = result.data as { likes: number, isLiked: boolean };
      
      // Update the current splat's likes more accurately
      const updatedSplat = {
        ...currentSplat,
        likes: isLiked 
          ? [...(currentSplat.likes || []), { 
              userId: currentUser.uid, 
              createdAt: new Date() 
            }]
          : (currentSplat.likes || []).filter(like => like.userId !== currentUser.uid)
      };

      setCurrentSplat(updatedSplat);
    } catch (error) {
      console.error('Error updating like:', error);
      alert('Unable to update like. Please check your connection and try again.');
    }
  };



  useEffect(() => {
    const fetchSplatData = async () => {
      if (!displayNameSlug || !sceneId) return;
      
      try {
        setLoading(true);
        setError(null);
        setIsPrivateSplat(false);

        // Query user by displayNameSlug
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('publicProfile.displayNameSlug', '==', displayNameSlug)
        );

        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          setError('Profile not found');
          return;
        }

        const userData = querySnapshot.docs[0].data();
        const userId = querySnapshot.docs[0].id;
        setUserData({ ...userData, id: userId });

        // Find the current splat
        const splat = userData.splats?.find((s: UserSplat) => s.id === sceneId);
        if (!splat) {
          setError('Splat not found');
          return;
        }

        // Check if splat is not public and user is not logged in
        if (splat.isPublic === false && !currentUser) {
          setIsPrivateSplat(true);
          setError('This splat is private');
          return;
        }

        // Initialize likes and comments arrays if they don't exist
        const normalizedSplat: UserSplat = {
          ...splat,
          likes: splat.likes || [],
          comments: splat.comments || [],
          isPublic: splat.isPublic ?? true,
          views: splat.views || 0
        };

        setCurrentSplat(normalizedSplat);

        // Track view for public splats or splats owned by the current user
        if (normalizedSplat.isPublic || 
            (currentUser && currentUser.uid === userId)) {
          await trackSplatView(userId, sceneId);
        }

        // Fetch related splats in the background
        fetchRelatedSplats(userId, userData, normalizedSplat);

      } catch (err) {
        console.error('Error fetching splat:', err);
        setError('Failed to load splat');
      } finally {
        setLoading(false);
      }
    };

    fetchSplatData();
  }, [displayNameSlug, sceneId, currentUser]);

  const fetchRelatedSplats = async (userId: string, userData: any, currentSplat: UserSplat) => {
    if (!currentSplat.isPublic) return;

    try {
      const usersRef = collection(db, 'users');
      // Get public splats from the same user first
      const userPublicSplats = userData.splats
        .filter((s: UserSplat) => s.isPublic && s.id !== sceneId)
        .map((s: UserSplat) => ({
          ...s,
          likes: s.likes || [],
          comments: s.comments || [],
          userId,
          displayNameSlug: userData.publicProfile.displayNameSlug
        }));

      // Get other users' public splats
      const otherUsersQuery = query(
        usersRef,
        where('publicProfile.isPublic', '==', true),
        limit(10)
      );
      
      const otherUsersSnapshot = await getDocs(otherUsersQuery);
      const otherPublicSplats: UserSplat[] = [];
      
      otherUsersSnapshot.forEach(doc => {
        if (doc.id !== userId) {
          const userData = doc.data();
          const userSplats = userData.splats
            .filter((s: UserSplat) => s.isPublic)
            .map((s: UserSplat) => ({
              ...s,
              likes: s.likes || [],
              comments: s.comments || [],
              userId: doc.id,
              displayNameSlug: userData.publicProfile.displayNameSlug
            }));
          otherPublicSplats.push(...userSplats);
        }
      });

      // Combine and sort splats
      const sortedSplats = [
        ...userPublicSplats.sort(() => Math.random() - 0.5),
        ...otherPublicSplats.sort(() => Math.random() - 0.5)
      ].slice(0, 3);

      setRelatedSplats(sortedSplats);
    } catch (error) {
      console.error('Error fetching related splats:', error);
    }
  };

const handleComment = async () => {
  if (!currentUser || !currentSplat || !userData || !newComment.trim()) return;

  try {
    setIsSubmitting(true);
    const handleCommentFunc = httpsCallable<
      { splatOwnerId: string; splatId: string; comment: { userId: string; displayName: string; content: string } },
      { success: boolean; comments: number }
    >(functions, 'socialHandleComment');
    
    const result = await handleCommentFunc({
      splatOwnerId: userData.id,
      splatId: currentSplat.id,
      comment: {
        userId: currentUser.uid,
        displayName: currentUser.displayName || 'Anonymous',
        content: newComment.trim()
      }
    });

    // Update local state based on Cloud Function result
    if (result.data.success) {
      const newCommentObj = {
        id: Date.now().toString(),
        userId: currentUser.uid,
        displayName: currentUser.displayName || 'Anonymous',
        content: newComment.trim(),
        createdAt: new Date()
      };

      // Update the current splat with the new comment
      setCurrentSplat(prevSplat => ({
        ...prevSplat!,
        comments: [...(prevSplat?.comments || []), newCommentObj]
      }));

      // Clear the comment input
      setNewComment('');
    }
  } catch (error) {
    console.error('Error adding comment:', error);
    alert('Unable to add comment. Please check your connection and try again.');
  } finally {
    setIsSubmitting(false);
  }
};

const handleRelatedSplatClick = (splatId: string, displayNameSlug: string) => {
  navigate(`/${displayNameSlug}?sceneId=${splatId}`);
};
const isLiked = currentUser && currentSplat?.likes?.some(like => like.userId === currentUser.uid);

  return (
    <PageContainer  >
      <Toolbar>
        <Logo to="/">StorySplat</Logo>
        <NavLinks>
          <NavLink to="/editor">Editor</NavLink>
          <NavLink to="/discover">Discover</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/help">Help</NavLink>
        </NavLinks>
      </Toolbar>

      {error ? (
                 <ErrorState>
                 <ErrorTitle>
                   {isPrivateSplat ? 'Private Splat' : 'Error Loading Splat'}
                 </ErrorTitle>
                 <ErrorDescription>
                   {isPrivateSplat ? (
                     <>
                       This splat is private and can only be viewed by its owner. 
                       Please check out our public splats or create your own!
                     </>
                   ) : (
                     error
                   )}
                 </ErrorDescription>
                 <ErrorActions>
                   <ActionButton to="/discover" className="primary">
                     Discover Public Splats
                   </ActionButton>
                   <ActionButton to="/editor" className="secondary">
                     Create Your Own
                   </ActionButton>
                 </ErrorActions>
               </ErrorState>
      ) : (
        <ScrollContainer isOverIframe={isOverIframe}>
        <Container showSidebar={currentSplat?.isPublic || false} >
          <MainContent>
            {currentSplat && (
              <>
                <IframeContainer 
                  onMouseEnter={() => setIsOverIframe(true)}
                  onMouseLeave={() => setIsOverIframe(false)}
                >
                  {!iframeLoaded && <ConfinedPreloader isLoading={!iframeLoaded} />}
                  <StyledIframe
                    src={currentSplat.htmlUrl}
                    title={currentSplat.name}
                    allow="accelerometer; gyroscope; xr-spatial-tracking"
                    allowFullScreen
                    onLoad={() => setIframeLoaded(true)}
                  />
                </IframeContainer>
                <InfoSection>
                  <Title>{currentSplat.name}</Title>
                  {currentSplat.description && (
                    <Description>{currentSplat.description}</Description>
                  )}
                  <AuthorSection>
                    <StyledLink href={`/profile/${displayNameSlug}`} target="_blank">
                      {displayNameSlug}
                    </StyledLink>
                    {userData?.publicProfile?.proTier && (
                      <ProBadge tier={userData.publicProfile.proTier}>
                        {userData.publicProfile.proTier.toUpperCase()}
                      </ProBadge>
                    )}
                  </AuthorSection>
                  <LinkSection>
                    <StyledLink href={currentSplat.htmlUrl} target="_blank">
                      Open in New Tab
                    </StyledLink>
                  </LinkSection>
                  {!currentSplat.isPublic && (
                  <NonPublicMessage>
                    This splat is not public and is only visible to you and those with direct access.
                  </NonPublicMessage>
                )}
                <Suspense fallback={<LoadingState>Loading social features...</LoadingState>}>
                  <SocialSection>
                    <LikeButton
                      onClick={handleLike}
                      isLiked={!!isLiked}
                      disabled={!currentUser}
                    >
                      {renderLikes(currentSplat?.likes)} {isLiked ? 'Liked' : 'Like'}
                    </LikeButton>

                    <CommentSection>
                      <h3>Comments ({renderComments(currentSplat?.comments).length})</h3>
                      {currentUser ? (
                        <>
                          <CommentInput
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                          />
                          <CommentButton
                            onClick={handleComment}
                            disabled={!newComment.trim() || isSubmitting}
                          >
                            {isSubmitting ? 'Posting...' : 'Post Comment'}
                          </CommentButton>
                        </>
                      ) : (
                        <LoginPrompt>
                          Please <Link to="/login">log in</Link> to like or comment
                        </LoginPrompt>
                      )}
                      <CommentList>
                        {renderComments(currentSplat?.comments).map(comment => (
                          <CommentItem key={comment.id}>
                            <CommentHeader>
                              <CommentAuthor>{comment.displayName}</CommentAuthor>
                              <CommentDate>
                                {comment.createdAtString}
                              </CommentDate>
                            </CommentHeader>
                            <CommentContent>{comment.content}</CommentContent>
                          </CommentItem>
                        ))}
                      </CommentList>
                    </CommentSection>
                  </SocialSection>
                </Suspense>
              </InfoSection>
            </>
          )}
        </MainContent>

        {currentSplat?.isPublic && (
          <Sidebar>
            <SidebarTitle>Related Public Splats</SidebarTitle>
            {relatedSplats.map(splat => (
              <RelatedSplat
                key={splat.id}
                onClick={() => handleRelatedSplatClick(splat.id, splat.displayNameSlug || '')}
              >
                <RelatedThumbnail url={splat.thumbnailUrl || ''} />
                <RelatedInfo>
                  <RelatedTitle>{splat.name}</RelatedTitle>
                  <RelatedMeta>
                    {splat.views || 0} views • {splat.likes?.length ?? 0} likes
                  </RelatedMeta>
                </RelatedInfo>
              </RelatedSplat>
            ))}
          </Sidebar>
        )}
      </Container>
      </ScrollContainer>
    )}
  </PageContainer>
);
};

export default SplatView;



