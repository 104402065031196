/*
 * TopToolbar.tsx
 * Description: Main toolbar component for the application containing various controls and menus
 * Last modified: 2024-02-14
 * Changes:
 * - Added hotspotManagerRef prop to connect HotspotManager ref through component hierarchy
 * - Removed onCapturePreview prop as it's now handled in UserDashboard
 * - Added lighting controls menu
 * - Added isModelLocal prop to TopToolbarProps interface
 * - Added pricing button
 * - Added currentLoadedSceneId prop
 */

import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  FiInfo,
  FiMenu,
  FiSettings,
  FiMapPin,
  FiTarget,
  FiBox,
  FiHelpCircle,
  FiSun,
} from "react-icons/fi";
import { FaDiscord } from "react-icons/fa";
import LoadSaveExportMenu from "./LoadSaveExportMenu";
import ParameterControls from "./ParameterControls";
import Controls from "./Controls";
import { Link } from "react-router-dom";
import LicenseButton from "./LicenseButton";
import WaypointControls from "./WaypointControls";
import HotspotManager from "./HotspotManager";
import CollisionControls from "./CollisionControls";
import HelpContent from "./HelpContent";
import LightingControls from "./Scene/LightingControls";
import {
  Waypoint,
  Hotspot,
  SerializedMeshData,
  SceneLight,
  CameraMode,
} from "../types/SceneTypes";
import * as BABYLON from "@babylonjs/core";
import { AbstractMesh } from "@babylonjs/core";
import config from "../config";

interface TopToolbarProps {
  lights: SceneLight[];
  setLights: (lights: SceneLight[]) => void;
  isLightEditMode: boolean;
  setIsLightEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLightIndex: number | null;
  setSelectedLightIndex: React.Dispatch<React.SetStateAction<number | null>>;

  // File Menu Props
  setLoadedModelUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setIsModelLocal: React.Dispatch<React.SetStateAction<boolean>>;
  customModelUrl: string;
  setCustomModelUrl: React.Dispatch<React.SetStateAction<string>>;
  handleExport: () => void;
  resetSettings: () => void;
  saveToJson: () => void;
  loadFromJson: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadedMeshes: AbstractMesh[] | null;
  isModelLocal: boolean;
  loadedModelUrl: string | null;
  currentLoadedSceneId: string | null;

  // Settings Props
  scrollSpeed: number;
  setScrollSpeed: React.Dispatch<React.SetStateAction<number>>;
  animationFrames: number;
  setAnimationFrames: React.Dispatch<React.SetStateAction<number>>;
  cameraMovementSpeed: number;
  setCameraMovementSpeed: React.Dispatch<React.SetStateAction<number>>;
  cameraRotationSensitivity: number;
  setCameraRotationSensitivity: React.Dispatch<React.SetStateAction<number>>;
  cameraSwing: number;
  setCameraSwing: React.Dispatch<React.SetStateAction<number>>;  // Fixed type
  backgroundColor: string;
  setBackgroundColor: (color: string) => void;
  uiColor: string;
  setUiColor: (color: string) => void;
  useCorsProxy: boolean;
  setUseCorsProxy: React.Dispatch<React.SetStateAction<boolean>>;
  corsProxyUrl: string;
  setCorsProxyUrl: React.Dispatch<React.SetStateAction<string>>;
  autoFrame: boolean;
  setAutoFrame: React.Dispatch<React.SetStateAction<boolean>>;

  // Scene Props
  scene: BABYLON.Scene | null;
  camera: BABYLON.Camera | null;

  // Waypoint Props
  waypoints: Waypoint[];
  setWaypoints: React.Dispatch<React.SetStateAction<Waypoint[]>>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  cameraMode: CameraMode;
  setCameraMode: React.Dispatch<React.SetStateAction<CameraMode>>;
  loopMode: boolean;
  setLoopMode: React.Dispatch<React.SetStateAction<boolean>>;
  autoPlaySpeed: number;
  setAutoPlaySpeed: React.Dispatch<React.SetStateAction<number>>;
  autoPlayEnabled: boolean;
  setAutoPlayEnabled: React.Dispatch<React.SetStateAction<boolean>>;

  // Hotspot Props
  hotspots: Hotspot[];
  setHotspots: React.Dispatch<React.SetStateAction<Hotspot[]>>;
  hotspotManagerRef: React.RefObject<any>;

  // Collision Props
  collisionMeshesData: SerializedMeshData[];
  setCollisionMeshesData: React.Dispatch<
    React.SetStateAction<SerializedMeshData[]>
  >;
  isCollisionEditMode: boolean;
  setIsCollisionEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveControl: React.Dispatch<
    React.SetStateAction<"move" | "rotate" | "scale" | undefined>
  >;
  activeControl?: "move" | "rotate" | "scale" | undefined;
  selectedMeshIndex: number | null;
  setSelectedMeshIndex: React.Dispatch<React.SetStateAction<number | null>>;
}



const ToolbarContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  z-index: 1001;
`;

const VerticalToolbarContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1001;
  width: 160px;
`;

const DropdownButton = styled.button<{
  $isOpen: boolean;
  $isVertical?: boolean;
}>`
  background-color: ${({ $isOpen }) => ($isOpen ? "#4CAF50" : "#2c2c2c")};
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: ${({ $isVertical }) => ($isVertical ? "160px" : "auto")};

  svg {
    margin-right: 5px;
  }
`;

const DropdownContent = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  position: absolute;
  min-width: 160px;
  z-index: 1;
  border-radius: 5px;
`;

const HelpDropdownContent = styled(DropdownContent)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;

  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
`;

const DiscordButton = styled.a`
  background-color: #5865f2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
    height: 18px;
  }
`;

const ExamplesButton = styled(Link)`
  background-color: #2c2c2c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PricingButton = styled(Link)`
  background-color: #d4af37;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const DiscoveryButton = styled(Link)`
  background-color: #f76900;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    110deg,
    #f76900 20%,
    #ff8533 40%,
    #ff8533 60%,
    #f76900 80%
  );
  background-size: 200% auto;
  animation: ${shimmer} 6s linear infinite;
`;
const TopToolbar: React.FC<TopToolbarProps> = ({
  lights,
  setLights,
  isLightEditMode,
  setIsLightEditMode,
  selectedLightIndex,
  setSelectedLightIndex,
  setLoadedModelUrl,
  setIsModelLocal,
  customModelUrl,
  setCustomModelUrl,
  handleExport,
  resetSettings,
  saveToJson,
  loadFromJson,
  loadedMeshes,
  isModelLocal,
  loadedModelUrl,
  currentLoadedSceneId,
  waypoints,
  setWaypoints,
  scene,
  camera,
  hotspots,
  setHotspots,
  collisionMeshesData,
  setCollisionMeshesData,
  isCollisionEditMode,
  setIsCollisionEditMode,
  setActiveControl,
  activeControl,
  selectedMeshIndex,
  setSelectedMeshIndex,
  isEditMode,
  setIsEditMode,
  cameraMode,
  setCameraMode,
  loopMode,
  setLoopMode,
  autoPlaySpeed,
  setAutoPlaySpeed,
  autoPlayEnabled,
  setAutoPlayEnabled,
  ...props
}) => {
  const [isFileDropdownOpen, setIsFileDropdownOpen] = React.useState(false);
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = React.useState(false);
  const [isControlsDropdownOpen, setIsControlsDropdownOpen] = React.useState(false);
  const [isWaypointsOpen, setIsWaypointsOpen] = React.useState(false);
  const [isHotspotsOpen, setIsHotspotsOpen] = React.useState(false);
  const [isCollisionOpen, setIsCollisionOpen] = React.useState(false);
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const [isLightingOpen, setIsLightingOpen] = React.useState(false);

  useEffect(() => {
    if (loadedMeshes && loadedMeshes.length > 0) {
      setIsWaypointsOpen(true);
    }
  }, [loadedMeshes]);

  const closeAllMenusExcept = (menusToKeepOpen: string[]) => {
    if (!menusToKeepOpen.includes("file")) setIsFileDropdownOpen(false);
    if (!menusToKeepOpen.includes("settings")) setIsSettingsDropdownOpen(false);
    if (!menusToKeepOpen.includes("controls")) setIsControlsDropdownOpen(false);
    if (!menusToKeepOpen.includes("waypoints")) setIsWaypointsOpen(false);
    if (!menusToKeepOpen.includes("hotspots")) setIsHotspotsOpen(false);
    if (!menusToKeepOpen.includes("collision")) setIsCollisionOpen(false);
    if (!menusToKeepOpen.includes("help")) setIsHelpOpen(false);
    if (!menusToKeepOpen.includes("lighting")) setIsLightingOpen(false);
  };

  const handleFileClick = () => {
    const newState = !isFileDropdownOpen;
    if (newState) closeAllMenusExcept(["file", "controls"]);
    setIsFileDropdownOpen(newState);
  };

  const handleSettingsClick = () => {
    const newState = !isSettingsDropdownOpen;
    if (newState) closeAllMenusExcept(["settings", "controls"]);
    setIsSettingsDropdownOpen(newState);
  };

  const handleControlsClick = () => {
    const newState = !isControlsDropdownOpen;
    setIsControlsDropdownOpen(newState);
  };

  const handleWaypointsClick = () => {
    const newState = !isWaypointsOpen;
    if (newState)
      closeAllMenusExcept(["waypoints", "hotspots", "collision", "lighting"]);
    setIsWaypointsOpen(newState);
  };

  const handleHotspotsClick = () => {
    const newState = !isHotspotsOpen;
    if (newState)
      closeAllMenusExcept(["hotspots", "waypoints", "collision", "lighting"]);
    setIsHotspotsOpen(newState);
  };

  const handleCollisionClick = () => {
    const newState = !isCollisionOpen;
    if (newState)
      closeAllMenusExcept(["collision", "waypoints", "hotspots", "lighting"]);
    setIsCollisionOpen(newState);
  };

  const handleLightingClick = () => {
    const newState = !isLightingOpen;
    if (newState)
      closeAllMenusExcept(["lighting", "waypoints", "hotspots", "collision"]);
    setIsLightingOpen(newState);
  };

  const handleHelpClick = () => {
    const newState = !isHelpOpen;
    if (newState) closeAllMenusExcept(["help"]);
    setIsHelpOpen(newState);
  };

  return (
    <>
      <ToolbarContainer>
        <DropdownButton
          $isOpen={isFileDropdownOpen}
          onClick={handleFileClick}
          $isVertical={true}
        >
          <FiMenu />
          File
        </DropdownButton>
        <DropdownContent $isOpen={isFileDropdownOpen}>
          <LoadSaveExportMenu
            setLoadedModelUrl={setLoadedModelUrl}
            setIsModelLocal={setIsModelLocal}
            customModelUrl={customModelUrl}
            setCustomModelUrl={setCustomModelUrl}
            handleExport={handleExport}
            resetSettings={resetSettings}
            saveToJson={saveToJson}
            loadFromJson={loadFromJson}
            waypoints={waypoints}
            isModelLocal={isModelLocal}
            loadedModelUrl={loadedModelUrl}
            currentLoadedSceneId={currentLoadedSceneId}
          />
        </DropdownContent>

        <DropdownButton
          $isOpen={isControlsDropdownOpen}
          onClick={handleControlsClick}
        >
          <FiInfo />
          Controls
        </DropdownButton>
        <DropdownContent $isOpen={isControlsDropdownOpen}>
          <Controls />
        </DropdownContent>

        <DropdownButton $isOpen={isHelpOpen} onClick={handleHelpClick}>
          <FiHelpCircle />
          Help
        </DropdownButton>
        <HelpDropdownContent $isOpen={isHelpOpen}>
          <HelpContent />
        </HelpDropdownContent>

        <DiscoveryButton to="/discover">Discover</DiscoveryButton>
        {config.stripeIntegrationEnabled && (
          <PricingButton to="/pricing">Pricing</PricingButton>
        )}

        <LicenseButton />
        <DiscordButton
          href="https://discord.com/invite/ddmycPPS8z"
          target="_blank"
        >
          <FaDiscord />
          Join Discord
        </DiscordButton>
      </ToolbarContainer>

      <VerticalToolbarContainer>
        <DropdownButton
          $isOpen={isSettingsDropdownOpen}
          onClick={handleSettingsClick}
          $isVertical={true}
        >
          <FiSettings />
          Settings
        </DropdownButton>
        <DropdownContent $isOpen={isSettingsDropdownOpen}>
          <ParameterControls {...props} />
        </DropdownContent>

        <DropdownButton
          $isOpen={isWaypointsOpen}
          onClick={handleWaypointsClick}
          $isVertical={true}
        >
          <FiMapPin />
          Waypoints
        </DropdownButton>
        <DropdownContent $isOpen={isWaypointsOpen}>
          <WaypointControls
            waypoints={waypoints}
            setWaypoints={setWaypoints}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            scene={scene}
            cameraMode={cameraMode}
            setCameraMode={setCameraMode}
            loopMode={loopMode}
            setLoopMode={setLoopMode}
            autoPlaySpeed={autoPlaySpeed}
            setAutoPlaySpeed={setAutoPlaySpeed}
            autoPlayEnabled={autoPlayEnabled}
            setAutoPlayEnabled={setAutoPlayEnabled}
          />
        </DropdownContent>

        <DropdownButton
          $isOpen={isHotspotsOpen}
          onClick={handleHotspotsClick}
          $isVertical={true}
        >
          <FiTarget />
          Hotspots
        </DropdownButton>
        <DropdownContent $isOpen={isHotspotsOpen}>
          <HotspotManager
            ref={props.hotspotManagerRef} 
            scene={scene}
            camera={camera}
            hotspots={hotspots}
            setHotspots={setHotspots}
          />
        </DropdownContent>
        
        <DropdownButton
          $isOpen={isCollisionOpen}
          onClick={handleCollisionClick}
          $isVertical={true}
        >
          <FiBox />
          Collision
        </DropdownButton>
        <DropdownContent $isOpen={isCollisionOpen}>
          <CollisionControls
            camera={camera}
            collisionMeshesData={collisionMeshesData}
            setCollisionMeshesData={setCollisionMeshesData}
            isCollisionEditMode={isCollisionEditMode}
            setIsCollisionEditMode={setIsCollisionEditMode}
            setActiveControl={setActiveControl}
            activeControl={activeControl}
            selectedMeshIndex={selectedMeshIndex}
            setSelectedMeshIndex={setSelectedMeshIndex}
          />
        </DropdownContent>

        <DropdownButton
          $isOpen={isLightingOpen}
          onClick={handleLightingClick}
          $isVertical={true}
        >
          <FiSun />
          Lighting
        </DropdownButton>
        <DropdownContent $isOpen={isLightingOpen}>
          <LightingControls
            lights={lights}
            onLightsChange={setLights}
            isLightEditMode={isLightEditMode}
            setIsLightEditMode={setIsLightEditMode}
            selectedLightIndex={selectedLightIndex}
            setSelectedLightIndex={setSelectedLightIndex}
            activeControl={activeControl}
            setActiveControl={setActiveControl}
            camera={camera}
          />
        </DropdownContent>
      </VerticalToolbarContainer>
    </>
  );
};

export default TopToolbar;

