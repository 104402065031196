import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/core/Gizmos/gizmoManager';
import Draggable from 'react-draggable';
import HotspotContentDisplay from './HotspotContentDisplay';
import styled from 'styled-components';
import { FiMove, FiRotateCw, FiMaximize } from 'react-icons/fi';
import { storage } from '../utils/FirebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';

import { Hotspot } from '../types/SceneTypes';
import { useSceneState } from '../hooks/useSceneState';
import { render } from '@testing-library/react';

interface HotspotManagerProps {
  scene: BABYLON.Scene | null;
  camera: BABYLON.Camera | null;
  hotspots: Hotspot[];
  setHotspots: React.Dispatch<React.SetStateAction<Hotspot[]>>;
}

interface HotspotManagerRef {
  renderHotspots: () => void;
}

const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 400px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 320px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  @media (max-width: 480px) {
    width: 90%;
    left: 5%;
  }
`;

const Handle = styled.div<{ $isCollapsed?: boolean }>`
  padding: 16px;
  cursor: move;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${props => props.$isCollapsed ? "8px" : ""};
  border-bottom-right-radius: ${props => props.$isCollapsed ? "8px" : ""};
  user-select: none;
`;

const Content = styled.div<{ $isCollapsed: boolean }>`
  max-height: ${props => props.$isCollapsed ? "0px" : "400px"};
  opacity: ${props => props.$isCollapsed ? 0 : 1};
  padding: ${props => props.$isCollapsed ? "0 10px" : "10px"};
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow-y: auto;
  background-color: #1e1e1e;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1e90ff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #45a049;
  }
`;

const HotspotList = styled.div`
  margin-bottom: 15px;
`;

const HotspotItem = styled.div<{ $isSelected?: boolean }>`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.$isSelected ? 'rgba(0, 123, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)'};
  padding: 8px;
  border-radius: 5px;
  border: ${props => props.$isSelected ? '2px solid #007BFF' : 'none'};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$isSelected ? 'rgba(0, 123, 255, 0.4)' : 'rgba(255, 255, 255, 0.2)'};
  }
`;

const EditButton = styled.button<{ $isSelected?: boolean }>`
  padding: 5px 10px;
  background-color: ${props => props.$isSelected ? '#0056b3' : '#008CBA'};
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$isSelected ? '#004494' : '#007B9A'};
  }
`;

const ControlButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const ControlButton = styled.button<{ $active?: boolean }>`
  flex: 1;
  padding: 8px;
  background-color: ${props => props.$active ? '#007BFF' : '#555555'};
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;

  &:hover {
    background-color: ${props => props.$active ? '#0056b3' : '#666666'};
  }
`;

const ImageUploadInput = styled.input`
  width: 100%;
  margin-top: 5px;
  color: white;
  &::-webkit-file-upload-button {
    background-color: #007BFF;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SectionTitle = styled.h4`
  margin: 15px 0 5px 0;
  color: #ffffff;
  font-size: 14px;
`;

const HotspotManager = forwardRef<HotspotManagerRef, HotspotManagerProps>((props, forwardedRef) => {
  const { scene, camera, hotspots, setHotspots } = props;
  const [selectedHotspot, setSelectedHotspot] = useState<Hotspot | null>(null);
  const [displayedHotspot, setDisplayedHotspot] = useState<Hotspot | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeControl, setActiveControl] = useState<'move' | 'rotate' | 'scale'>('move');
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const gizmoManagerRef = useRef<BABYLON.GizmoManager | null>(null);
  const nodeRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useAuth();

  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed) {
      setSelectedHotspot(null);
    }
  };

  useEffect(() => {
    if (scene?.getEngine()) {
      canvasRef.current = scene.getEngine().getRenderingCanvas();
      
      if (!gizmoManagerRef.current) {
        gizmoManagerRef.current = new BABYLON.GizmoManager(scene);
        gizmoManagerRef.current.positionGizmoEnabled = true;
        gizmoManagerRef.current.rotationGizmoEnabled = false;
        gizmoManagerRef.current.scaleGizmoEnabled = false;
        gizmoManagerRef.current.usePointerToAttachGizmos = false;
      }
      
      renderHotspots();
    }

    return () => {
      if (gizmoManagerRef.current) {
        gizmoManagerRef.current.dispose();
        gizmoManagerRef.current = null;
      }
    };
  }, [scene, hotspots ]);

  useEffect(() => {
    if (!scene || !gizmoManagerRef.current) return;

    gizmoManagerRef.current.attachToMesh(null);

    if (selectedHotspot) {
      const mesh = scene.getMeshByName(`hotspot-${selectedHotspot.id}`);
      if (mesh) {
        gizmoManagerRef.current.attachToMesh(mesh);
      }
    }
  }, [selectedHotspot, scene]);

  useEffect(() => {
    if (!gizmoManagerRef.current) return;

    gizmoManagerRef.current.positionGizmoEnabled = activeControl === 'move';
    gizmoManagerRef.current.rotationGizmoEnabled = activeControl === 'rotate';
    gizmoManagerRef.current.scaleGizmoEnabled = activeControl === 'scale';
  }, [activeControl]);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, hotspotId: string, imageType: '3d' | 'info') => {
    const file = event.target.files?.[0];
    if (!file || !scene || !currentUser) return;

    try {
      const hotspot = hotspots.find(h => h.id === hotspotId);
      if (!hotspot) return;

      // Delete old image if it exists
      if (imageType === '3d' && hotspot.type === 'image' && hotspot.imageUrl) {
        try {
          const oldImagePath = `users/${currentUser.uid}/hotspot-images/${hotspotId}_3d_${hotspot.imageName}`;
          const oldImageRef = ref(storage, oldImagePath);
          await deleteObject(oldImageRef);
        } catch (error) {
          console.error('Error deleting old 3D image:', error);
        }
      } else if (imageType === 'info' && hotspot.photoUrl) {
        try {
          const oldImagePath = `users/${currentUser.uid}/hotspot-images/${hotspotId}_info_${hotspot.imageName}`;
          const oldImageRef = ref(storage, oldImagePath);
          await deleteObject(oldImageRef);
        } catch (error) {
          console.error('Error deleting old info image:', error);
        }
      }

      // Upload new image
      const imageRef = ref(storage, `users/${currentUser.uid}/hotspot-images/${hotspotId}_${imageType}_${file.name}`);
      await uploadBytes(imageRef, file);
      const imageUrl = await getDownloadURL(imageRef);

      const updatedHotspots = hotspots.map(h => {
        if (h.id === hotspotId) {
          if (imageType === '3d') {
            return {
              ...h,
              type: 'image' as const,
              imageUrl,
              imageName: file.name
            };
          } else {
            return {
              ...h,
              photoUrl: imageUrl,
              imageName: file.name
            };
          }
        }
        return h;
      });
      
      setHotspots(updatedHotspots);
      renderHotspots();
    } catch (error) {
      console.error('Error uploading hotspot image:', error);
      alert('Failed to upload image. Please try again.');
    }
  };

  const addHotspot = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    
    if (!scene || !camera) return;

    const forward = camera.getForwardRay().direction;
    const position = camera.position.add(forward.scale(5));

    const newHotspot: Hotspot = {
      id: `hotspot-${Date.now()}`,
      position: position,
      scale: new BABYLON.Vector3(1, 1, 1),
      rotation: new BABYLON.Vector3(0, 0, 0),
      title: `Hotspot ${hotspots.length + 1}`,
      activationMode: 'click',
      color: '#ff0000',
      type: 'sphere'
    };
    
    setHotspots([...hotspots, newHotspot]);
    setSelectedHotspot(newHotspot);
    renderHotspots();
  };

  const deleteHotspot = async (id: string) => {
    if (currentUser) {
      const hotspot = hotspots.find(h => h.id === id);
      if (hotspot) {
        // Delete 3D image if it exists
        if (hotspot.type === 'image' && hotspot.imageUrl) {
          try {
            const imagePath = `users/${currentUser.uid}/hotspot-images/${id}_3d_${hotspot.imageName}`;
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('Error deleting 3D hotspot image:', error);
          }
        }
        // Delete info image if it exists
        if (hotspot.photoUrl) {
          try {
            const imagePath = `users/${currentUser.uid}/hotspot-images/${id}_info_${hotspot.imageName}`;
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
          } catch (error) {
            console.error('Error deleting info image:', error);
          }
        }
      }
    }

    setHotspots(hotspots.filter(h => h.id !== id));
    setSelectedHotspot(null);
  };

  const createHotspotMesh = (hotspot: Hotspot, scene: BABYLON.Scene) => {
    let mesh: BABYLON.Mesh;

    if (hotspot.type === 'image' && hotspot.imageUrl) {
      mesh = BABYLON.MeshBuilder.CreatePlane(`hotspot-${hotspot.id}`, { size: 1 }, scene);
      const material = new BABYLON.StandardMaterial(`hotspot-material-${hotspot.id}`, scene);
      
      // Create texture with error handling
      const texture = new BABYLON.Texture(hotspot.imageUrl, scene, false, false, undefined, 
        () => {
          console.error(`Failed to load hotspot image:`);
          // If texture fails to load, fallback to sphere
          mesh.dispose();
          mesh = createSphereMesh(hotspot, scene);
        }
      );
      
      material.diffuseTexture = texture;
      material.emissiveColor = BABYLON.Color3.White();
      material.backFaceCulling = false;
      mesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
      mesh.material = material;
    } else {
      console.log('Creating sphere hotspot:', hotspot);
      mesh = createSphereMesh(hotspot, scene);
    }

    mesh.position = hotspot.position;
    mesh.scaling = hotspot.scale;
    mesh.rotation = hotspot.rotation;

    return mesh;
  };

  const createSphereMesh = (hotspot: Hotspot, scene: BABYLON.Scene) => {
    const mesh = BABYLON.MeshBuilder.CreateSphere(`hotspot-${hotspot.id}`, { diameter: 0.2 }, scene);
    const material = new BABYLON.StandardMaterial(`hotspot-material-${hotspot.id}`, scene);
    material.diffuseColor = BABYLON.Color3.FromHexString(hotspot.color);
    material.emissiveColor = BABYLON.Color3.FromHexString(hotspot.color).scale(0.5);
    mesh.material = material;
    return mesh;
  };

  const renderHotspots = () => {
    if (!scene) return;

    console.log('HOTSPOT MANAGER:: Rendering hotspots...');
    
    scene.meshes.forEach(mesh => {
      if (mesh.name.startsWith('hotspot-')) {
        mesh.dispose();
      }
    });

    hotspots.forEach(hotspot => {
      const mesh = createHotspotMesh(hotspot, scene);
      
      mesh.actionManager = new BABYLON.ActionManager(scene);
      
      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOverTrigger,
          () => {
            if (hotspot.type !== 'image') {
              (mesh.material as BABYLON.StandardMaterial).emissiveColor = 
                BABYLON.Color3.FromHexString(hotspot.color);
            }
            if (hotspot.activationMode === 'hover') {
              setDisplayedHotspot(hotspot);
            }
          }
        )
      );

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOutTrigger,
          () => {
            if (hotspot.type !== 'image') {
              (mesh.material as BABYLON.StandardMaterial).emissiveColor = 
                BABYLON.Color3.FromHexString(hotspot.color).scale(0.5);
            }
            if (hotspot.activationMode === 'hover') {
              setDisplayedHotspot(null);
            }
          }
        )
      );

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            if (hotspot.activationMode === 'click') {
              setDisplayedHotspot(hotspot);
            }
          }
        )
      );
    });
  };

  useImperativeHandle<HotspotManagerRef, HotspotManagerRef>(
    forwardedRef,
    () => ({
      renderHotspots
    }),
    [scene, hotspots]
  );

  const updateHotspot = (updatedHotspot: Hotspot) => {
    if (!scene) return;
    
    const mesh = scene.getMeshByName(`hotspot-${updatedHotspot.id}`);
    if (mesh) {
      updatedHotspot.position = mesh.position.clone();
      updatedHotspot.scale = mesh.scaling.clone();
      updatedHotspot.rotation = mesh.rotation.clone();
    }
    
    setHotspots(hotspots.map(h => h.id === updatedHotspot.id ? updatedHotspot : h));
    setSelectedHotspot(updatedHotspot);

    if (gizmoManagerRef.current && mesh) {
      gizmoManagerRef.current.attachToMesh(mesh);
    }
  };

  return (
    <>
      <Draggable handle=".handle" nodeRef={nodeRef}>
        <Container
          ref={nodeRef}
          onDragEnter={handleDragEvent}
          onDragOver={handleDragEvent}
          onDragLeave={handleDragEvent}
          onDrop={handleDragEvent}
        >
          <Handle 
            $isCollapsed={isCollapsed} 
            className="handle"
            onDragEnter={handleDragEvent}
            onDragOver={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDrop={handleDragEvent}
          >
            <Header>
              <Title>Hotspots</Title>
              <ToggleButton onClick={toggleCollapse}>
                {isCollapsed ? '▼' : '▲'}
              </ToggleButton>
            </Header>
          </Handle>
          <Content $isCollapsed={isCollapsed}>
            {selectedHotspot && (
              <ControlButtonGroup>
                <ControlButton
                  onClick={() => setActiveControl('move')}
                  $active={activeControl === 'move'}
                >
                  <FiMove /> Move
                </ControlButton>
                <ControlButton
                  onClick={() => setActiveControl('rotate')}
                  $active={activeControl === 'rotate'}
                >
                  <FiRotateCw /> Rotate
                </ControlButton>
                <ControlButton
                  onClick={() => setActiveControl('scale')}
                  $active={activeControl === 'scale'}
                >
                  <FiMaximize /> Scale
                </ControlButton>
              </ControlButtonGroup>
            )}

            <Button onClick={addHotspot}>
              Add Hotspot
            </Button>
            
            {hotspots.length > 0 && (
              <HotspotList>
                <h3>Hotspot List</h3>
                {hotspots.map((hotspot) => (
                  <HotspotItem key={hotspot.id} $isSelected={selectedHotspot?.id === hotspot.id}>
                    <span>{hotspot.title}</span>
                    <EditButton 
                      onClick={() => setSelectedHotspot(hotspot)}
                      $isSelected={selectedHotspot?.id === hotspot.id}
                    >
                      Edit
                    </EditButton>
                  </HotspotItem>
                ))}
              </HotspotList>
            )}

            {selectedHotspot && (
              <div style={{ marginTop: '15px', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '15px', borderRadius: '5px' }}>
                <h3 style={{ margin: '0 0 10px 0' }}>Edit Hotspot</h3>
                <input
                  type="text"
                  value={selectedHotspot.title}
                  onChange={(e) => updateHotspot({ ...selectedHotspot, title: e.target.value })}
                  placeholder="Title"
                  style={{ width: '96%', marginBottom: '10px', padding: '8px', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white', border: 'none', borderRadius: '3px' }}
                />
                
                <div style={{ marginBottom: '10px' }}>
                  <label style={{ display: 'block', marginBottom: '5px', color: 'white' }}>Hotspot Type:</label>
                  <select
                    value={selectedHotspot.type}
                    onChange={(e) => updateHotspot({ ...selectedHotspot, type: e.target.value as 'sphere' | 'image' })}
                    style={{ width: '100%', padding: '5px', backgroundColor: 'white', color: 'black', border: 'none', borderRadius: '3px' }}
                  >
                    <option value="sphere">Sphere</option>
                    <option value="image">Image Plane</option>
                  </select>
                </div>

                {selectedHotspot.type === 'image' && (
                  <div style={{ marginBottom: '10px' }}>
                    <SectionTitle>3D Billboard Image:</SectionTitle>
                    <input
                      type="text"
                      value={selectedHotspot.imageUrl || ''}
                      onChange={(e) => updateHotspot({ ...selectedHotspot, imageUrl: e.target.value })}
                      placeholder="Enter image URL"
                      style={{ width: '96%', marginBottom: '10px', padding: '8px', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white', border: 'none', borderRadius: '3px' }}
                    />
                    <ImageUploadInput
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, selectedHotspot.id, '3d')}
                    />
                  </div>
                )}

                <div style={{ marginBottom: '10px' }}>
                  <SectionTitle>Info Display Image:</SectionTitle>
                  <input
                    type="text"
                    value={selectedHotspot.photoUrl || ''}
                    onChange={(e) => updateHotspot({ ...selectedHotspot, photoUrl: e.target.value })}
                    placeholder="Enter info image URL"
                    style={{ width: '96%', marginBottom: '10px', padding: '8px', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white', border: 'none', borderRadius: '3px' }}
                  />
                  <ImageUploadInput
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, selectedHotspot.id, 'info')}
                  />
                </div>

                <textarea
                  value={selectedHotspot.information || ''}
                  onChange={(e) => updateHotspot({ ...selectedHotspot, information: e.target.value })}
                  placeholder="Information"
                  style={{ width: '96%', marginBottom: '10px', padding: '8px', backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white', border: 'none', borderRadius: '3px', minHeight: '100px' }}
                />

                <div style={{ marginBottom: '10px' }}>
                  <label style={{ display: 'block', marginBottom: '5px', color: 'white' }}>Activation Mode:</label>
                  <select
                    value={selectedHotspot.activationMode}
                    onChange={(e) => updateHotspot({ ...selectedHotspot, activationMode: e.target.value as 'click' | 'hover' })}
                    style={{ width: '100%', padding: '5px', backgroundColor: 'white', color: 'black', border: 'none', borderRadius: '3px' }}
                  >
                    <option value="click">Click</option>
                    <option value="hover">Hover</option>
                  </select>
                </div>

                {selectedHotspot.type !== 'image' && (
                  <div style={{ marginBottom: '10px' }}>
                    <label style={{ display: 'block', marginBottom: '5px', color: 'white' }}>Color:</label>
                    <input
                      type="color"
                      value={selectedHotspot.color}
                      onChange={(e) => updateHotspot({ ...selectedHotspot, color: e.target.value })}
                      style={{ width: '100%', height: '40px', padding: '0', border: 'none', borderRadius: '3px' }}
                    />
                  </div>
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={() => deleteHotspot(selectedHotspot.id)} style={{ width: '48%', backgroundColor: '#f44336' }}>
                    Delete
                  </Button>
                  <Button onClick={() => setSelectedHotspot(null)} style={{ width: '48%', backgroundColor: '#555' }}>
                    Close
                  </Button>
                </div>
              </div>
            )}
          </Content>
        </Container>
      </Draggable>
      {displayedHotspot && (
        <HotspotContentDisplay
          hotspot={displayedHotspot}
          onClose={() => setDisplayedHotspot(null)}
          showCloseButton={displayedHotspot.activationMode === 'click'}
          mousePosition={{ x: scene?.pointerX || 0, y: scene?.pointerY || 0 }}
        />
      )}
    </>
  );
});

export default HotspotManager;
