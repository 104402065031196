import { useCallback } from 'react';
import * as BABYLON from "@babylonjs/core";
import { CameraMode, Waypoint } from '../types/SceneTypes';

interface UseSceneNavigationProps {
  pathRef: React.MutableRefObject<BABYLON.Vector3[]>;
  scrollTargetRef: React.MutableRefObject<number>;
  scrollPositionRef: React.MutableRefObject<number>;
  userControlRef: React.MutableRefObject<boolean>;
  cameraModeRef: React.MutableRefObject<CameraMode>;
  loopModeRef: React.MutableRefObject<boolean>;
}

export const useSceneNavigation = ({
  pathRef,
  scrollTargetRef,
  scrollPositionRef,
  userControlRef,
  cameraModeRef,
  loopModeRef,
}: UseSceneNavigationProps) => {
  const adjustScroll = useCallback((direction: number) => {
    if (cameraModeRef.current === "explore") {
      return;
    }

    const pathLength = pathRef.current.length;
    if (pathLength > 1) {
      const scrollIncrement = (pathLength - 1) * direction;
      scrollTargetRef.current += scrollIncrement;

      if (loopModeRef.current) {
        // Use threshold values for smooth looping
        if (scrollTargetRef.current >= pathLength - 1.1) {
          scrollTargetRef.current = 0.2;
          scrollPositionRef.current = 0.2;
        } else if (scrollTargetRef.current <= 0.1) {
          scrollTargetRef.current = pathLength - 1.11;
          scrollPositionRef.current = pathLength - 1.11;
        }
      } else {
        // Clamp within bounds when not looping
        scrollTargetRef.current = Math.max(
          0,
          Math.min(scrollTargetRef.current, pathLength - 1)
        );
      } 

      userControlRef.current = false;
    }
  }, []);


  const moveToWaypoint = useCallback((direction: number, waypoints: Waypoint[]) => {
    if (cameraModeRef.current === "explore") {
      return;
    }

    const subdivisionsPerSegment = 20;
    let currentWaypointIndex = Math.round(
      scrollPositionRef.current / subdivisionsPerSegment
    );
    let nextWaypointIndex = currentWaypointIndex + direction;

    if (loopModeRef.current) {
      // Use threshold values for smooth looping
      const totalSegments = (waypoints.length - 1) * subdivisionsPerSegment;
      const targetPosition = nextWaypointIndex * subdivisionsPerSegment;

      if (targetPosition >= totalSegments - 1.1) {
        scrollTargetRef.current = 0.2;
        scrollPositionRef.current = 0.2;
      } else if (targetPosition <= 0.1) {
        scrollTargetRef.current = totalSegments - 1.11;
        scrollPositionRef.current = totalSegments - 1.11;
      } else {
        scrollTargetRef.current = targetPosition;
      }
    } else {
      // Clamp within bounds when not looping
      nextWaypointIndex = Math.max(
        0,
        Math.min(nextWaypointIndex, waypoints.length - 1)
      );
      scrollTargetRef.current = nextWaypointIndex * subdivisionsPerSegment;
    }

    userControlRef.current = false;
  }, []);

  const handleWheelNavigation = useCallback((
    event: WheelEvent,
    animatingToPathRef: React.MutableRefObject<boolean>,
    camera: BABYLON.UniversalCamera,
    rotations: BABYLON.Quaternion[],
    waypoints: Waypoint[],
    animationFrames: number,
    scrollSpeed: number,
    isEditMode: boolean
  ) => {
    if (cameraModeRef.current === "explore") {
      return;
    }

    event.preventDefault();
    if (animatingToPathRef.current || isEditMode) return;

    const delta = Math.sign(event.deltaY);
    const scrollAmount = delta * scrollSpeed;

    if (pathRef.current.length > 1) {
      scrollTargetRef.current += scrollAmount;

      if (loopModeRef.current) {
        // Use threshold values for smooth looping
        if (scrollTargetRef.current >= pathRef.current.length - 1.1) {
          scrollTargetRef.current = 0.2;
          scrollPositionRef.current = 0.2;
        } else if (scrollTargetRef.current <= 0.1) {
          scrollTargetRef.current = pathRef.current.length - 1.11;
          scrollPositionRef.current = pathRef.current.length - 1.11;
        }
      } else {
        // Clamp within bounds
        scrollTargetRef.current = Math.max(
          0,
          Math.min(scrollTargetRef.current, pathRef.current.length - 1)
        );
      }

      userControlRef.current = false;
    }
  }, []);

  return {
    adjustScroll,
    moveToWaypoint,
    handleWheelNavigation,
  };
};
