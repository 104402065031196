import React from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { FiArrowLeft, FiArrowRight, FiCamera, FiMap } from "react-icons/fi";
import { CameraMode } from "../types/SceneTypes";

interface ScrollControlsProps {
  scrollPercentage: number;
  adjustScroll: (direction: number) => void;
  moveToWaypoint: (direction: number) => void;
  showScrollControls: boolean;
  setShowScrollControls: React.Dispatch<React.SetStateAction<boolean>>;
  cameraMode: CameraMode;
  setCameraMode: React.Dispatch<React.SetStateAction<CameraMode>>;
  uiColor: string;
  transitionSpeed: number;
  setTransitionSpeed: React.Dispatch<React.SetStateAction<number>>;
  scrollButtonMode: "percentage" | "waypoint";
  setScrollButtonMode: React.Dispatch<React.SetStateAction<"percentage" | "waypoint">>;
  scrollAmount: number;
  setScrollAmount: React.Dispatch<React.SetStateAction<number>>;
  infoPopupText: string | null;
  currentWaypointTitle: string;
}

function adjustColorBrightness(color: string, percent: number) {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
}

// Updated Container component to use transform for centering
const Container = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  transition: background-color 0.3s ease;

  @media (max-width: 480px) {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Handle = styled.div`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: move;
`;

const ControlsContainer = styled.div`
  padding: 16px;
`;

const WaypointTitle = styled.h3`
  color: #ffffff;
  margin: 0 0 8px 0;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

const InfoText = styled.p`
  color: #ffffff;
  margin: 0 0 20px 0;
  font-size: 14px;
  text-align: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

const ModeToggleContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;

const ModeToggle = styled.div<{ $uiColor: string }>`
  display: flex;
  border: 1px solid ${(props) => props.$uiColor};
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
`;

const ModeButton = styled.button<{ $uiColor: string; selected: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${(props) =>
    props.selected ? adjustColorBrightness(props.$uiColor, 20) : props.$uiColor};
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props.$uiColor};
  }

  &:hover {
    background-color: ${(props) => adjustColorBrightness(props.$uiColor, 20)};
  }
`;

const ControlRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
`;

const PercentageText = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #dddddd;
  text-align: center;
`;

const Button = styled.button<{ $uiColor: string }>`
  padding: 6px 12px;
  margin: 0 5px;
  background-color: ${props => props.$uiColor};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => adjustColorBrightness(props.$uiColor, 20)};
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #333333;
  border-radius: 8px;
  overflow: hidden;
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
`;

const ProgressBar = styled.div<{ $percentage: number; $uiColor: string }>`
  background-color: ${(props) => props.$uiColor};
  height: 100%;
  width: ${({ $percentage }) => $percentage}%;
`;

const Select = styled.select`
  padding: 5px;
  margin-right: 10px;
  background-color: #333;
  color: white;
  border: 1px solid #555;
  border-radius: 4px;
`;

const Input = styled.input`
  width: 50px;
  padding: 5px;
  margin-right: 10px;
  background-color: #333;
  color: white;
  border: 1px solid #555;
  border-radius: 4px;
`;

const Slider = styled.input`
  width: 100%;
  margin: 10px 0;
`;
const ScrollControls: React.FC<ScrollControlsProps> = ({
  scrollPercentage,
  adjustScroll,
  moveToWaypoint,
  showScrollControls,
  setShowScrollControls,
  cameraMode,
  setCameraMode,
  uiColor,
  transitionSpeed,
  setTransitionSpeed,
  scrollButtonMode,
  setScrollButtonMode,
  scrollAmount,
  setScrollAmount,
  infoPopupText,
  currentWaypointTitle,
}) => {
  const handleScrollModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setScrollButtonMode(e.target.value as "percentage" | "waypoint");
  };

  const handleScrollAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScrollAmount(Number(e.target.value));
  };

  const handleScroll = (direction: number) => {
    if (scrollButtonMode === "percentage") {
      console.log("handleScroll: scrollAmount", scrollAmount);
      adjustScroll(direction * (scrollAmount / 100));
    } else {
      moveToWaypoint(direction);
    }
  };

  const handleTransitionSpeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransitionSpeed(Number(e.target.value));
  };

  const handleCameraModeChange = (newMode: CameraMode) => {
    setCameraMode(newMode);
  };

  const nodeRef = React.useRef(null);

  // Handle drag events to prevent interference with file drops
  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };
  return (
    <>
      {showScrollControls && (
          <Container
            ref={nodeRef}
            onDragEnter={handleDragEvent}
            onDragOver={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDrop={handleDragEvent}
          >
 
            <ControlsContainer>
              {/* Only show WaypointTitle if currentWaypointTitle is not empty */}
              {currentWaypointTitle && currentWaypointTitle.trim() !== "" && (
                <WaypointTitle>{currentWaypointTitle}</WaypointTitle>
              )}
              {infoPopupText && (
                <InfoText>{infoPopupText}</InfoText>
              )}

              <ModeToggleContainer>
                <ModeToggle $uiColor={uiColor}>
                  <ModeButton
                    $uiColor={uiColor}
                    selected={cameraMode === "explore"}
                    onClick={() => handleCameraModeChange("explore")}
                    aria-label="Switch to Explore Mode"
                  >
                    <FiMap style={{ marginRight: "8px" }} />
                    Explore
                  </ModeButton>
                  <ModeButton
                    $uiColor={uiColor}
                    selected={cameraMode === "tour"}
                    onClick={() => handleCameraModeChange("tour")}
                    aria-label="Switch to Tour Mode"
                  >
                    <FiCamera style={{ marginRight: "8px" }} />
                    Tour
                  </ModeButton>
                  <ModeButton
                    $uiColor={uiColor}
                    selected={cameraMode === "hybrid"}
                    onClick={() => handleCameraModeChange("hybrid")}
                    aria-label="Switch to Hybrid Mode"
                  >
                    <FiMap style={{ marginRight: "8px" }} />
                    Hybrid
                  </ModeButton>
                </ModeToggle>
              </ModeToggleContainer>

              {(cameraMode === "hybrid" || cameraMode === "tour") && (
                <>
                  <PercentageText>
                    Scroll Position: {Math.round(scrollPercentage)}%
                  </PercentageText>
                  <ProgressBarContainer>
                    <ProgressBar
                      $percentage={scrollPercentage}
                      $uiColor={uiColor}
                    />
                  </ProgressBarContainer>

                  <ControlRow>
                    <Button
                      onClick={() => handleScroll(-1)}
                      aria-label={
                        scrollButtonMode === "percentage"
                        ? `Scroll Backward ${scrollAmount}%`
                        : "Move to Previous Waypoint"
                      }
                      $uiColor={uiColor}
                    >
                      <FiArrowLeft style={{ marginRight: "4px" }} />
                      {scrollButtonMode === "percentage"
                        ? `${scrollAmount}%`
                        : "Prev"}
                    </Button>
                    <Select
                      value={scrollButtonMode}
                      onChange={handleScrollModeChange}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="waypoint">Waypoint</option>
                    </Select>
                    {scrollButtonMode === "percentage" && (
                      <Input
                        type="number"
                        value={scrollAmount}
                        onChange={handleScrollAmountChange}
                        min="1"
                        max="100"
                        aria-label="Set Scroll Amount Percentage"
                      />
                    )}
                    <Button
                      onClick={() => handleScroll(1)}
                      aria-label={
                        scrollButtonMode === "percentage"
                          ? `Scroll Forward ${scrollAmount}%`
                          : "Move to Next Waypoint"
                      }
                      $uiColor={uiColor}
                    >
                      {scrollButtonMode === "percentage"
                        ? `${scrollAmount}%`
                        : "Next"}
                      <FiArrowRight style={{ marginLeft: "4px" }} />
                    </Button>
                  </ControlRow>
                  <ControlRow>
                    <label htmlFor="transitionSpeed">Transition Speed:</label>
                    <Slider
                      id="transitionSpeed"
                      type="range"
                      min="0.01"
                      max="10"
                      step="0.01"
                      value={transitionSpeed}
                      onChange={handleTransitionSpeedChange}
                      aria-label="Adjust Transition Speed"
                    />
                  </ControlRow>
                  <PercentageText>
                    Transition Speed: {transitionSpeed.toFixed(2)}
                  </PercentageText>
                </>
              )}
            </ControlsContainer>
          </Container>
      )}
    </>
  );
};

export default ScrollControls;
