/*
File: mainScript.ts
Description: Main entry point for Babylon.js scene generation that combines all modular components
Last modified: 2024-02-19
Changes: 
- Refactored into modular components for better organization and maintainability
- Added initialization of shared variables before module code
- Split functionality into separate files for scene setup, hotspots, navigation, collisions, XR, mobile controls, etc.
*/

import { GenerateHTMLProps } from './types';
import { generateSceneSetup } from './modules/sceneSetup';
import { generateHotspotSystem } from './modules/hotspotSystem';
import { generateNavigationSystem } from './modules/navigationSystem';
import { generateCollisionSystem } from './modules/collisionSystem';
import { generateXRSystem } from './modules/xrSystem';
import { generateMobileControls } from './modules/mobileControls';
import { generateFullscreenControls } from './modules/fullscreenControls';
import { generateRenderLoop } from './modules/renderLoop';

export const generateMainScript = (props: GenerateHTMLProps): string => {
  const debugMode = props.uiOptions?.debugMode === true;

  return `
    // Initialize shared variables
    const waypoints = ${JSON.stringify(props.waypoints)};
    let userControl = cameraMode === 'explore';
    let scrollPosition = 0;
    let scrollTarget = 0.01;
    let targetRotation = null;
    let targetPosition = null;
    const activeWaypoints = new Set();
    let transitionSpeed = ${props.transitionSpeed};

    ${generateSceneSetup(props)}

    // After scene setup, initialize target positions
    targetRotation = camera.rotationQuaternion.clone();
    targetPosition = camera.position.clone();

    ${debugMode ? `
    // Debug mode setup
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'i') {
        if (scene.debugLayer.isVisible()) {
          scene.debugLayer.hide();
        } else {
          scene.debugLayer.show();
        }
      }
    });
    ` : ''}

    ${generateHotspotSystem(props)}
    ${generateNavigationSystem(props)}
    ${generateCollisionSystem(props)}
    ${generateXRSystem(props)}
    ${generateMobileControls(props)}
    ${generateFullscreenControls()}
    ${generateRenderLoop(props)}
  `;
};
