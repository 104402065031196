import { useMemo } from 'react';

export const useMobileDetection = () => {
  const isMobile = useMemo(() => {
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent.toLowerCase()
    );
  }, []);

  return isMobile;
};
