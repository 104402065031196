/*
File: useLights.ts
Description: Hook for managing scene lights, handling creation, updates, and removal of different light types
Created: 2024-01-15
*/

import { useEffect, useRef } from 'react';
import * as BABYLON from '@babylonjs/core';
import { SceneLight } from '../types/SceneTypes';

export const useLights = (scene: BABYLON.Scene | null, lights: SceneLight[]) => {
  const lightInstancesRef = useRef<Map<string, BABYLON.Light>>(new Map());

  // Helper to create a new light
  const createLight = (lightData: SceneLight): BABYLON.Light | undefined => {
    if (!scene) return undefined;

    let light: BABYLON.Light | undefined;

    switch (lightData.type) {
      case 'hemispheric':
        light = new BABYLON.HemisphericLight(
          lightData.id,
          new BABYLON.Vector3(
            lightData.position.x,
            lightData.position.y,
            lightData.position.z
          ),
          scene
        );
        if (lightData.groundColor) {
          (light as BABYLON.HemisphericLight).groundColor = BABYLON.Color3.FromHexString(lightData.groundColor);
        }
        break;

      case 'point':
        light = new BABYLON.PointLight(
          lightData.id,
          new BABYLON.Vector3(
            lightData.position.x,
            lightData.position.y,
            lightData.position.z
          ),
          scene
        );
        if (lightData.range) {
          light.range = lightData.range;
        }
        break;

      case 'directional':
        light = new BABYLON.DirectionalLight(
          lightData.id,
          new BABYLON.Vector3(
            lightData.direction?.x ?? 0,
            lightData.direction?.y ?? -1,
            lightData.direction?.z ?? 0
          ),
          scene
        );
        break;

      case 'spot':
        if (lightData.direction) {
          light = new BABYLON.SpotLight(
            lightData.id,
            new BABYLON.Vector3(
              lightData.position.x,
              lightData.position.y,
              lightData.position.z
            ),
            new BABYLON.Vector3(
              lightData.direction.x,
              lightData.direction.y,
              lightData.direction.z
            ),
            lightData.angle ?? Math.PI / 3,
            lightData.exponent ?? 2,
            scene
          );
          if (lightData.range) {
            light.range = lightData.range;
          }
        }
        break;
    }

    if (light) {
      light.intensity = lightData.intensity;
      light.diffuse = BABYLON.Color3.FromHexString(lightData.color);
      light.specular = BABYLON.Color3.FromHexString(lightData.color);
      light.setEnabled(lightData.enabled);
    }

    return light;
  };

  // Update existing light
  const updateLight = (lightInstance: BABYLON.Light, lightData: SceneLight) => {
    lightInstance.intensity = lightData.intensity;
    lightInstance.diffuse = BABYLON.Color3.FromHexString(lightData.color);
    lightInstance.specular = BABYLON.Color3.FromHexString(lightData.color);
    lightInstance.setEnabled(lightData.enabled);

    switch (lightData.type) {
      case 'hemispheric':
        if (lightInstance instanceof BABYLON.HemisphericLight && lightData.groundColor) {
          lightInstance.groundColor = BABYLON.Color3.FromHexString(lightData.groundColor);
        }
        break;

      case 'point':
        if (lightInstance instanceof BABYLON.PointLight) {
          lightInstance.position = new BABYLON.Vector3(
            lightData.position.x,
            lightData.position.y,
            lightData.position.z
          );
          if (lightData.range) {
            lightInstance.range = lightData.range;
          }
        }
        break;

      case 'directional':
        if (lightInstance instanceof BABYLON.DirectionalLight && lightData.direction) {
          lightInstance.direction = new BABYLON.Vector3(
            lightData.direction.x,
            lightData.direction.y,
            lightData.direction.z
          );
        }
        break;

      case 'spot':
        if (lightInstance instanceof BABYLON.SpotLight) {
          lightInstance.position = new BABYLON.Vector3(
            lightData.position.x,
            lightData.position.y,
            lightData.position.z
          );
          if (lightData.direction) {
            lightInstance.direction = new BABYLON.Vector3(
              lightData.direction.x,
              lightData.direction.y,
              lightData.direction.z
            );
          }
          if (lightData.angle !== undefined) {
            lightInstance.angle = lightData.angle;
          }
          if (lightData.exponent !== undefined) {
            lightInstance.exponent = lightData.exponent;
          }
          if (lightData.range !== undefined) {
            lightInstance.range = lightData.range;
          }
        }
        break;
    }
  };

  // Effect to manage lights
  useEffect(() => {
    if (!scene) return;

    // Track which lights we've processed
    const processedLights = new Set<string>();

    // Create or update lights
    lights.forEach(lightData => {
      let lightInstance = lightInstancesRef.current.get(lightData.id);

      if (lightInstance) {
        // Update existing light
        updateLight(lightInstance, lightData);
      } else {
        // Create new light
        lightInstance = createLight(lightData);
        if (lightInstance) {
          lightInstancesRef.current.set(lightData.id, lightInstance);
        }
      }

      processedLights.add(lightData.id);
    });

    // Remove lights that no longer exist in the data
    for (const [id, lightInstance] of lightInstancesRef.current.entries()) {
      if (!processedLights.has(id)) {
        lightInstance.dispose();
        lightInstancesRef.current.delete(id);
      }
    }

    // Cleanup function
    return () => {
      for (const lightInstance of lightInstancesRef.current.values()) {
        lightInstance.dispose();
      }
      lightInstancesRef.current.clear();
    };
  }, [scene, lights]);

  return lightInstancesRef;
};
