/**
 * ProUpgrade.tsx
 * This component handles the pricing and subscription upgrade page for the application.
 * It displays different pricing tiers and handles the Stripe checkout process.
 * 
 * Changes:
 * - Added redirect to account settings if user already has a Stripe account (2024-01-09 14:30)
 * - Added yearly billing cycle toggle and pricing (2024-03-19 15:00)
 * - Updated to handle yearly price IDs in checkout
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { useSubscription } from '../hooks/useSubscription';
import { STRIPE_CONFIG } from '../utils/StripeConfig';
import { FiCheck, FiArrowLeft, FiBriefcase } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useAuth } from '../contexts/AuthContext';

type PlanId = keyof typeof STRIPE_CONFIG.PLANS;

const BillingToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 0 auto 40px;
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 8px 16px;
  border: 2px solid #d4af37;
  background: ${props => props.active ? '#d4af37' : 'transparent'};
  color: ${props => props.active ? '#fff' : '#d4af37'};
  border-radius: 20px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.active ? '#b4941f' : 'rgba(212, 175, 55, 0.1)'};
  }
`;

const SaveBadge = styled.span`
  position: absolute;
  top: -12px;
  right: -12px;
  background: #d4af37;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(212, 175, 55, 0.3);
  z-index: 1;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(30, 30, 30, 0.97) 0%, rgba(20, 20, 20, 0.98) 100%);
  z-index: 100000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: clamp(20px, 5vw, 40px);
  color: #f0f0f0;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  max-width: 1400px;
`;

const EarlyAccessBanner = styled.div`
  background: linear-gradient(135deg, rgba(212, 175, 55, 0.15) 0%, rgba(180, 148, 31, 0.15) 100%);
  color: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 800px;
  box-shadow: 0 8px 24px rgba(212, 175, 55, 0.15);
  border: 1px solid #d4af37;
  animation: glow 2s infinite;
  
  @keyframes glow {
    0% { box-shadow: 0 8px 24px rgba(212, 175, 55, 0.15); }
    50% { box-shadow: 0 8px 32px rgba(212, 175, 55, 0.25); }
    100% { box-shadow: 0 8px 24px rgba(212, 175, 55, 0.15); }
  }

  h3 {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600;
    color: #d4af37;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: clamp(14px, 2vw, 16px);
  cursor: pointer;
  padding: 10px;
  margin-bottom: clamp(16px, 4vw, 20px);
  transition: all 0.3s ease;
  position: sticky;
  top: 10px;
  z-index: 1;

  &:hover {
    transform: translateX(-5px);
    color: #fff;
  }
`;

const Title = styled.h1`
  font-size: clamp(24px, 5vw, 2.5em);
  text-align: center;
  margin-bottom: clamp(24px, 6vw, 40px);
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const PricingGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 8px;

  & > * {
    flex: 1 1 280px;
    min-width: 280px;
    max-width: 400px;
  }
`;

const PricingCard = styled.div<{ featured?: boolean; isCommercial?: boolean }>`
  background: ${props => {
    if (props.featured) return 'linear-gradient(145deg, rgba(212, 175, 55, 0.15) 0%, rgba(180, 148, 31, 0.15) 100%)';
    if (props.isCommercial) return 'linear-gradient(145deg, #34485E 0%, #2C3E50 100%)';
    return 'linear-gradient(145deg, #323232 0%, #282828 100%)';
  }};
  border-radius: 20px;
  padding: clamp(18px, 4vw, 30px);
  text-align: center;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid ${props => {
    if (props.featured) return '#d4af37';
    if (props.isCommercial) return '#3498DB';
    return 'rgba(160, 160, 160, 0.1)';
  }};
  animation: slideUp 1s ease-out;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
  }

  @keyframes slideUp {
    from { opacity: 0; transform: translateY(30px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const PlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-bottom: 15px;
`;

const PlanIcon = styled.div`
  font-size: 24px;
  color: ${props => props.color || '#d4af37'};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-bottom: 8px;
`;

const PlanType = styled.div<{ type: 'popular' | 'business' | 'enterprise' | 'basic' }>`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 4px 12px;
  border-radius: 12px;
  background: ${props => {
    switch (props.type) {
      case 'popular': return 'rgba(212, 175, 55, 0.2)';
      case 'business': return 'rgba(52, 152, 219, 0.2)';
      case 'enterprise': return 'rgba(52, 73, 94, 0.2)';
      default: return 'rgba(255, 255, 255, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.type) {
      case 'popular': return '#d4af37';
      case 'business': return '#3498DB';
      case 'enterprise': return '#95A5A6';
      default: return '#fff';
    }
  }};
  margin-bottom: 6px;
`;

const PlanName = styled.h2`
  color: #fff;
  font-size: clamp(20px, 4vw, 24px);
  margin-bottom: 12px;
  font-weight: 600;
`;

const Price = styled.div`
  font-size: clamp(32px, 6vw, 48px);
  font-weight: bold;
  color: #fff;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  span {
    font-size: clamp(16px, 3vw, 20px);
    opacity: 0.8;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
  flex-grow: 1;
`;

const Feature = styled.li<{ highlighted?: boolean }>`
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${props => props.highlighted ? '#fff' : 'rgba(255, 255, 255, 0.9)'};
  font-size: clamp(14px, 2vw, 16px);
  padding: 6px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: ${props => props.highlighted ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  font-weight: ${props => props.highlighted ? '500' : 'normal'};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
  }

  svg {
    color: ${props => props.highlighted ? '#d4af37' : '#a0a0a0'};
    font-size: 18px;
    flex-shrink: 0;
  }
`;

const UpgradeButton = styled.button<{ featured?: boolean; isCommercial?: boolean }>`
  background: ${props => {
    if (props.featured) return '#d4af37';
    if (props.isCommercial) return '#3498DB';
    return '#666';
  }};
  color: #fff;
  padding: clamp(14px, 3vw, 16px) clamp(24px, 5vw, 32px);
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: clamp(14px, 2vw, 16px);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: auto;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px ${props => {
      if (props.featured) return 'rgba(212, 175, 55, 0.3)';
      if (props.isCommercial) return 'rgba(52, 152, 219, 0.3)';
      return 'rgba(255, 255, 255, 0.1)';
    }};
    background: ${props => {
      if (props.featured) return '#b4941f';
      if (props.isCommercial) return '#2980B9';
      return '#777';
    }};
  }

  &:active {
    transform: translateY(-1px);
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ContactSection = styled.div`
  text-align: center;
  margin: 40px auto;
  padding: clamp(24px, 5vw, 40px);
  background: linear-gradient(145deg, #323232 0%, #282828 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(160, 160, 160, 0.1);
  max-width: 600px;
  animation: slideUp 1s ease-out;
`;

const ContactText = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: clamp(16px, 3vw, 20px);
  margin-bottom: 15px;
`;

const ContactLink = styled.a`
  color: #d4af37;
  text-decoration: none;
  font-weight: 500;
  font-size: clamp(14px, 2.5vw, 18px);
  transition: all 0.3s ease;
  
  &:hover {
    color: #b4941f;
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #fff;
  font-size: 1.2em;
`;


const ProUpgrade: React.FC = () => {
  const { startCheckout, isProcessing } = useSubscription();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');

  // Redirect to account settings if user already has a Stripe account
  React.useEffect(() => {
    if (userProfile?.stripeCustomerId) {
      navigate('/manage-subscription');
    }
  }, [userProfile, navigate]);

  React.useEffect(() => {
    console.log('=== Available Plans ===');
    console.log(Object.entries(STRIPE_CONFIG.PLANS).map(([id, plan]) => ({
      id,
      name: plan.name,
      priceId: plan.priceId,
      yearlyPriceId: plan.yearlyPriceId
    })));
  }, []);

  const isStripeEnabled = config.stripeIntegrationEnabled;
  const hasExperimentalAccess = config.stripeExperimentalFeatures.enabled && 
    userProfile?.betaFeatures?.includes('stripe-beta');
  
  const shouldOverrideStripeEnabled = hasExperimentalAccess && config.stripeExperimentalFeatures.enabled;

  if (!isStripeEnabled && !shouldOverrideStripeEnabled) {
    return (
      <Overlay>
        <Container>
          <BackButton onClick={() => navigate(-1)}>
            <FiArrowLeft /> Back
          </BackButton>
          <ErrorMessage>
            This feature is currently not available.
          </ErrorMessage>
        </Container>
      </Overlay>
    );
  }

  const handleUpgrade = async (planId: PlanId) => {
    try {
      const plan = STRIPE_CONFIG.PLANS[planId];
      const priceId = billingCycle === 'yearly' ? plan.yearlyPriceId : plan.priceId;
      
      console.log('=== Upgrade Details ===');
      console.log('Selected Plan ID:', planId);
      console.log('Billing Cycle:', billingCycle);
      console.log('Selected Plan Details:', plan);
      console.log('Price ID being used:', priceId);
      console.log('All available plans:', STRIPE_CONFIG.PLANS);
      
      alert(`Initiating upgrade to ${plan.name} plan (${billingCycle})...`);

      if (!priceId) {
        throw new Error(`Price ID not found for plan: ${planId} (${billingCycle})`);
      }
      
      await startCheckout(priceId);
    } catch (error) {
      console.error('Upgrade error:', error);
      alert('Error initiating upgrade. Please try again.');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const calculateYearlySavings = (monthlyPrice: number, yearlyPrice: number) => {
    const yearlyTotal = monthlyPrice * 12;
    const savings = ((yearlyTotal - yearlyPrice) / yearlyTotal * 100).toFixed(0);
    return `Save ${savings}%`;
  };

  const getPrice = (plan: typeof STRIPE_CONFIG.PLANS[PlanId]) => {
    return billingCycle === 'yearly' ? plan.yearlyPrice : plan.price;
  };

  const availablePlans = Object.entries(STRIPE_CONFIG.PLANS).filter(([planId]) => planId !== 'ENTERPRISE') as [PlanId, typeof STRIPE_CONFIG.PLANS[PlanId]][];

  return (
    <Overlay>
      <Container>
        <BackButton onClick={handleBack}>
          <FiArrowLeft /> Back
        </BackButton>
        <Title>Pricing</Title>
        
        <EarlyAccessBanner>
          <h3>🚀 Early Access Pricing!</h3>
        </EarlyAccessBanner>

        <BillingToggle>
          <ToggleButton 
            active={billingCycle === 'monthly'} 
            onClick={() => setBillingCycle('monthly')}
          >
            Monthly
          </ToggleButton>
          <ToggleButton 
            active={billingCycle === 'yearly'} 
            onClick={() => setBillingCycle('yearly')}
          >
            Yearly
          </ToggleButton>
        </BillingToggle>

        <PricingGrid>
          {availablePlans.map(([planId, plan]) => {
            const isCommercial = planId === 'BUSINESS';
            return (
              <PricingCard 
                key={planId}
                featured={planId === 'PRO'}
                isCommercial={isCommercial}
              >
                {billingCycle === 'yearly' && (
                  <SaveBadge>
                    {calculateYearlySavings(plan.price, plan.yearlyPrice)}
                  </SaveBadge>
                )}
                <PlanHeader>
                  <PlanIcon color={isCommercial ? '#3498DB' : '#d4af37'}>
                    {isCommercial ? <FiBriefcase /> : <FiCheck />}
                  </PlanIcon>
                  <PlanType 
                    type={
                      planId === 'PRO' ? 'popular' : 
                      planId === 'BUSINESS' ? 'business' : 'basic'
                    }
                  >
                    {planId === 'PRO' ? 'Most Popular' :
                     planId === 'BUSINESS' ? 'Business License' : 'Basic'}
                  </PlanType>
                  <PlanName>{plan.name}</PlanName>
                </PlanHeader>
                <Price>
                  ${getPrice(plan)}<span>/{billingCycle === 'yearly' ? 'year' : 'month'}</span>
                </Price>
                <FeatureList>
                  {plan.features.map((feature, index) => {
                    const isCommercialFeature = feature.toLowerCase().includes('commercial') || 
                                              feature.toLowerCase().includes('business');
                    return (
                      <Feature 
                        key={index}
                        highlighted={isCommercialFeature}
                      >
                        <FiCheck /> {feature}
                      </Feature>
                    );
                  })}
                </FeatureList>
                <UpgradeButton 
                  onClick={() => handleUpgrade(planId)}
                  disabled={isProcessing}
                  featured={planId === 'PRO'}
                  isCommercial={isCommercial}
                >
                  {isProcessing ? 'Processing...' : `Choose ${plan.name}`}
                </UpgradeButton>
              </PricingCard>
            );
          })}
        </PricingGrid>

        <ContactSection>
          <ContactText>Need a custom enterprise solution?</ContactText>
          <ContactLink href="mailto:the@sonnycirasuolo.com">
            Contact us at the@sonnycirasuolo.com
          </ContactLink>
        </ContactSection>
      </Container>
    </Overlay>
  );
};

export default ProUpgrade;
