/*
File: SceneTypes.ts
Description: Contains type definitions for scene-related data structures
Last modified: 2024-02-20
Changes: 
- Added ARHotspotOptions interface for AR-specific hotspot settings
- Added defaultARPosition to AROptions
- Added arHotspotOptions to Hotspot interface
- Added waypoint conversion settings to AROptions
*/

import * as BABYLON from "@babylonjs/core";
import { UIType } from "../tools/html-generation/types";

export type SceneType = "mesh" | "splat" | "ply";

export type CameraMode = "tour" | "explore" | "hybrid" | "edit";

export type XRMode = "none" | "ar" | "vr";

export interface ARHotspotOptions {
  textSize?: number;
  textColor?: string;
  backgroundColor?: string;
  displayDistance?: number;
  billboardMode?: boolean;
}

export interface AROptions {
  enableHitTest?: boolean;
  enablePlaneDetection?: boolean;
  enableAnchors?: boolean;
  enableBackgroundRemoval?: boolean;
  defaultARPosition?: {
    position: BABYLON.Vector3;
    rotation: BABYLON.Quaternion;
  };
  convertWaypointsToHotspots?: boolean;
  defaultARHotspotOptions?: ARHotspotOptions;
}

export interface PlaySoundOptions {
  scene?: BABYLON.Scene;
  position?: BABYLON.Vector3;
  useCorsProxy?: boolean;
  corsProxyUrl?: string;
}

export interface SceneLight {
  id: string;
  type: "hemispheric" | "point" | "directional" | "spot";
  name: string;
  intensity: number;
  color: string;
  position: {
    x: number;
    y: number;
    z: number;
  };
  direction?: {
    x: number;
    y: number;
    z: number;
  };
  angle?: number;  // for spot lights
  exponent?: number;  // for spot lights
  range?: number;  // for point and spot lights
  groundColor?: string;  // for hemispheric lights
  enabled: boolean;
}

export interface Interaction {
  id: string;
  type: "audio" | "info" | "animation" | "custom";
  data: AudioInteractionData | InfoInteractionData | AnimationInteractionData | CustomInteractionData;
}

export interface Waypoint {
  x: number;
  y: number;
  z: number;
  rotation: BABYLON.Quaternion;
  interactions: Interaction[];
  triggerDistance?: number;
  name?: string;
}

export interface Hotspot {
  id: string;
  position: BABYLON.Vector3;
  scale: BABYLON.Vector3;
  rotation: BABYLON.Vector3;
  title: string;
  information?: string;
  photoUrl?: string;
  activationMode: 'click' | 'hover';
  color: string;
  type: 'sphere' | 'image';
  imageUrl?: string;
  imageName?: string;
  arHotspotOptions?: ARHotspotOptions;
}

export interface UIOptions {
  infoPosition: 'popup' | 'controls';
  buttonPosition: "inline" | "below";
  showStartExperience?: boolean;
  debugMode?: boolean;
  uiType: UIType;
  hideWatermark?: boolean;
}

export interface AudioInteractionData {
  id: string;
  url: string;
  spatialSound: boolean;
  volume?: number;
  loop?: boolean;
  autoplay?: boolean;
  distanceModel?: "linear" | "exponential";
  maxDistance?: number;
  refDistance?: number;
  rolloffFactor?: number;
  stopOnExit?: boolean;
}

export interface InfoInteractionData {
  text: string;
}

export interface AnimationInteractionData {
  animationName: string;
}

export interface CustomInteractionData {
  script: string;
}

export interface SaveFile {
  scrollSpeed: number;
  animationFrames: number;
  cameraMovementSpeed: number;
  cameraRotationSensitivity: number;
  backgroundColor: string;
  waypoints: Waypoint[];
  loadedModelUrl: string | null;
  hotspots: Hotspot[];
  uiColor: string;
  transitionSpeed: number;
  scrollButtonMode: "percentage" | "waypoint";
  scrollAmount: number;
  collisionMeshesData: SerializedMeshData[];
  allowedCameraModes: string[];
  loopMode: boolean;
  autoPlayEnabled: boolean;
  autoPlaySpeed: number;
  uiOptions?: UIOptions;
  sceneTitle: string;
  autoFrame?: boolean;
  lights: SceneLight[];
  sceneType?: SceneType;
  xrMode?: XRMode;
  arOptions?: AROptions;
}

export interface SceneConfig {
  sceneTitle: string;
  modelUrl: string;
  includeScrollControls: boolean;
  waypoints: Array<{
    x: number;
    y: number;
    z: number;
    rotation: {
      x: number;
      y: number;
      z: number;
      w: number;
    };
    interactions: Array<{
      id: string;
      type: string;
      data: any;
    }>;
    triggerDistance?: number;
  }>;
  backgroundColor: string;
  cameraMovementSpeed: number;
  cameraRotationSensitivity: number;
  scrollSpeed: number;
  animationFrames: number;
  hotspots: Array<{
    id: string;
    position: {
      _x: number;
      _y: number;
      _z: number;
    };
    scale: {
      _x: number;
      _y: number;
      _z: number;
    };
    rotation: {
      _x: number;
      _y: number;
      _z: number;
    };
    title: string;
    information?: string;
    photoUrl?: string;
    activationMode: "click" | "hover";
    color: string;
    type: "sphere" | "image";
    imageUrl?: string;
    imageName?: string;
    arHotspotOptions?: ARHotspotOptions;
  }>;
  defaultCameraMode: CameraMode | undefined;
  cameraDampeningRef: number;
  uiColor: string;
  transitionSpeed: number;
  scrollButtonMode: "percentage" | "waypoint";
  scrollAmount: number;
  allowedCameraModes: string[];
  loopMode: boolean;
  autoPlaySpeed: number;
  autoPlayEnabled: boolean;
  uiOptions?: UIOptions;
  collisionMeshesData: SerializedMeshData[];
  includeXR: boolean;
  xrMode?: XRMode;
  arOptions?: AROptions;
  description?: string;
  lights?: SceneLight[];
  sceneType?: SceneType;
}

export interface SerializedMeshData {
  meshData: any;
  position: [number, number, number];
  rotation: [number, number, number];
  scaling: [number, number, number];
  meshType: "plane" | "cube" | "sphere" | "custom";
  customMeshUrl?: string;
}

export const DEFAULT_SETTINGS = {
  scrollSpeed: 0.1,
  animationFrames: 120,
  cameraMovementSpeed: 0.2,
  cameraRotationSensitivity: 4000,
  backgroundColor: "#7D7D7D",
  autoFrame: false,
  lights: [{
    id: "default",
    type: "hemispheric" as const,
    name: "Default Light",
    intensity: 1,
    color: "#ffffff",
    position: { x: 0, y: 1, z: 0 },
    groundColor: "#000000",
    enabled: true
  }],
  arOptions: {
    enableHitTest: false,
    enablePlaneDetection: true,
    convertWaypointsToHotspots: true,
    defaultARHotspotOptions: {
      textSize: 24,
      textColor: "#ffffff",
      backgroundColor: "#000000",
      displayDistance: 2,
      billboardMode: true
    }
  }
}
