/*
* Configuration file for feature flags and global settings
* Last modified: 2024-01-09 15:30
* Changes: 
* - Added publicProfilesEnabled flag for user portfolio pages feature
* - Added stripeExperimentalFeatures for beta testing new Stripe features
*/

import { analytics } from "./utils/FirebaseConfig"

const config = {
    analyticsEnabled: false,
    stripeIntegrationEnabled: false,
    stripeExperimentalFeatures: {
        enabled: false,
        features: ['stripe-beta'] // Feature identifier that matches betaFeatures in UserProfile
    },
    publicProfilesEnabled: true, // Feature flag for public user portfolio pages
}

export default config
