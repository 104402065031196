/*
File: mobileControls.ts
Description: Handles mobile-specific controls and joystick functionality
Created: 2024-02-19
*/

import { GenerateHTMLProps } from '../types';

export const generateMobileControls = (props: GenerateHTMLProps): string => {
  const { cameraMovementSpeed, defaultCameraMode, isMobileDevice } = props;

  return `
    if (${isMobileDevice}) {
      var leftJoystick = new BABYLON.VirtualJoystick(true);
      var rightJoystick = new BABYLON.VirtualJoystick(false);
      
      if('${defaultCameraMode}' === 'tour'){
        if(BABYLON.VirtualJoystick.Canvas){
          BABYLON.VirtualJoystick.Canvas.style.zIndex = "-10";
        }
      } else {
        if(BABYLON.VirtualJoystick.Canvas){
          BABYLON.VirtualJoystick.Canvas.style.zIndex = "10";
        }
      }
  
      scene.onBeforeRenderObservable.add(()=>{
        if(leftJoystick.pressed){
          userControl = true;
          const deltaTime = engine.getDeltaTime() / 1000;
          const moveSpeed = deltaTime * 5 * ${cameraMovementSpeed};
      
          const moveX = leftJoystick.deltaPosition.x * moveSpeed;
          const moveZ = leftJoystick.deltaPosition.y * moveSpeed;
      
          const forward = camera.getDirection(BABYLON.Axis.Z);
          const right = camera.getDirection(BABYLON.Axis.X);
      
          const move = forward.scale(moveZ).add(right.scale(moveX));
          camera.position.addInPlace(move);
        }

        if (rightJoystick.pressed) {
          const deltaX = rightJoystick.deltaPosition.x;
          const deltaY = rightJoystick.deltaPosition.y;
      
          const angularSensibility = 200 * ${cameraMovementSpeed};
      
          const currentEuler = camera.rotationQuaternion.toEulerAngles();
          let yaw = currentEuler.y;
          let pitch = currentEuler.x;
      
          yaw += deltaX / angularSensibility;
          pitch -= deltaY / angularSensibility;
      
          const maxPitch = Math.PI / 2;
          pitch = Math.max(-maxPitch, Math.min(maxPitch, pitch));
      
          camera.rotationQuaternion = BABYLON.Quaternion.RotationYawPitchRoll(yaw, pitch, 0);
        }
      });
    }
  `;
};
