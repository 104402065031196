/*
File: analytics.ts
Description: Generates analytics tracking code for the exported HTML scene
Last modified: 2024-01-09
Changes: Initial creation - Extracted from GenerateExportedHtml.ts
*/

interface AnalyticsProps {
  sceneId: string;
  userId: string;
  sceneName: string;
  userName: string;
}

export const generateAnalyticsCode = ({
  sceneId,
  userId,
  sceneName,
  userName
}: AnalyticsProps): string => {
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    
    // Configure custom dimensions
    gtag('config', 'G-L2NG77PKGG', {
      custom_map: {
        'dimension1': 'scene_id',
        'dimension2': 'scene_name',
        'dimension3': 'creator_name',
        'dimension4': 'user_id'
      }
    });

    // Set default values for custom dimensions
    gtag('event', 'set', {
      'scene_id': '${sceneId}',
      'scene_name': '${sceneName}',
      'creator_name': '${userName}',
      'user_id': '${userId}'
    });

    // Add scene metadata object
    const sceneMetadata = {
      scene_id: '${sceneId}',
      scene_name: '${sceneName}',
      creator_name: '${userName}',
      user_id: '${userId}',
      timestamp: new Date().toISOString()
    };

    // Enhanced tracking functions with metadata
    function trackPageview() {
      gtag('event', 'page_view', {
        ...sceneMetadata,
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
        referrer: document.referrer,
        viewport_size: \`\${window.innerWidth}x\${window.innerHeight}\`,
        user_agent: navigator.userAgent
      });
    }

    function trackCameraMode(mode) {
      gtag('event', 'camera_mode_change', {
        ...sceneMetadata,
        mode: mode
      });
    }

    function trackWaypointReached(waypointName, waypointIndex) {
      gtag('event', 'waypoint_reached', {
        ...sceneMetadata,
        waypoint_name: waypointName,
        waypoint_index: waypointIndex
      });
    }

    function trackHotspotInteraction(hotspotTitle, interactionType) {
      gtag('event', 'hotspot_interaction', {
        ...sceneMetadata,
        hotspot_title: hotspotTitle,
        interaction_type: interactionType
      });
    }

    function trackAudioInteraction(audioId, action) {
      gtag('event', 'audio_interaction', {
        ...sceneMetadata,
        audio_id: audioId,
        action: action
      });
    }

    function trackUserEngagement(type, duration) {
      gtag('event', 'user_engagement', {
        ...sceneMetadata,
        engagement_type: type,
        duration_seconds: duration
      });
    }

    // Track session duration
    let sessionStartTime = Date.now();
    window.addEventListener('beforeunload', () => {
      const sessionDuration = (Date.now() - sessionStartTime) / 1000; // Convert to seconds
      trackUserEngagement('session_duration', sessionDuration);
    });

    // Track initial pageview
    window.addEventListener('load', trackPageview);

    // Track visibility changes
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        const visibleDuration = (Date.now() - sessionStartTime) / 1000;
        trackUserEngagement('visible_duration', visibleDuration);
      }
    });

    // Track user interactions
    let lastInteractionTime = Date.now();
    document.addEventListener('click', updateLastInteraction);
    document.addEventListener('mousemove', updateLastInteraction);
    document.addEventListener('keypress', updateLastInteraction);
    document.addEventListener('scroll', updateLastInteraction);
    document.addEventListener('touchstart', updateLastInteraction);

    function updateLastInteraction() {
      const now = Date.now();
      const timeSinceLastInteraction = (now - lastInteractionTime) / 1000;
      if (timeSinceLastInteraction > 30) { // Track engagement periods longer than 30 seconds
        trackUserEngagement('active_period', timeSinceLastInteraction);
      }
      lastInteractionTime = now;
    }`;
};
