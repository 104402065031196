/*
File: SceneControls.tsx
Description: Main controls component for the scene
Last modified: 2024-02-14
Changes: 
- Added onCapturePreview prop for thumbnail capture
- Added lighting system support
- Added LightVisualizer component
- Updated LicenseButton to use forceVisible prop
- Added hotspotManagerRef prop to connect HotspotManager ref through component hierarchy
*/

import React, { useState, useEffect } from "react";
import { VERSION, VERSION_CHANGES } from "../../config/version";
import { User } from "firebase/auth";
import VersionDisplay from "../VersionDisplay";
import VersionPopup from "../VersionPopup";
import { SceneState } from "../../hooks/useSceneState";
import { useSceneNavigation } from "../../hooks/useSceneNavigation";
import WaypointControls from "../WaypointControls";
import ParameterControls from "../ParameterControls";
import ScrollControls from "../ScrollControls";
import Controls from "../Controls";
import LoadSaveExportMenu from "../LoadSaveExportMenu";
import WaypointVisualizer from "../WaypointVisualizer";
import HotspotManager from "../HotspotManager";
import CollisionControls from "../CollisionControls";
import CollisionVisualizer from "../CollisionVisualizer";
import InfoPopup from "../InfoPopup";
import ExportPopup from "../ExportPopup";
import GitHubLink from "../GithubCTA";
import DiscordCTA from "../DiscordCTA";
import ExampleExports from "../ExampleExports";
import LicenseButton from "../LicenseButton";
import UserDashboard from "../UserDashboard";
import config from "../../config";
import TopToolbar from "../TopToolbar";
import BabylonScene from "./BabylonScene";
import LightVisualizer from "./LightVisualizer";
import { CameraMode, SerializedMeshData, XRMode, AROptions, UIOptions, SceneType } from "../../types/SceneTypes";

interface SceneControlsProps {
  sceneState: SceneState;
  currentUser?: User | null;
  hasAcceptedLicense?: boolean;
  useCorsProxy: boolean;
  setUseCorsProxy: React.Dispatch<React.SetStateAction<boolean>>;
  corsProxyUrl: string;
  setCorsProxyUrl: React.Dispatch<React.SetStateAction<string>>;
  isMobile: boolean;
  sceneFiles: {
    saveToJson: () => any;
    loadFromJson: (saveData: any, sceneId?: string) => void;
    loadSaveData: (saveData: any) => void;
    saveToJsonNoDownload: () => any;
    handleExportConfirm: (
      modelUrl: string,
      includeScrollControls: boolean,
      defaultCameraMode: CameraMode,
      allowedCameraModes: string[],
      includeXR: boolean,
      xrMode: XRMode,
      arOptions: AROptions,
      newUIOptions: UIOptions,
      currentUser: { uid: string; displayName: string },
      sceneId: string,
      collisionMeshesData: SerializedMeshData[],
      sceneType: SceneType
    ) => void;
  };
  isPro: boolean;
  isBusiness: boolean;
  onCapturePreview: () => Promise<Blob | null>;
  hotspotManagerRef: React.RefObject<any>;
}

export const SceneControls: React.FC<SceneControlsProps> = ({
  sceneState,
  currentUser,
  hasAcceptedLicense,
  useCorsProxy,
  setUseCorsProxy,
  corsProxyUrl,
  setCorsProxyUrl,
  isMobile,
  sceneFiles,
  isPro,
  isBusiness,
  onCapturePreview,
  hotspotManagerRef
}) => {
  const sceneNavigation = useSceneNavigation({
    pathRef: sceneState.pathRef,
    scrollTargetRef: sceneState.scrollTargetRef,
    scrollPositionRef: sceneState.scrollPositionRef,
    userControlRef: sceneState.userControlRef,
    cameraModeRef: sceneState.cameraModeRef,
    loopModeRef: sceneState.loopModeRef,
  });

  const [showVersionPopup, setShowVersionPopup] = useState(false);
  const [activeControl, setActiveControl] = useState<
    "move" | "rotate" | "scale"
  >();
  const [selectedMeshIndex, setSelectedMeshIndex] = useState<number | null>(
    null
  );

  const handleExport = () => {
    if (!currentUser) {
      alert("Please sign in to export your scene.");
      return;
    }

   if (!hasAcceptedLicense) {
      sceneState.setShowLicenseAgreement(true);
      return;
    }
 
    sceneState.setShowExportPopup(true);
  };

  const scene = sceneState.sceneRef.current;
  const camera = sceneState.cameraRef.current;
  const hasLoadedModel = sceneState.loadedMeshesRef.current.length > 0;
  const isSceneReady =
    scene &&
    scene.getEngine() &&
    !sceneState.isLoading &&
    !sceneState.isSplatLoading &&
    hasLoadedModel;

  return (
    <>
      <TopToolbar
        {...sceneState}
        {...sceneFiles}
        useCorsProxy={useCorsProxy}
        setUseCorsProxy={setUseCorsProxy}
        corsProxyUrl={corsProxyUrl}
        setCorsProxyUrl={setCorsProxyUrl}
        handleExport={handleExport}
        cameraSwing={sceneState.cameraDampening}
        setCameraSwing={sceneState.setCameraDampening}
        scene={sceneState.sceneRef.current}
        camera={sceneState.cameraRef.current}
        setActiveControl={setActiveControl}
        selectedMeshIndex={selectedMeshIndex}
        setSelectedMeshIndex={setSelectedMeshIndex}
        loadedMeshes={sceneState.loadedMeshesRef.current}
        lights={sceneState.lights}
        setLights={sceneState.setLights}
        isLightEditMode={sceneState.isLightEditMode}
        setIsLightEditMode={sceneState.setIsLightEditMode}
        selectedLightIndex={sceneState.selectedLightIndex}
        setSelectedLightIndex={sceneState.setSelectedLightIndex}
        activeControl={activeControl}
        hotspotManagerRef={hotspotManagerRef}
      />

      {isSceneReady && scene && camera && (
        <>
          <ScrollControls
            scrollPercentage={sceneState.scrollPercentage}
            adjustScroll={sceneNavigation.adjustScroll}
            moveToWaypoint={(direction) =>
              sceneNavigation.moveToWaypoint(direction, sceneState.waypoints)
            }
            showScrollControls={sceneState.showScrollControls}
            setShowScrollControls={sceneState.setShowScrollControls}
            cameraMode={sceneState.cameraMode}
            setCameraMode={sceneState.setCameraMode}
            uiColor={sceneState.uiColor}
            transitionSpeed={sceneState.transitionSpeed}
            setTransitionSpeed={sceneState.setTransitionSpeed}
            scrollButtonMode={sceneState.scrollButtonMode}
            setScrollButtonMode={sceneState.setScrollButtonMode}
            scrollAmount={sceneState.scrollAmount}
            setScrollAmount={sceneState.setScrollAmount}
            infoPopupText={sceneState.infoPopupText}
            currentWaypointTitle={sceneState.currentWaypointTitle}
          />
          <WaypointVisualizer
            scene={scene}
            waypoints={sceneState.waypoints}
            setWaypoints={sceneState.setWaypoints}
            isEditMode={sceneState.isEditMode}
            loopMode={sceneState.loopMode}
            pathRef={sceneState.pathRef}
            rotationsRef={sceneState.rotationsRef}
          />
          <CollisionVisualizer
            scene={scene}
            collisionMeshesData={sceneState.collisionMeshesData}
            isCollisionEditMode={sceneState.isCollisionEditMode}
            activeControl={activeControl}
            selectedMeshIndex={selectedMeshIndex}
            setSelectedMeshIndex={setSelectedMeshIndex}   
            setCollisionMeshes={sceneState.setCollisionMeshes}
            setCollisionMeshesData={sceneState.setCollisionMeshesData}
          />
          <LightVisualizer
            scene={scene}
            lights={sceneState.lights}
            setLights={sceneState.setLights}
            isLightEditMode={sceneState.isLightEditMode}
            selectedLightIndex={sceneState.selectedLightIndex}
            activeControl={activeControl}
          />
        </>
      )}

      <UserDashboard
        getSceneConfig={() => ({
          modelUrl:
            sceneState.loadedModelUrl || sceneState.customModelUrl || "",
          includeScrollControls: sceneState.showScrollControls,
          waypoints: sceneState.waypoints,
          backgroundColor: sceneState.backgroundColor,
          cameraMovementSpeed: sceneState.cameraMovementSpeed,
          cameraRotationSensitivity: sceneState.cameraRotationSensitivity,
          scrollSpeed: sceneState.scrollSpeed,
          animationFrames: sceneState.animationFrames,
          hotspots: sceneState.hotspots,
          defaultCameraMode: sceneState.cameraMode,
          cameraDampeningRef: sceneState.cameraDampeningRef.current,
          uiColor: sceneState.uiColor,
          transitionSpeed: sceneState.transitionSpeed,
          scrollButtonMode: sceneState.scrollButtonMode,
          scrollAmount: sceneState.scrollAmount,
          allowedCameraModes: sceneState.allowedCameraModes,
          loopMode: sceneState.loopMode,
          autoPlaySpeed: sceneState.autoPlaySpeed,
          autoPlayEnabled: sceneState.autoPlayEnabled,
          collisionMeshesData: sceneState.collisionMeshesData,
          includeXR: false,
          lights: sceneState.lights,
        })}
        isModelLocal={sceneState.isModelLocal}
        modelUrl={sceneState.loadedModelUrl}
        localModelFile={sceneState.localModelFileRef.current}
        getJsonSave={sceneFiles.saveToJsonNoDownload}
        loadFromJsonSaveFile={sceneFiles.loadSaveData}
        uiOptions={sceneState.uiOptions}
        setUIOptions={sceneState.setUIOptions}
        setShowLicenseAgreement={sceneState.setShowLicenseAgreement}
        currentLoadedSceneId={sceneState.currentLoadedSceneId}
        setCurrentLoadedSceneId={sceneState.setCurrentLoadedSceneId}
        sceneTitle={sceneState.sceneTitle}
        setSceneTitle={sceneState.setSceneTitle}
        analyticsEnabled={config.analyticsEnabled}
        onCapturePreview={onCapturePreview}
        previewImageBlob={sceneState.thumbnailBlob}
      />
      {sceneState.showExportPopup && currentUser && (
        <ExportPopup
          onExport={(
            modelUrl,
            includeScrollControls,
            defaultCameraMode,
            allowedCameraModes,
            includeXR,
            xrMode,
            arOptions,
            newUIOptions,
        
          ) => {
            const userForExport = {
              uid: currentUser.uid,
              displayName: currentUser.displayName || "Anonymous User",
            };

            sceneFiles.handleExportConfirm(
              modelUrl,
              includeScrollControls,
              defaultCameraMode,
              allowedCameraModes,
              includeXR,
              xrMode,
              arOptions,
              newUIOptions,
              userForExport,
              sceneState.currentLoadedSceneId ?? "",
              sceneState.collisionMeshesData,
              sceneState.sceneType ?? "splat"
            );
            sceneState.setShowExportPopup(false);
          }}
          onCancel={() => sceneState.setShowExportPopup(false)}
          isModelLocal={sceneState.isModelLocal}
          initialUIOptions={sceneState.uiOptions}
          onUIOptionsChange={sceneState.setUIOptions}
          isPro={isPro}
          isBusiness={isBusiness}
          sceneTitle={sceneState.sceneTitle}
          setSceneTitle={sceneState.setSceneTitle}
          loadedModelUrl={sceneState.loadedModelUrl ?? ""}
          setLoadedModelUrl={sceneState.setLoadedModelUrl}
          mode="export"
        />
      )}

{sceneState.infoPopupText &&
        sceneState.uiOptions.infoPosition === "popup" && (
          <InfoPopup
            text={sceneState.infoPopupText}
            onClose={() => sceneState.setInfoPopupText(null)}
            uiColor={sceneState.uiColor}
          />
        )}

      {sceneState.showLicenseAgreement && (
        <LicenseButton
          standalone={false}
          showAcceptButton={true}
          forceVisible={true}
          onAccept={(popup) => {
              sceneState.setShowLicenseAgreement(false);
          //    sceneState.setShowExportPopup(true);
          }}
          onCancel={() => sceneState.setShowLicenseAgreement(false)}
          isProcessing={sceneState.isProcessingLicense}
        />
      )}
      
      <VersionDisplay 
        version={VERSION} 
        onClick={() => {
          localStorage.removeItem(`version-${VERSION}-dismissed`);
          setShowVersionPopup(true);
        }} 
      />
      <GitHubLink repoUrl="https://sonnycirasuolo.com" />
      {showVersionPopup && (
        <VersionPopup
          version={VERSION}
          changes={VERSION_CHANGES}
          onDismiss={() => {
            setShowVersionPopup(false);
            localStorage.setItem(`version-${VERSION}-dismissed`, 'true');
          }}
        />
      )}
    </>
  );
};

export default SceneControls;
