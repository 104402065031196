import React, { createContext, useContext, useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_CONFIG } from '../utils/StripeConfig';

interface StripeContextType {
  stripe: Stripe | null;
  isLoading: boolean;
  error: string | null;
}

const StripeContext = createContext<StripeContextType>({
  stripe: null,
  isLoading: true,
  error: null,
});

export const StripeProvider: React.FC<{ 
  children: React.ReactNode,
  onLoadComplete: (error?: string) => void 
}> = ({ 
  children,
  onLoadComplete 
}) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initStripe = async () => {
      try {
        const stripeInstance = await loadStripe(STRIPE_CONFIG.PUBLISHABLE_KEY);
        setStripePromise(Promise.resolve(stripeInstance));
        setStripe(stripeInstance);
        onLoadComplete(); // Notify parent that Stripe loaded successfully
      } catch (error) {
        console.error('Error initializing Stripe:', error);
        const errorMessage = 'Failed to initialize Stripe. Please try again later.';
        setError(errorMessage);
        onLoadComplete(errorMessage); // Notify parent of error but allow app to continue
      } finally {
        setIsLoading(false);
      }
    };

    initStripe();
  }, [onLoadComplete]);

  // Always render children wrapped in Elements, even if Stripe failed to load
  // This ensures the app continues to work even without Stripe
  return (
    <StripeContext.Provider value={{ stripe, isLoading, error }}>
      <Elements stripe={stripePromise}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
};

export const useStripe = () => useContext(StripeContext);

// Add a comment explaining the changes
/**
 * Changes made to fix Stripe initialization and loading behavior:
 * 1. Added onLoadComplete callback prop to notify parent of Stripe loading status
 * 2. Modified error handling to allow app to continue even if Stripe fails
 * 3. Always render children wrapped in Elements to ensure app functionality
 * 4. Removed loading/error conditional renders to prevent blocking app load
 */
