/*
File: useSceneSetup.ts
Description: Hook for setting up scene and handling model loading
Last modified: 2024-02-21
Changes: 
- Added onProgress parameter for tracking model loading progress
- Connected progress tracking to loadModelFile calls
*/

import { useCallback, Dispatch, SetStateAction } from 'react';
import * as BABYLON from "@babylonjs/core";
import loadModelFile from '../tools/LoadModelFile';

interface UseSceneSetupProps {
  sceneRef: React.MutableRefObject<BABYLON.Scene | null>;
  cameraRef: React.MutableRefObject<BABYLON.UniversalCamera | null>;
  loadedMeshesRef: React.MutableRefObject<BABYLON.AbstractMesh[]>;
  localModelFileRef: React.MutableRefObject<File | null>;
  setIsModelLocal: Dispatch<SetStateAction<boolean>>;
  infoTextRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsSplatLoading: Dispatch<SetStateAction<boolean>>;
  autoFrame: boolean;
  setSceneType: Dispatch<SetStateAction<"mesh" | "splat" | "ply" | null>>;
  renderHotspots?: () => void;
  onProgress?: (progress: number) => void; // Added progress callback
}

export const useSceneSetup = ({
  sceneRef,
  cameraRef,
  loadedMeshesRef,
  localModelFileRef,
  setIsModelLocal,
  infoTextRef,
  setIsSplatLoading,
  autoFrame,
  setSceneType,
  renderHotspots,
  onProgress
}: UseSceneSetupProps) => {
  const handleSceneReady = useCallback((scene: BABYLON.Scene, camera: BABYLON.UniversalCamera) => {
    console.log("handleSceneReady called");
    sceneRef.current = scene;
    cameraRef.current = camera;

    // If there's a local model file waiting to be loaded, load it now
    if (localModelFileRef.current && scene) {
      loadModelFile(
        localModelFileRef.current,
        scene,
        autoFrame,
        setIsModelLocal,
        infoTextRef,
        setIsSplatLoading,
        setSceneType,
        renderHotspots,
        onProgress // Pass progress callback
      ).then(loadedMeshes => {
        if (loadedMeshes) {
          loadedMeshesRef.current = loadedMeshes;
        }
      }).catch(error => {
        console.error("Error loading model file:", error);
        if (infoTextRef.current) {
          infoTextRef.current.style.display = "block";
          infoTextRef.current.innerText = "Failed to load the model.";
        }
        setIsSplatLoading(false);
      });
    }
  }, [sceneRef, cameraRef, loadedMeshesRef, localModelFileRef, setIsModelLocal, infoTextRef, setIsSplatLoading, autoFrame, renderHotspots, onProgress]);

  const handleDrop = useCallback(async (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Hide the drop zone when file is dropped
    if (infoTextRef.current) {
      infoTextRef.current.style.display = "none";
    }

    const files = e.dataTransfer?.files;
    if (!files || !files.length || !sceneRef.current) return;

    const file = files[0];
    const ext = file.name.split(".").pop()?.toLowerCase();

    if (["splat", "ply", "gltf", "glb"].includes(ext || "")) {
      try {
        // Ensure scene is ready and has an engine
        if (!sceneRef.current.getEngine()) {
          console.warn('Scene engine not ready for file drop');
          return;
        }

        localModelFileRef.current = file;
        const loadedMeshes = await loadModelFile(
          file,
          sceneRef.current,
          autoFrame,
          setIsModelLocal,
          infoTextRef,
          setIsSplatLoading, 
          setSceneType,
          renderHotspots,
          onProgress // Pass progress callback
        );
        if (loadedMeshes) {
          // Add new meshes to existing ones instead of replacing
          loadedMeshesRef.current = [
            ...loadedMeshesRef.current,
            ...loadedMeshes
          ];
        }
      } catch (error) {
        console.error("Error loading model file:", error);
        alert("Failed to load the model.");
        if (infoTextRef.current) {
          infoTextRef.current.style.display = "block";
          infoTextRef.current.innerText = "Failed to load the model.";
        }
        setIsSplatLoading(false);
      }
    } else {
      alert("Please drop a .splat, .ply, .gltf, or .glb file.");
    }
  }, [sceneRef, localModelFileRef, loadedMeshesRef, setIsModelLocal, infoTextRef, setIsSplatLoading, autoFrame, renderHotspots, onProgress]);

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Show the drop zone when file is being dragged over
    if (infoTextRef.current) {
      infoTextRef.current.style.display = "block";
    }
  }, [infoTextRef]);

  const handleDragLeave = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Hide the drop zone when file is dragged out
    if (infoTextRef.current) {
      infoTextRef.current.style.display = "none";
    }
  }, [infoTextRef]);

  const setupEventListeners = useCallback(() => {
    document.addEventListener("dragover", handleDragOver, false);
    document.addEventListener("dragleave", handleDragLeave, false);
    document.addEventListener("drop", handleDrop, false);

    return () => {
      document.removeEventListener("dragover", handleDragOver, false);
      document.removeEventListener("dragleave", handleDragLeave, false);
      document.removeEventListener("drop", handleDrop, false);
    };
  }, [handleDragOver, handleDragLeave, handleDrop]);

  return {
    handleSceneReady,
    setupEventListeners,
  };
};
