import * as BABYLON from "@babylonjs/core";
import { MutableRefObject } from "react";
import { CameraMode } from "../types/SceneTypes";

export const wheelHandler = (
  event: WheelEvent,
  animatingToPathRef: MutableRefObject<boolean>,
  userControlRef: MutableRefObject<boolean>,
  camera: BABYLON.UniversalCamera,
  pathRef: MutableRefObject<BABYLON.Vector3[]>,
  rotations: BABYLON.Quaternion[],
  waypoints: { x: number; y: number; z: number; rotation: BABYLON.Quaternion }[],
  animationFrames: number,
  scrollSpeed: number,
  scrollTargetRef: MutableRefObject<number>,
  scrollPositionRef: MutableRefObject<number>,
  isEditMode: boolean,
  cameraMode: CameraMode,
  loopMode?: boolean
) => {
  if (animatingToPathRef.current || isEditMode) return;
  
  // Lock down tour mode completely
  if (cameraMode === 'tour') {
    userControlRef.current = false;
    // Handle scroll input for tour mode
    scrollTargetRef.current += event.deltaY * scrollSpeed;
    
    if (loopMode) {
      // Allow scrolling past bounds for smooth looping
      if (scrollTargetRef.current >= pathRef.current.length - 1.1) {
        scrollTargetRef.current = 0.2;
        scrollPositionRef.current = 0.2;
      } else if (scrollTargetRef.current <= 0.1) {
        scrollTargetRef.current = pathRef.current.length - 1.11;
        scrollPositionRef.current = pathRef.current.length - 1.11;
      }
    } else {
      // Clamp within bounds when not looping
      scrollTargetRef.current = Math.max(0, Math.min(scrollTargetRef.current, pathRef.current.length - 1));
    }
    return;
  }

  if (cameraMode === 'explore') {
    userControlRef.current = true;
  } else {
    // Auto mode scrolling
    scrollTargetRef.current += event.deltaY * scrollSpeed;
    
    if (loopMode) {
      // Allow scrolling past bounds for smooth looping
      if (scrollTargetRef.current >= pathRef.current.length - 1.1) {
        scrollTargetRef.current = 0.2;
        scrollPositionRef.current = 0.2;
      } else if (scrollTargetRef.current <= 0.1) {
        scrollTargetRef.current = pathRef.current.length - 1.11;
        scrollPositionRef.current = pathRef.current.length - 1.11;
      }
    } else {
      // Clamp within bounds when not looping
      scrollTargetRef.current = Math.max(0, Math.min(scrollTargetRef.current, pathRef.current.length - 1));
    }
  }
};
