/*
File: useSceneState.ts
Description: Hook for managing scene state
Last modified: 2024-01-22
Changes: 
- Added sceneType state and setter
- Added sceneType to SceneState interface
- Added edit camera mode support
*/

import { useState, useRef, useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import * as BABYLON from "@babylonjs/core";
import { Waypoint, AudioInteractionData, InfoInteractionData, DEFAULT_SETTINGS, UIOptions, SerializedMeshData, SceneLight, SceneType, CameraMode } from '../types/SceneTypes';
import { Hotspot } from '../../src/types/SceneTypes';
import { UIType } from '../tools/html-generation/types';

export interface SceneState {
  sceneType: SceneType | null;
  setSceneType: Dispatch<SetStateAction<SceneType | null>>;
  lights: SceneLight[];
  setLights: Dispatch<SetStateAction<SceneLight[]>>;
  isLightEditMode: boolean;
  setIsLightEditMode: Dispatch<SetStateAction<boolean>>;
  selectedLightIndex: number | null;
  setSelectedLightIndex: Dispatch<SetStateAction<number | null>>;
  autoFrame: boolean;
  setAutoFrame: Dispatch<SetStateAction<boolean>>;
  sceneRef: React.MutableRefObject<BABYLON.Scene | null>;
  cameraRef: React.MutableRefObject<BABYLON.UniversalCamera | null>;
  editCameraRef: React.MutableRefObject<BABYLON.ArcRotateCamera | null>;
  pathRef: React.MutableRefObject<BABYLON.Vector3[]>;
  rotationsRef: React.MutableRefObject<BABYLON.Quaternion[]>;
  loadedMeshesRef: React.MutableRefObject<BABYLON.AbstractMesh[]>;
  collisionMeshesRef: React.MutableRefObject<BABYLON.Mesh[]>;
  localModelFileRef: React.MutableRefObject<File | null>;
  userControlRef: React.MutableRefObject<boolean>;
  animatingToPathRef: React.MutableRefObject<boolean>;
  scrollPositionRef: React.MutableRefObject<number>;
  scrollTargetRef: React.MutableRefObject<number>;
  cameraModeRef: React.MutableRefObject<CameraMode>;
  cameraDampeningRef: React.MutableRefObject<number>;
  transitionSpeedRef: React.MutableRefObject<number>;
  autoPlaySpeedRef: React.MutableRefObject<number>;
  autoPlayEnabledRef: React.MutableRefObject<boolean>;
  loopModeRef: React.MutableRefObject<boolean>;
  cameraMovementSpeedRef: React.MutableRefObject<number>;
  waypoints: Waypoint[];
  setWaypoints: Dispatch<SetStateAction<Waypoint[]>>;
  waypointsRef: React.MutableRefObject<Waypoint[]>;
  activeWaypointsRef: React.MutableRefObject<Set<number>>;
  activeAudioRefs: React.MutableRefObject<{
    [key: string]: { sound: BABYLON.Sound; data: AudioInteractionData };
  }>;
  targetRotationRef: React.MutableRefObject<BABYLON.Quaternion>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isCollisionEditMode: boolean;
  setIsCollisionEditMode: Dispatch<SetStateAction<boolean>>;
  cameraMode: CameraMode;
  setCameraMode: Dispatch<SetStateAction<CameraMode>>;
  scrollPercentage: number;
  setScrollPercentage: Dispatch<SetStateAction<number>>;
  showScrollControls: boolean;
  setShowScrollControls: Dispatch<SetStateAction<boolean>>;
  currentWaypointTitle: string;
  setCurrentWaypointTitle: Dispatch<SetStateAction<string>>;
  infoPopupText: string | null;
  setInfoPopupText: Dispatch<SetStateAction<string | null>>;
  loadedModelUrl: string | null;
  setLoadedModelUrl: Dispatch<SetStateAction<string | null>>;
  isModelLocal: boolean;
  setIsModelLocal: Dispatch<SetStateAction<boolean>>;
  backgroundColor: string;
  setBackgroundColor: Dispatch<SetStateAction<string>>;
  customModelUrl: string;
  setCustomModelUrl: Dispatch<SetStateAction<string>>;
  hotspots: Hotspot[];
  setHotspots: Dispatch<SetStateAction<Hotspot[]>>;
  uiColor: string;
  setUiColor: Dispatch<SetStateAction<string>>;
  sceneTitle: string;
  setSceneTitle: Dispatch<SetStateAction<string>>;
  scrollSpeed: number;
  setScrollSpeed: Dispatch<SetStateAction<number>>;
  animationFrames: number;
  setAnimationFrames: Dispatch<SetStateAction<number>>;
  cameraMovementSpeed: number;
  setCameraMovementSpeed: Dispatch<SetStateAction<number>>;
  cameraRotationSensitivity: number;
  setCameraRotationSensitivity: Dispatch<SetStateAction<number>>;
  cameraDampening: number;
  setCameraDampening: Dispatch<SetStateAction<number>>;
  transitionSpeed: number;
  setTransitionSpeed: Dispatch<SetStateAction<number>>;
  scrollButtonMode: "percentage" | "waypoint";
  setScrollButtonMode: Dispatch<SetStateAction<"percentage" | "waypoint">>;
  scrollAmount: number;
  setScrollAmount: Dispatch<SetStateAction<number>>;
  allowedCameraModes: string[];
  setAllowedCameraModes: Dispatch<SetStateAction<string[]>>;
  loopMode: boolean;
  setLoopMode: Dispatch<SetStateAction<boolean>>;
  autoPlaySpeed: number;
  setAutoPlaySpeed: Dispatch<SetStateAction<number>>;
  autoPlayEnabled: boolean;
  setAutoPlayEnabled: Dispatch<SetStateAction<boolean>>;
  uiOptions: UIOptions;
  setUIOptions: Dispatch<SetStateAction<UIOptions>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isSplatLoading: boolean;
  setIsSplatLoading: Dispatch<SetStateAction<boolean>>;
  showLicenseAgreement: boolean;
  setShowLicenseAgreement: Dispatch<SetStateAction<boolean>>;
  showExportPopup: boolean;
  setShowExportPopup: Dispatch<SetStateAction<boolean>>;
  isProcessingLicense: boolean;
  setIsProcessingLicense: Dispatch<SetStateAction<boolean>>;
  currentLoadedSceneId: string | null;
  setCurrentLoadedSceneId: Dispatch<SetStateAction<string | null>>;
  collisionMeshesData: SerializedMeshData[];
  setCollisionMeshesData: Dispatch<SetStateAction<SerializedMeshData[]>>;
  collisionMeshes: BABYLON.Mesh[];
  setCollisionMeshes: Dispatch<SetStateAction<BABYLON.Mesh[]>>;
  resetSettings: () => void;
  thumbnailBlob: Blob | null;
  setThumbnailBlob: Dispatch<SetStateAction<Blob | null>>; 
  thumbnailBlobRef: React.MutableRefObject<Blob | null>;
}

export const useSceneState = (): SceneState => {
  const [sceneType, setSceneType] = useState<SceneType | null>(null);
  const [lights, setLights] = useState<SceneLight[]>(DEFAULT_SETTINGS.lights);
  const [isLightEditMode, setIsLightEditMode] = useState<boolean>(false);
  const [selectedLightIndex, setSelectedLightIndex] = useState<number | null>(null);
  const [autoFrame, setAutoFrame] = useState<boolean>(DEFAULT_SETTINGS.autoFrame);

  // Scene refs
  const sceneRef = useRef<BABYLON.Scene | null>(null);
  const cameraRef = useRef<BABYLON.UniversalCamera | null>(null);
  const editCameraRef = useRef<BABYLON.ArcRotateCamera | null>(null);
  const pathRef = useRef<BABYLON.Vector3[]>([]);
  const rotationsRef = useRef<BABYLON.Quaternion[]>([]);
  const loadedMeshesRef = useRef<BABYLON.AbstractMesh[]>([]);
  const collisionMeshesRef = useRef<BABYLON.Mesh[]>([]);
  const localModelFileRef = useRef<File | null>(null);
  const thumbnailBlobRef = useRef<Blob | null>(null);

  // Control refs
  const userControlRef = useRef<boolean>(false);
  const animatingToPathRef = useRef<boolean>(false);
  const scrollPositionRef = useRef<number>(0);
  const scrollTargetRef = useRef<number>(0.01);
  const cameraModeRef = useRef<CameraMode>("explore");
  const cameraDampeningRef = useRef(0.05);
  const transitionSpeedRef = useRef<number>(0.5);
  const autoPlaySpeedRef = useRef<number>(0.05);
  const autoPlayEnabledRef = useRef<boolean>(false);
  const loopModeRef = useRef<boolean>(false);
  const cameraMovementSpeedRef = useRef<number>(DEFAULT_SETTINGS.cameraMovementSpeed);

  // Waypoints and interactions
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const waypointsRef = useRef<Waypoint[]>([]);
  const activeWaypointsRef = useRef<Set<number>>(new Set());
  const activeAudioRefs = useRef<{
    [key: string]: { sound: BABYLON.Sound; data: AudioInteractionData };
  }>({});
  const targetRotationRef = useRef<BABYLON.Quaternion>(BABYLON.Quaternion.Identity());

  // UI and control states
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCollisionEditMode, setIsCollisionEditMode] = useState(false);
  const [cameraMode, setCameraMode] = useState<CameraMode>("explore");
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [showScrollControls, setShowScrollControls] = useState<boolean>(true);
  const [currentWaypointTitle, setCurrentWaypointTitle] = useState<string>("");
  const [infoPopupText, setInfoPopupText] = useState<string | null>(null);

  // Scene configuration
  const [loadedModelUrl, setLoadedModelUrl] = useState<string | null>(null);
  const [isModelLocal, setIsModelLocal] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(DEFAULT_SETTINGS.backgroundColor);
  const [customModelUrl, setCustomModelUrl] = useState<string>("");
  const [hotspots, setHotspots] = useState<Hotspot[]>([]);
  const [uiColor, setUiColor] = useState<string>("#4CAF50");
  const [sceneTitle, setSceneTitle] = useState<string>("");
  const [thumbnailBlob, setThumbnailBlob] = useState<Blob | null>(null);

  // Performance and animation settings
  const [scrollSpeed, setScrollSpeed] = useState<number>(DEFAULT_SETTINGS.scrollSpeed);
  const [animationFrames, setAnimationFrames] = useState<number>(DEFAULT_SETTINGS.animationFrames);
  const [cameraMovementSpeed, setCameraMovementSpeed] = useState<number>(DEFAULT_SETTINGS.cameraMovementSpeed);
  const [cameraRotationSensitivity, setCameraRotationSensitivity] = useState<number>(DEFAULT_SETTINGS.cameraRotationSensitivity);
  const [cameraDampening, setCameraDampening] = useState<number>(0.95);
  const [transitionSpeed, setTransitionSpeed] = useState<number>(0.5);

  // Additional settings
  const [scrollButtonMode, setScrollButtonMode] = useState<"percentage" | "waypoint">("waypoint");
  const [scrollAmount, setScrollAmount] = useState(10);
  const [allowedCameraModes, setAllowedCameraModes] = useState<string[]>(["tour", "explore", "auto", "edit"]);
  const [loopMode, setLoopMode] = useState(false);
  const [autoPlaySpeed, setAutoPlaySpeed] = useState(0.05);
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(false);
  const [uiOptions, setUIOptions] = useState<UIOptions>({
    infoPosition: 'controls',
    buttonPosition: "below",
    uiType: UIType.Standard,
    showStartExperience: false,
    debugMode: false,
    hideWatermark: false
  });

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSplatLoading, setIsSplatLoading] = useState<boolean>(false);

  // License and export states
  const [showLicenseAgreement, setShowLicenseAgreement] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [isProcessingLicense, setIsProcessingLicense] = useState(false);
  const [currentLoadedSceneId, setCurrentLoadedSceneId] = useState<string | null>(null);
  const [collisionMeshesData, setCollisionMeshesData] = useState<SerializedMeshData[]>([]);
  const [collisionMeshes, setCollisionMeshes] = useState<BABYLON.Mesh[]>([]);

  // Effects
  useEffect(() => {
    collisionMeshesRef.current = collisionMeshes;
  }, [collisionMeshes]);

  useEffect(() => {
    cameraModeRef.current = cameraMode;
  }, [cameraMode]);

  useEffect(() => {
    waypointsRef.current = waypoints;
  }, [waypoints]);

  useEffect(() => {
    loopModeRef.current = loopMode;
  }, [loopMode]);

  useEffect(() => {
    autoPlayEnabledRef.current = autoPlayEnabled;
  }, [autoPlayEnabled]);

  useEffect(() => {
    transitionSpeedRef.current = transitionSpeed;
  }, [transitionSpeed]);

  useEffect(() => {
    if (isEditMode) {
      setCameraMode("explore");
      cameraModeRef.current = "explore";
    }
  }, [isEditMode]);

  useEffect(() => {
    thumbnailBlobRef.current = thumbnailBlob;
    console.log("SCENESTATE:: Thumbnail blob updated:", thumbnailBlob);
  }, [thumbnailBlob]);

  const resetSettings = useCallback(() => {
    setScrollSpeed(DEFAULT_SETTINGS.scrollSpeed);
    setAnimationFrames(DEFAULT_SETTINGS.animationFrames);
    setCameraMovementSpeed(DEFAULT_SETTINGS.cameraMovementSpeed);
    setCameraRotationSensitivity(DEFAULT_SETTINGS.cameraRotationSensitivity);
    setBackgroundColor(DEFAULT_SETTINGS.backgroundColor);
    setAutoFrame(DEFAULT_SETTINGS.autoFrame);
    setCustomModelUrl("");
    setLoadedModelUrl(null);
    setIsModelLocal(false);
    setWaypoints([]);
    setLights(DEFAULT_SETTINGS.lights);
    setSceneType(null);
  }, []);

  return {
    sceneType,
    setSceneType,
    lights,
    setLights,
    isLightEditMode,
    setIsLightEditMode,
    selectedLightIndex,
    setSelectedLightIndex,
    autoFrame,
    setAutoFrame,
    sceneRef,
    cameraRef,
    editCameraRef,
    pathRef,
    rotationsRef,
    loadedMeshesRef,
    collisionMeshesRef,
    localModelFileRef,
    userControlRef,
    animatingToPathRef,
    scrollPositionRef,
    scrollTargetRef,
    cameraModeRef,
    cameraDampeningRef,
    transitionSpeedRef,
    autoPlaySpeedRef,
    autoPlayEnabledRef,
    loopModeRef,
    cameraMovementSpeedRef,
    waypoints,
    setWaypoints,
    waypointsRef,
    activeWaypointsRef,
    activeAudioRefs,
    targetRotationRef,
    isEditMode,
    setIsEditMode,
    isCollisionEditMode,
    setIsCollisionEditMode,
    cameraMode,
    setCameraMode,
    scrollPercentage,
    setScrollPercentage,
    showScrollControls,
    setShowScrollControls,
    currentWaypointTitle,
    setCurrentWaypointTitle,
    infoPopupText,
    setInfoPopupText,
    loadedModelUrl,
    setLoadedModelUrl,
    isModelLocal,
    setIsModelLocal,
    backgroundColor,
    setBackgroundColor,
    customModelUrl,
    setCustomModelUrl,
    hotspots,
    setHotspots,
    uiColor,
    setUiColor,
    sceneTitle,
    setSceneTitle,
    scrollSpeed,
    setScrollSpeed,
    animationFrames,
    setAnimationFrames,
    cameraMovementSpeed,
    setCameraMovementSpeed,
    cameraRotationSensitivity,
    setCameraRotationSensitivity,
    cameraDampening,
    setCameraDampening,
    transitionSpeed,
    setTransitionSpeed,
    scrollButtonMode,
    setScrollButtonMode,
    scrollAmount,
    setScrollAmount,
    allowedCameraModes,
    setAllowedCameraModes,
    loopMode,
    setLoopMode,
    autoPlaySpeed,
    setAutoPlaySpeed,
    autoPlayEnabled,
    setAutoPlayEnabled,
    uiOptions,
    setUIOptions,
    isLoading,
    setIsLoading,
    isSplatLoading,
    setIsSplatLoading,
    showLicenseAgreement,
    setShowLicenseAgreement,
    showExportPopup,
    setShowExportPopup,
    isProcessingLicense,
    setIsProcessingLicense,
    currentLoadedSceneId,
    setCurrentLoadedSceneId,
    collisionMeshesData,
    setCollisionMeshesData,
    collisionMeshes,
    setCollisionMeshes,
    resetSettings,
    thumbnailBlob,
    setThumbnailBlob,
    thumbnailBlobRef
  };
};
