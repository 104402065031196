// WaypointControls.tsx
// This component manages the waypoint system for camera paths and interactions
// Modified on 2024-01-09: Added hover states and tooltips to buttons for better UX
// [Previous imports and styled components remain unchanged...]
import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import * as BABYLON from "@babylonjs/core";
import { Waypoint, Interaction, CameraMode } from "../types/SceneTypes";
import InteractionEditor from "./InteractionEditor";
import { FiCamera, FiChevronDown, FiChevronUp, FiEdit2, FiTrash2, FiInfo } from "react-icons/fi";
import styled from "styled-components";
import { Scene } from "@babylonjs/core";

// ... (previous styled components remain unchanged until Button) ...

// ... (keep all styled components)
type WaypointControlsProps = {
  waypoints: Waypoint[];
  setWaypoints: React.Dispatch<React.SetStateAction<Waypoint[]>>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  scene: Scene | null;
  cameraMode: CameraMode;
  setCameraMode: React.Dispatch<React.SetStateAction<CameraMode>>;
  loopMode: boolean;
  setLoopMode: React.Dispatch<React.SetStateAction<boolean>>;
  autoPlaySpeed: number;
  setAutoPlaySpeed: React.Dispatch<React.SetStateAction<number>>;
  autoPlayEnabled: boolean;
  setAutoPlayEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const Container = styled.div`
  position: absolute;
  top: 250px;
  left: 10px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 380px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Content = styled.div<{ $isCollapsed: boolean }>`
  max-height: ${props => props.$isCollapsed ? "0px" : "600px"};
  opacity: ${props => props.$isCollapsed ? 0 : 1};
  padding: ${props => props.$isCollapsed ? "0 16px" : "16px"};
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow-y: auto;
`;

const Header = styled.div<{ $isCollapsed: boolean }>`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${props => props.$isCollapsed ? "8px" : ""};
  border-bottom-right-radius: ${props => props.$isCollapsed ? "8px" : ""};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1e90ff;
  }
`;


const ControlsContainer = styled.div`
  margin-bottom: 16px;
`;

const ControlRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  color: #ddd;
  margin-right: 8px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  font-size: 14px;
  color: #ddd;
  margin-left: 8px;
`;
const Slider = styled.input`
  flex: 1;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Button = styled.button<{ $variant?: 'primary' | 'danger' | 'secondary' }>`
  padding: 3px 6px;
  background-color: ${props => 
    props.$variant === 'primary' ? '#007BFF' : 
    props.$variant === 'danger' ? '#dc3545' : 
    props.$variant === 'secondary' ? 'transparent' : '#555555'
  };
  color: ${props => props.$variant === 'secondary' ? '#ccc' : 'white'};
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props => 
      props.$variant === 'primary' ? '#0056b3' : 
      props.$variant === 'danger' ? '#c82333' : 
      props.$variant === 'secondary' ? 'rgba(255, 255, 255, 0.1)' : '#666666'
    };
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;


const WaypointItem = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #444;
`;

const WaypointHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const WaypointNameInput = styled.input`
  width: 96%;
  padding: 4px 8px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 4px;
  color: white;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007BFF;
  }
`;

const CoordinatesContainer = styled.div`
  margin-top: 6px;
`;

const CoordinateGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 4px;
`;

const CoordinateLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ccc;
  width: 30%;
`;

const CoordinateInput = styled.input`
  width: 70px;
  padding: 2px 4px;
  border-radius: 3px;
  border: 1px solid #555;
  background-color: #333;
  color: #fff;
  font-size: 12px;
`;

const AddWaypointButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 5px;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
`;
const TooltipText = styled.span<{ xOffset: number, yOffset?: number }>`
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: ${props => props.yOffset ? `calc(125% + ${props.yOffset}px)` : '125%'};
  font-size: 12px;
  left: 50%;
  transform: translateX(${props => props.xOffset}px);
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: ${props => -props.xOffset - 5}px;
    border-width: 5px;
    border-style: solid;
    border-color: ${props => props.yOffset ? 'transparent' : '#555 transparent transparent transparent'};
  }
`;
const WaypointControls: React.FC<WaypointControlsProps> = ({
  waypoints,
  setWaypoints,
  isEditMode,
  setIsEditMode,
  scene,
  cameraMode,
  setCameraMode,
  loopMode,
  setLoopMode,
  autoPlaySpeed,
  setAutoPlaySpeed,
  autoPlayEnabled,
  setAutoPlayEnabled}) => {
    const [editingWaypointIndex, setEditingWaypointIndex] = useState<number | null>(null);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [collapsedWaypoints, setCollapsedWaypoints] = useState<Set<number>>(new Set());
    const previousCameraModeRef = useRef<CameraMode>(cameraMode);
  
    useEffect(() => {
      setCollapsedWaypoints(new Set(waypoints.map((_, index) => index)));
    }, [waypoints.length]);
  
    const getQuaternionFromRotation = (rotation: any): BABYLON.Quaternion => {
      if (rotation instanceof BABYLON.Quaternion) {
        return rotation.clone();
      } else if (rotation && typeof rotation === 'object' && 'x' in rotation && 'y' in rotation && 'z' in rotation && 'w' in rotation) {
        return new BABYLON.Quaternion(rotation.x, rotation.y, rotation.z, rotation.w);
      } else {
        console.warn('Invalid rotation format, using default Quaternion');
        return BABYLON.Quaternion.Identity();
      }
    };
    
    const handleWaypointNameChange = (index: number, newName: string) => {
      const newWaypoints = [...waypoints];
      newWaypoints[index] = {
        ...newWaypoints[index],
        name: newName
      };
      setWaypoints(newWaypoints);
    };
  
    const handleWaypointChange = (
      index: number,
      axis: "x" | "y" | "z" | "rotationX" | "rotationY" | "rotationZ",
      value: string
    ) => {
      const newWaypoints = [...waypoints];
      if (axis === "x" || axis === "y" || axis === "z") {
        newWaypoints[index] = { ...newWaypoints[index], [axis]: parseFloat(value) };
      } else {
        const quaternion = getQuaternionFromRotation(newWaypoints[index].rotation);
        const euler = quaternion.toEulerAngles();
        euler[axis.charAt(8).toLowerCase() as 'x' | 'y' | 'z'] = BABYLON.Angle.FromDegrees(parseFloat(value)).radians();
        newWaypoints[index] = {
          ...newWaypoints[index],
          rotation: BABYLON.Quaternion.FromEulerAngles(euler.x, euler.y, euler.z),
        };
      }
      setWaypoints(newWaypoints);
    };
  
    const addWaypoint = () => {
      const camera = scene?.activeCamera;
      const cameraRotation = camera?.absoluteRotation;
      const cameraPosition = camera?.position;
  
      const newWaypoint: Waypoint = {
        x: cameraPosition ? cameraPosition.x : 0,
        y: cameraPosition ? cameraPosition.y : 0,
        z: cameraPosition ? cameraPosition.z : 0,
        rotation: cameraRotation ? cameraRotation.clone() : BABYLON.Quaternion.Identity(),
        interactions: [],
        triggerDistance: 1,
        name: `Waypoint ${waypoints.length + 1}`
      };
      setWaypoints([...waypoints, newWaypoint]);
      setCollapsedWaypoints(prev => new Set(prev).add(waypoints.length));
    };
  
    const removeWaypoint = (index: number) => {
      const newWaypoints = waypoints.filter((_, i) => i !== index);
      setWaypoints(newWaypoints);
      setCollapsedWaypoints(prev => {
        const newSet = new Set<number>();
        newWaypoints.forEach((_, i) => {
          if (prev.has(i < index ? i : i + 1)) {
            newSet.add(i);
          }
        });
        return newSet;
      });
    };
  
    const updateWaypointInteractions = (interactions: Interaction[]) => {
      if (editingWaypointIndex !== null) {
        const newWaypoints = [...waypoints];
        newWaypoints[editingWaypointIndex] = { 
          ...newWaypoints[editingWaypointIndex], 
          interactions: interactions 
        };
        setWaypoints(newWaypoints);
      }
    };
  
    const updateWaypointTriggerDistance = (value: number) => {
      if (editingWaypointIndex !== null) {
        const newWaypoints = [...waypoints];
        newWaypoints[editingWaypointIndex] = { 
          ...newWaypoints[editingWaypointIndex], 
          triggerDistance: value 
        };
        setWaypoints(newWaypoints);
      }
    };
  
    const toggleEditMode = () => {
      if (!isEditMode) {
        // Store the current camera mode before entering edit mode
        previousCameraModeRef.current = cameraMode;
        setCameraMode('explore');
      } else {
        // Restore the previous camera mode when exiting edit mode
        setCameraMode(previousCameraModeRef.current);
      }
      setIsEditMode(!isEditMode);
    };
  
    const toggleWaypointCollapse = (index: number) => {
      setCollapsedWaypoints(prev => {
        const newSet = new Set(prev);
        if (newSet.has(index)) {
          newSet.delete(index);
        } else {
          newSet.add(index);
        }
        return newSet;
      });
    };
  
    const updateWaypointToCurrentCamera = (index: number) => {
      const camera = scene?.activeCamera;
      if (camera) {
        const newWaypoints = [...waypoints];
        newWaypoints[index] = {
          ...newWaypoints[index],
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z,
          rotation: camera.absoluteRotation.clone(),
        };
        setWaypoints(newWaypoints);
      }
    };
  
    const nodeRef = useRef(null);

  return (
    <>
      <Draggable handle=".handle" nodeRef={nodeRef}>
        <Container
          ref={nodeRef}
          onDragEnter={(e: React.DragEvent) => e.stopPropagation()}
          onDragOver={(e: React.DragEvent) => e.stopPropagation()}
          onDragLeave={(e: React.DragEvent) => e.stopPropagation()}
          onDrop={(e: React.DragEvent) => e.stopPropagation()}
        >
          <Header 
            $isCollapsed={isCollapsed} 
            className="handle"
            onDragEnter={(e: React.DragEvent) => e.stopPropagation()}
            onDragOver={(e: React.DragEvent) => e.stopPropagation()}
            onDragLeave={(e: React.DragEvent) => e.stopPropagation()}
            onDrop={(e: React.DragEvent) => e.stopPropagation()}
          >
            <Title>Waypoints</Title>
            <ButtonGroup>
              <Tooltip>
                <Button onClick={toggleEditMode} $variant={isEditMode ? 'danger' : 'primary'}>
                  {isEditMode ? "Exit Edit Mode" : "Enter Edit Mode"}
                </Button>
                <TooltipText xOffset={0}>
                  {isEditMode 
                    ? "Exit edit mode to return to normal camera controls" 
                    : "Enter edit mode to modify waypoint positions"}
                </TooltipText>
              </Tooltip>
              <ToggleButton onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
              </ToggleButton>
            </ButtonGroup>
          </Header>
          <Content $isCollapsed={isCollapsed}>
          <ControlsContainer>
              <ControlRow>
                <LabelRow>
                  <Label>
                    Autoplay Mode
                    <Tooltip>
                      <FiInfo />
                      <TooltipText xOffset={125} yOffset={-33}>
                        When enabled, the camera moves along the path automatically.
                      </TooltipText>
                    </Tooltip>
                  </Label>
                  <input
                    type="checkbox"
                    checked={autoPlayEnabled}
                    onChange={(e) => setAutoPlayEnabled(e.target.checked)}
                  />
                </LabelRow>
              </ControlRow>

              {autoPlayEnabled && (
                <ControlRow>
                  <LabelRow>
                    <Label>
                      Autoplay Speed
                      <Tooltip>
                        <FiInfo />
                        <TooltipText xOffset={125} yOffset={-33}>
                          Adjust the speed of automatic camera movement.
                        </TooltipText>
                      </Tooltip>
                    </Label>
                    <Value>{autoPlaySpeed.toFixed(2)}</Value>
                  </LabelRow>
                  <Slider
                    type="range"
                    min="0.01"
                    max="1"
                    step="0.01"
                    value={autoPlaySpeed}
                    onChange={(e) => setAutoPlaySpeed(parseFloat(e.target.value))}
                  />
                </ControlRow>
              )}

              <ControlRow>
                <LabelRow>
                  <Label>
                    Loop Mode
                    <Tooltip>
                      <FiInfo />
                      <TooltipText xOffset={125} yOffset={-33}>
                        If enabled, the path loops from the last waypoint to the first.
                      </TooltipText>
                    </Tooltip>
                  </Label>
                  <input
                    type="checkbox"
                    checked={loopMode}
                    onChange={(e) => setLoopMode(e.target.checked)}
                  />
                </LabelRow>
              </ControlRow>
            </ControlsContainer>

            {waypoints.map((waypoint, index) => (
              <WaypointItem key={index}>
                <WaypointHeader>
                  <WaypointNameInput
                    value={waypoint.name}
                    onChange={(e) => handleWaypointNameChange(index, e.target.value)}
                    placeholder={`Waypoint ${index + 1}`}
                  />
                  <ButtonGroup>
                    <Tooltip>
                      <Button 
                        onClick={() => updateWaypointToCurrentCamera(index)} 
                        $variant="primary"
                      >
                        <FiCamera />
                      </Button>
                      <TooltipText xOffset={75}>
                        Update to current camera position and rotation
                      </TooltipText>
                    </Tooltip>
                    
                    <Tooltip>
                      <Button 
                        onClick={() => setEditingWaypointIndex(index)} 
                        $variant="primary"
                      >
                        Edit Interactions
                      </Button>
                      <TooltipText xOffset={0}>
                        Configure interactions and trigger settings for this waypoint
                      </TooltipText>
                    </Tooltip>
                    
                    <Tooltip>
                      <Button 
                        onClick={() => removeWaypoint(index)} 
                        $variant="danger"
                      >
                        <FiTrash2 />
                      </Button>
                      <TooltipText xOffset={0}>
                        Delete this waypoint
                      </TooltipText>
                    </Tooltip>
                    
                    <Tooltip>
                      <Button 
                        onClick={() => toggleWaypointCollapse(index)} 
                        $variant="secondary"
                      >
                        {collapsedWaypoints.has(index) ? "Show" : "Hide"}
                      </Button>
                      <TooltipText xOffset={0}>
                        {collapsedWaypoints.has(index) 
                          ? "Show waypoint coordinates and rotation" 
                          : "Hide waypoint coordinates and rotation"}
                      </TooltipText>
                    </Tooltip>
                  </ButtonGroup>
                </WaypointHeader>

              
                {!collapsedWaypoints.has(index) && (
                  <CoordinatesContainer>
                    <CoordinateGroup>
                      {["x", "y", "z"].map((axis) => (
                        <CoordinateLabel key={axis}>
                          <span style={{ marginRight: "4px", width: "20px" }}>
                            {axis.toUpperCase()}:
                          </span>
                          <CoordinateInput
                            type="number"
                            step="0.01"
                            value={waypoint[axis as "x" | "y" | "z"].toFixed(2)}
                            onChange={(e) => handleWaypointChange(index, axis as "x" | "y" | "z", e.target.value)}
                          />
                        </CoordinateLabel>
                      ))}
                    </CoordinateGroup>
                    <CoordinateGroup>
                      {["rotationX", "rotationY", "rotationZ"].map((axis) => (
                        <CoordinateLabel key={axis}>
                          <span style={{ marginRight: "4px", width: "20px" }}>
                            {axis.replace("rotation", "R")}:
                          </span>
                          <CoordinateInput
                            type="number"
                            step="0.01"
                            value={BABYLON.Angle.FromRadians(
                              getQuaternionFromRotation(waypoint.rotation)
                                .toEulerAngles()[axis.charAt(8).toLowerCase() as 'x' | 'y' | 'z']
                            ).degrees().toFixed(2)}
                            onChange={(e) => handleWaypointChange(
                              index,
                              axis as "rotationX" | "rotationY" | "rotationZ",
                              e.target.value
                            )}
                          />
                        </CoordinateLabel>
                      ))}
                    </CoordinateGroup>
                  </CoordinatesContainer>
                )}
              </WaypointItem>
            ))}
            
            <Tooltip>
              <AddWaypointButton onClick={addWaypoint} $variant="primary">
                {waypoints.length > 0 ? "Add Waypoint at Camera Position" : "Set Initial Camera Position"}
              </AddWaypointButton>
              <TooltipText xOffset={0}>
                {waypoints.length > 0 
                  ? "Add a new waypoint at the current camera position and rotation" 
                  : "Set the first waypoint at the current camera position"}
              </TooltipText>
            </Tooltip>
          </Content>
        </Container>
      </Draggable>
      
      {editingWaypointIndex !== null && (
        <InteractionEditor
          waypointIndex={editingWaypointIndex}
          interactions={waypoints[editingWaypointIndex].interactions}
          waypoint={waypoints[editingWaypointIndex]}
          setInteractions={updateWaypointInteractions}
          setTriggerDistance={updateWaypointTriggerDistance}
          onClose={() => setEditingWaypointIndex(null)}
          scene={scene}
        />
      )}
    </>
  );
};

export default WaypointControls;
