/*
File: collisionSystem.ts
Description: Handles collision mesh creation and configuration
Created: 2024-02-19
*/

import { GenerateHTMLProps } from '../types';

export const generateCollisionSystem = (props: GenerateHTMLProps): string => {
  const { collisionMeshesData } = props;

  return `
    const collisionMeshesData = ${JSON.stringify(collisionMeshesData)};
    
    function createCollisionMeshes() {
      collisionMeshesData.forEach((data, index) => {
        let mesh;
        
        switch(data.meshType) {
          case 'cube':
            mesh = BABYLON.MeshBuilder.CreateBox(
              \`collisionMesh-\${index}\`,
              { size: 3 },
              scene
            );
            break;
          case 'sphere':
            mesh = BABYLON.MeshBuilder.CreateSphere(
              \`collisionMesh-\${index}\`,
              { diameter: 3 },
              scene
            );
            break;
          case 'custom':
            if (data.customMeshUrl) {
              BABYLON.SceneLoader.ImportMesh("", "", data.customMeshUrl, scene, function(meshes) {
                if (meshes.length > 0) {
                  mesh = meshes[0];
                  configureMesh(mesh, data);
                }
              });
              return;
            }
          case 'plane':
          default:
            mesh = BABYLON.MeshBuilder.CreatePlane(
              \`collisionMesh-\${index}\`,
              { size: 3 },
              scene
            );
        }
        
        configureMesh(mesh, data);
      });
    }

    function configureMesh(mesh, data) {
      mesh.position = new BABYLON.Vector3(
        data.position[0],
        data.position[1],
        data.position[2]
      );
      
      mesh.rotation = new BABYLON.Vector3(
        data.rotation[0],
        data.rotation[1],
        data.rotation[2]
      );
      
      mesh.scaling = new BABYLON.Vector3(
        data.scaling[0],
        data.scaling[1],
        data.scaling[2]
      );
      
      mesh.checkCollisions = true;
      mesh.isVisible = false;
    }

    createCollisionMeshes();
  `;
};
