// src/components/CollisionControls.tsx
// Previous imports and styled components remain unchanged...
import React, { useState, useRef, SetStateAction, Dispatch } from "react";
import Draggable from "react-draggable";
import * as BABYLON from "@babylonjs/core";
import { SerializedMeshData } from "../types/SceneTypes";
import { FiChevronDown, FiChevronUp, FiInfo } from "react-icons/fi";
import { BsSquareFill, BsCircleFill } from "react-icons/bs";
import { BiRectangle, BiMove, BiRotateRight, BiExpand, BiUnite, BiUpload } from "react-icons/bi";
import styled from "styled-components";

// Helper functions to convert between Vector3 and number arrays
const fromVector3 = (vector: BABYLON.Vector3): [number, number, number] => {
  return [vector.x, vector.y, vector.z];
};

const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 690px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 380px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Header = styled.div<{ $isCollapsed: boolean }>`
  padding: 16px;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  border-bottom-right-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;
  &:hover {
    color: #1e90ff;
  }
`;

const Content = styled.div<{ $isCollapsed: boolean }>`
  max-height: ${(props) => (props.$isCollapsed ? "0px" : "600px")};
  opacity: ${(props) => (props.$isCollapsed ? 0 : 1)};
  padding: ${(props) => (props.$isCollapsed ? "0 16px" : "16px")};
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const ControlsGroup = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  padding: 12px;
  background-color: #2c2c2c;
  border-radius: 5px;
  border: 1px solid #3d3d3d;
`;

const ControlButton = styled.button<{ $active?: boolean }>`
  padding: 8px;
  background-color: ${props => props.$active ? '#007BFF' : '#3c3c3c'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$active ? '#0056b3' : '#4c4c4c'};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
const MeshItem = styled.div<{ $isSelected?: boolean }>`
  padding: 12px;
  background-color: ${props => props.$isSelected ? '#3d3d3d' : '#2c2c2c'};
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.$isSelected ? '#007BFF' : 'transparent'};
  transition: all 0.2s ease;
  
  &:hover {
    border-color: #007BFF;
    background-color: #3d3d3d;
  }
`;

const MeshControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const GizmoControls = styled.div`
  display: flex;
  gap: 4px;
  margin-right: 8px;
  padding: 4px;
  background-color: #252525;
  border-radius: 4px;
`;

const GizmoButton = styled(ControlButton)`
  padding: 6px;
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const MeshLabel = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
`;

// ... rest of the styled components remain unchanged ...

const Button = styled.button<{ $variant?: "primary" | "danger" | "secondary" }>`
  padding: 8px 12px;
  background-color: ${(props) =>
    props.$variant === "primary"
      ? "#007BFF"
      : props.$variant === "danger"
      ? "#dc3545"
      : props.$variant === "secondary"
      ? "transparent"
      : "#555555"};
  color: ${(props) => (props.$variant === "secondary" ? "#ccc" : "white")};
  border: ${(props) =>
    props.$variant === "secondary" ? "1px solid #ccc" : "none"};
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  justify-content: center;
  width: 100%;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background-color: #555555;
    color: #888888;
    cursor: not-allowed;
    transform: none;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const Tooltip = styled.div<{ $show: boolean }>`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  opacity: ${props => props.$show ? 1 : 0};
  transition: opacity 0.2s ease;
  margin-bottom: 8px;
  z-index: 1000;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);
  }
`;

const HelpText = styled.div`
  padding: 12px;
  background-color: #3d3d3d;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 13px;
  color: #ddd;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #4d4d4d;
`;

const FileInput = styled.input`
  display: none;
`;

interface CollisionControlsProps {
  collisionMeshesData: SerializedMeshData[];
  setCollisionMeshesData: React.Dispatch<React.SetStateAction<SerializedMeshData[]>>;
  isCollisionEditMode: boolean;
  setIsCollisionEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  camera: BABYLON.Camera | null;
  setActiveControl: React.Dispatch<React.SetStateAction<"move" | "rotate" | "scale" | undefined>>;
  activeControl?: string;
  selectedMeshIndex: number | null;
  setSelectedMeshIndex: Dispatch<SetStateAction<number | null>>;
}

const CollisionControls: React.FC<CollisionControlsProps> = ({
  collisionMeshesData,
  setCollisionMeshesData,
  isCollisionEditMode,
  setIsCollisionEditMode,
  camera,
  setActiveControl,
  activeControl,
  selectedMeshIndex,
  setSelectedMeshIndex,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [selectedMeshType, setSelectedMeshType] = useState<"plane" | "cube" | "sphere" | "custom">("plane");
  const [tooltipText, setTooltipText] = useState<string>("");
  const nodeRef = React.useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // ... other functions remain unchanged ...

  const addCollisionMesh = () => {
    if (!camera) return;
    
    const cameraPosition = camera.position.clone().add(
      camera.getForwardRay().direction.scale(5)
    );
    const cameraRotation = camera.absoluteRotation.toEulerAngles();

    setCollisionMeshesData((prevData) => [
      ...prevData,
      {
        meshData: null,
        position: fromVector3(cameraPosition),
        rotation: fromVector3(cameraRotation),
        scaling: [1, 1, 1],
        meshType: selectedMeshType,
      },
    ]);
  };

  const removeCollisionMesh = (index: number) => {
    setCollisionMeshesData((prevData) => prevData.filter((_, i) => i !== index));
    if (selectedMeshIndex === index) {
      setSelectedMeshIndex(null);
    }
  };

  const toggleCollisionEditMode = () => {
    setIsCollisionEditMode(!isCollisionEditMode);
    if (!isCollisionEditMode) {
      setSelectedMeshIndex(null);
    }
  };

  const handleDragEvent = (e: React.DragEvent) => {
    e.stopPropagation();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      addCollisionMesh();
    }
  };

  const handleMeshClick = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedMeshIndex(index === selectedMeshIndex ? null : index);
  };

  const getMeshTypeIcon = (type: string | undefined) => {
    switch (type) {
      case 'cube':
        return <BsSquareFill />;
      case 'sphere':
        return <BsCircleFill />;
      case 'plane':
      default:
        return <BiRectangle />;
    }
  };
  return (
    <Draggable handle=".handle" nodeRef={nodeRef}>
      <Container
        ref={nodeRef}
        onDragEnter={handleDragEvent}
        onDragOver={handleDragEvent}
        onDragLeave={handleDragEvent}
        onDrop={handleDragEvent}
      >
        <Header 
          $isCollapsed={isCollapsed} 
          className="handle"
        >
          <Title>
            <BiUnite />
            Collision Meshes
          </Title>
          <ToggleButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
          </ToggleButton>
        </Header>
        <Content $isCollapsed={isCollapsed}>
          <ButtonGroup>
            <Button
              onClick={toggleCollisionEditMode}
              $variant={isCollisionEditMode ? "danger" : "primary"}
            >
              {isCollisionEditMode
                ? "Exit Collision Edit Mode"
                : "Enter Collision Edit Mode"}
            </Button>
          </ButtonGroup>

          {isCollisionEditMode && (
            <>
              <HelpText>
                <FiInfo size={16} />
                Click on a mesh to edit it with gizmos. Use the toolbar to add new meshes.
              </HelpText>

              <ControlsGroup>
                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Create Plane (Default)")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedMeshType === "plane"}
                    onClick={() => setSelectedMeshType("plane")}
                  >
                    <BiRectangle />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Create Plane (Default)"}>
                    Create Plane (Default)
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Create Cube")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedMeshType === "cube"}
                    onClick={() => setSelectedMeshType("cube")}
                  >
                    <BsSquareFill />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Create Cube"}>
                    Create Cube
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Create Sphere")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton
                    $active={selectedMeshType === "sphere"}
                    onClick={() => setSelectedMeshType("sphere")}
                  >
                    <BsCircleFill />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Create Sphere"}>
                    Create Sphere
                  </Tooltip>
                </TooltipContainer>

                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Coming Soon")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <ControlButton disabled>
                    <BiUpload />
                  </ControlButton>
                  <Tooltip $show={tooltipText === "Coming Soon"}>
                    Coming Soon
                  </Tooltip>
                </TooltipContainer>

                <FileInput
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  accept=".obj,.glb,.gltf"
                  disabled
                />

                <Button
                  onClick={addCollisionMesh}
                  $variant="primary"
                >
                  <BiUnite /> Add Mesh
                </Button>
              </ControlsGroup>
            </>
          )}

          {isCollisionEditMode && collisionMeshesData.map((data, index) => (
            <MeshItem 
              key={index}
              onClick={(e) => handleMeshClick(index, e)}
              $isSelected={selectedMeshIndex === index}
            >
              <MeshLabel>
                {getMeshTypeIcon(data.meshType)}
                Collision Mesh {index + 1}
              </MeshLabel>
              <MeshControls>
                {selectedMeshIndex === index && (
                  <GizmoControls>
                    <TooltipContainer 
                      onMouseEnter={() => setTooltipText("Move Tool")}
                      onMouseLeave={() => setTooltipText("")}
                    >
                      <GizmoButton
                        $active={activeControl === "move"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveControl("move");
                        }}
                      >
                        <BiMove />
                      </GizmoButton>
                      <Tooltip $show={tooltipText === "Move Tool"}>
                        Move Tool (G)
                      </Tooltip>
                    </TooltipContainer>

                    <TooltipContainer 
                      onMouseEnter={() => setTooltipText("Rotate Tool")}
                      onMouseLeave={() => setTooltipText("")}
                    >
                      <GizmoButton
                        $active={activeControl === "rotate"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveControl("rotate");
                        }}
                      >
                        <BiRotateRight />
                      </GizmoButton>
                      <Tooltip $show={tooltipText === "Rotate Tool"}>
                        Rotate Tool (R)
                      </Tooltip>
                    </TooltipContainer>

                    <TooltipContainer 
                      onMouseEnter={() => setTooltipText("Scale Tool")}
                      onMouseLeave={() => setTooltipText("")}
                    >
                      <GizmoButton
                        $active={activeControl === "scale"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveControl("scale");
                        }}
                      >
                        <BiExpand />
                      </GizmoButton>
                      <Tooltip $show={tooltipText === "Scale Tool"}>
                        Scale Tool (S)
                      </Tooltip>
                    </TooltipContainer>
                  </GizmoControls>
                )}
                <TooltipContainer 
                  onMouseEnter={() => setTooltipText("Remove Mesh")}
                  onMouseLeave={() => setTooltipText("")}
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeCollisionMesh(index);
                    }}
                    $variant="danger"
                  >
                    Remove
                  </Button>
                  <Tooltip $show={tooltipText === "Remove Mesh"}>
                    Remove this collision mesh
                  </Tooltip>
                </TooltipContainer>
              </MeshControls>
            </MeshItem>
          ))}

          {isCollisionEditMode && collisionMeshesData.length === 0 && (
            <HelpText style={{ textAlign: 'center', justifyContent: 'center' }}>
              No collision meshes added yet. Use the toolbar above to add meshes.
            </HelpText>
          )}

          {!isCollisionEditMode && collisionMeshesData.length > 0 && (
            <HelpText style={{ textAlign: 'center', justifyContent: 'center' }}>
              Enter edit mode to modify collision meshes
            </HelpText>
          )}
        </Content>
      </Container>
    </Draggable>
  );
};

export default CollisionControls;
