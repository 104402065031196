import React, { useState } from 'react';
import ErrorBoundary from './utils/ErrorBoundary';
import { StripeProvider } from './contexts/StripeContext';
import AppRoutes from './routes/Routes';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import Preloader from './components/Preloader';

// Wrapper component to access location
const AppContent: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const location = useLocation();
  
  // Check if current path matches SplatView pattern (/:displayNameSlug with optional sceneId query param)
  const isSplatViewPage = /^\/[^/]+(\?.*)?$/.test(location.pathname);

  return (
    <>
      {/* Only show preloader if not on SplatView page */}
      
{/*       {!isSplatViewPage && <Preloader isLoading={isLoading} progress={0}/>} */}
      
      <div className="app-container">
        <AppRoutes />
        <ToastContainer
          className="custom-toast-container"
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
};

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Handle completion of Stripe loading
  const handleStripeLoadComplete = (error?: string) => {
    // If there was an error, we might want to show it in a toast
    if (error) {
      console.error('Stripe loading error:', error);
      // Continue loading the app despite Stripe error
    }
    setIsLoading(false);
  };

  return (
    <ErrorBoundary>
      <AuthProvider>
        <StripeProvider onLoadComplete={handleStripeLoadComplete}>
          <BrowserRouter>
            <AppContent isLoading={isLoading} />
          </BrowserRouter>
        </StripeProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;

/**
 * Changes made:
 * 1. Added 'custom-toast-container' className to ToastContainer to allow custom z-index styling
 * 2. Maintained all previous toast configuration
 * 3. Added conditional rendering of Preloader based on route
 * Date: 2024-02-07
 */
