/*
File: src/pages/AccountSettings.tsx
Description: This file contains the Manage Subscription page component.
Changes: 2024-01-09 - Modified to render as overlay instead of full page replacement
*/

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiCreditCard, FiCalendar, FiBox, FiAlertTriangle } from 'react-icons/fi';
import { useSubscription } from '../hooks/useSubscription';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.95);
  z-index: 100000;
  overflow-y: auto;
`;

const Container = styled.div`
  margin: 40px auto;
  max-width: 800px;
  padding: 20px;
  color: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  flex-grow: 1;
  text-align: center;
`;

const Card = styled.div`
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: #4CAF50;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled.span`
  opacity: 0.8;
`;

const Value = styled.span<{ $status?: string }>`
  font-weight: bold;
  color: ${props => {
    switch (props.$status) {
      case 'active':
        return '#4CAF50';
      case 'past_due':
        return '#FFC107';
      case 'canceled':
        return '#f44336';
      default:
        return 'inherit';
    }
  }};
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  margin-top: 16px;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }

  &.cancel {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }
  }
`;

const Alert = styled.div`
  background-color: #dc3545;
  color: white;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccountSettings: React.FC = () => {
  const { userProfile } = useAuth();
  const navigate = useNavigate();
  const { redirectToPortal, isProcessing, error } = useSubscription();
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  useEffect(() => {
    if (!userProfile?.isPro) {
      navigate('/');
    }
  }, [userProfile, navigate]);

  const handleUpdatePayment = async () => {
    try {
      setIsLoadingPortal(true);
      await redirectToPortal();
    } catch (error) {
      console.error('Error redirecting to portal:', error);
    } finally {
      setIsLoadingPortal(false);
    }
  };

  const formatDate = (date: Date | null | undefined) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const formatStorageSize = (bytes: number) => {
    const gigabytes = bytes / (1024 * 1024 * 1024);
    return `${gigabytes.toFixed(1)} GB`;
  };

  if (!userProfile) {
    return null;
  }

  return (
    <Overlay>
    <Container>
      <Header>
        <BackButton onClick={() => navigate('/')}>
          <FiArrowLeft />
          Back to Dashboard
        </BackButton>
        <Title>Account Settings</Title>
      </Header>

      {error && (
        <Alert>
          <FiAlertTriangle />
          {error.message}
        </Alert>
      )}

      <Card>
        <CardTitle>
          <FiBox />
          Subscription Plan
        </CardTitle>
        <InfoRow>
          <Label>Current Plan</Label>
          <Value $status={userProfile.subscriptionStatus}>
            {userProfile.proTier ? userProfile.proTier.charAt(0).toUpperCase() + userProfile.proTier.slice(1) : 'Free'}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label>Status</Label>
          <Value $status={userProfile.subscriptionStatus}>
            {(userProfile.subscriptionStatus?.charAt(0).toUpperCase() || '') + 
             (userProfile.subscriptionStatus?.slice(1) || '')}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label>Storage Used</Label>
          <Value>
            {formatStorageSize(userProfile.storageUsed)} / 
            {(userProfile && userProfile.maxStorageBytes > 0) ? formatStorageSize(userProfile.maxStorageBytes) : ' Unlimited'}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label>Splats Used</Label>
          <Value>
            {userProfile.splats.length} / {userProfile.maxSplats > 0 ? userProfile.maxSplats : ' Unlimited'}
          </Value>
        </InfoRow>
        <Button 
          onClick={handleUpdatePayment}
          disabled={isLoadingPortal || isProcessing}
        >
          {isLoadingPortal ? 'Redirecting...' : 'Manage Subscription'}
        </Button>
      </Card>

    </Container>
    </Overlay>
  );
};

export default AccountSettings;
