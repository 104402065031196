import { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { functions } from '../utils/FirebaseConfig';
import { trackProEventTyped, ProEventProperties } from '../utils/AnalyticsMiddleware';
import { STRIPE_CONFIG } from '../utils/StripeConfig';

interface CheckoutSessionResponse {
  sessionId: string;
}

interface PortalSessionResponse {
  url: string;
}

type PlanType = keyof typeof STRIPE_CONFIG.PLANS;

export const useSubscription = () => {
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const getPlanTypeFromPriceId = (priceId: string): PlanType | undefined => {
    const planEntry = Object.entries(STRIPE_CONFIG.PLANS).find(
      ([_, plan]) => plan.priceId === priceId
    );
    console.log('=== Price ID Lookup ===');
    console.log('Looking up plan for price ID:', priceId);
    console.log('Found plan:', planEntry ? planEntry[0] : 'Not found');
    return planEntry?.[0] as PlanType;
  };

  const startCheckout = useCallback(async (priceId: string) => {
    console.log('=== Starting Checkout ===');
    console.log('Received price ID:', priceId);
    console.log('Current user:', currentUser?.uid);

    if (!currentUser) {
      setError(new Error('Must be logged in to upgrade'));
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const planType = getPlanTypeFromPriceId(priceId);
      console.log('Resolved plan type:', planType);
      console.log('Starting checkout process for plan:', planType, 'with priceId:', priceId);
      
      const eventProps: ProEventProperties = {
        planType,
        priceId,
        userId: currentUser.uid
      };

      trackProEventTyped('CHECKOUT_STARTED', eventProps);

      if (!priceId) {
        throw new Error('Price ID is required for checkout');
      }

      // Initialize Stripe
      if (!window.Stripe) {
        throw new Error('Stripe.js not loaded');
      }
      const stripe = window.Stripe(STRIPE_CONFIG.PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error('Failed to initialize Stripe');
      }

      // Get the ID token for authentication
      const idToken = await currentUser.getIdToken();

      // Make HTTP request to the function using hardcoded URL from StripeConfig
      console.log('Making request to:', STRIPE_CONFIG.API.CHECKOUT);
      console.log('Request payload:', { 
        priceId,
        planType 
      });
      
      const response = await fetch(STRIPE_CONFIG.API.CHECKOUT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          priceId,
          planType 
        })
      });

      // Log response details for debugging
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      const contentType = response.headers.get('content-type');
      if (!response.ok) {
        let errorMessage = '';
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          errorMessage = errorData.error || 'Failed to create checkout session';
        } else {
          const textResponse = await response.text();
          console.error('Non-JSON error response:', textResponse);
          errorMessage = 'Server returned an invalid response';
        }
        throw new Error(errorMessage);
      }

      if (!contentType || !contentType.includes('application/json')) {
        const textResponse = await response.text();
        console.error('Unexpected response type:', contentType);
        console.error('Response body:', textResponse);
        throw new Error('Server returned an invalid response type');
      }

      const result = await response.json();
      console.log('Checkout session response:', result);

      if (!result.sessionId) {
        throw new Error('No session ID returned from checkout creation');
      }

      // Track successful checkout session creation
      trackProEventTyped('CHECKOUT_SESSION_CREATED', {
        ...eventProps,
        sessionId: result.sessionId
      });

      // Redirect to Stripe Checkout using the Stripe instance
      const { error } = await stripe.redirectToCheckout({
        sessionId: result.sessionId
      });

      if (error) {
        throw error;
      }
    } catch (err) {
      console.error('Checkout error details:', {
        error: err,
        errorMessage: err instanceof Error ? err.message : 'Unknown error'
      });
      
      const planType = getPlanTypeFromPriceId(priceId);
      trackProEventTyped('CHECKOUT_FAILED', {
        planType,
        priceId,
        userId: currentUser.uid,
        error: err instanceof Error ? err.message : 'Unknown error'
      });

      setError(err instanceof Error ? err : new Error('Failed to start checkout'));
    } finally {
      setIsProcessing(false);
    }
  }, [currentUser]);

  const redirectToPortal = useCallback(async () => {
    if (!currentUser) {
      setError(new Error('Must be logged in to manage subscription'));
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      console.log('Starting portal redirect...');
      trackProEventTyped('PORTAL_REDIRECT_STARTED', {
        userId: currentUser.uid
      });

      // Get the ID token for authentication
      const idToken = await currentUser.getIdToken();

      // Make HTTP request to the function using hardcoded URL from StripeConfig
      console.log('Making portal request to:', STRIPE_CONFIG.API.PORTAL);
      
      const response = await fetch(STRIPE_CONFIG.API.PORTAL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
          'Accept': 'application/json'
        }
      });

      // Log response details for debugging
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));

      const contentType = response.headers.get('content-type');
      if (!response.ok) {
        let errorMessage = '';
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          errorMessage = errorData.error || 'Failed to create portal session';
        } else {
          const textResponse = await response.text();
          console.error('Non-JSON error response:', textResponse);
          errorMessage = 'Server returned an invalid response';
        }
        throw new Error(errorMessage);
      }

      if (!contentType || !contentType.includes('application/json')) {
        const textResponse = await response.text();
        console.error('Unexpected response type:', contentType);
        console.error('Response body:', textResponse);
        throw new Error('Server returned an invalid response type');
      }

      const result = await response.json();
      console.log('Portal session created:', result);

      if (!result.url) {
        throw new Error('No portal URL returned');
      }

      trackProEventTyped('PORTAL_REDIRECT_SUCCESS', {
        userId: currentUser.uid
      });
      window.location.href = result.url;
    } catch (err) {
      console.error('Portal redirect error:', err);
      
      trackProEventTyped('PORTAL_REDIRECT_FAILED', {
        userId: currentUser.uid,
        error: err instanceof Error ? err.message : 'Unknown error'
      });

      if (err instanceof Error) {
        if (err.message.includes('customer not found')) {
          setError(new Error('No active subscription found. Please upgrade to access the billing portal.'));
        } else {
          setError(err);
        }
      } else {
        setError(new Error('Failed to access portal'));
      }
    } finally {
      setIsProcessing(false);
    }
  }, [currentUser]);

  return {
    startCheckout,
    redirectToPortal,
    isProcessing,
    error
  };
};
