/*
File: LightVisualizer.tsx
Description: Component for visualizing and manipulating lights in the 3D scene
Created: 2024-01-15
*/

import React, { useEffect, useRef } from "react";
import * as BABYLON from "@babylonjs/core";
import { SceneLight } from "../../types/SceneTypes";

interface LightVisualizerProps {
  scene: BABYLON.Scene;
  lights: SceneLight[];
  setLights: React.Dispatch<React.SetStateAction<SceneLight[]>>;
  isLightEditMode: boolean;
  selectedLightIndex: number | null;
  activeControl?: "move" | "rotate" | "scale";
}

const LightVisualizer: React.FC<LightVisualizerProps> = ({
  scene,
  lights,
  setLights,
  isLightEditMode,
  selectedLightIndex,
  activeControl = "move"
}) => {
  const gizmoManagerRef = useRef<BABYLON.GizmoManager | null>(null);
  const lightMeshesRef = useRef<BABYLON.Mesh[]>([]);
  const isInitializedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!scene || !scene.getEngine()) {
      isInitializedRef.current = false;
      return;
    }

    // Initialize GizmoManager
    const initializeGizmoManager = () => {
      if (!isInitializedRef.current && scene.isReady()) {
        const gizmoManager = new BABYLON.GizmoManager(scene);
        gizmoManager.positionGizmoEnabled = activeControl === "move";
        gizmoManager.rotationGizmoEnabled = activeControl === "rotate";
        gizmoManager.scaleGizmoEnabled = false;
        gizmoManager.usePointerToAttachGizmos = false;
        gizmoManager.attachableMeshes = [];
        gizmoManagerRef.current = gizmoManager;
        isInitializedRef.current = true;
      }
    };

    if (!isInitializedRef.current) {
      if (scene.isReady()) {
        initializeGizmoManager();
      } else {
        scene.onReadyObservable.addOnce(initializeGizmoManager);
      }
    }

    // Update Gizmo Controls
    if (gizmoManagerRef.current) {
      gizmoManagerRef.current.positionGizmoEnabled = activeControl === "move";
      gizmoManagerRef.current.rotationGizmoEnabled = activeControl === "rotate";
    }

    // Create visual representation for lights
    const createLightMesh = (light: SceneLight, index: number): BABYLON.Mesh => {
      // Create a small sphere to represent the light
      const mesh = BABYLON.MeshBuilder.CreateSphere(
        `lightMesh-${index}`,
        { diameter: 0.5 },
        scene
      );
      
      mesh.position = new BABYLON.Vector3(
        light.position.x,
        light.position.y,
        light.position.z
      );
      mesh.isPickable = true;

      // Create material for the light mesh
      const material = new BABYLON.StandardMaterial(`lightMaterial-${index}`, scene);
      material.emissiveColor = BABYLON.Color3.FromHexString(light.color);
      material.disableLighting = true;
      mesh.material = material;

      // Add click handling
      if (!mesh.actionManager) {
        mesh.actionManager = new BABYLON.ActionManager(scene);
      }

      return mesh;
    };

    // Update or create light meshes
    if (lightMeshesRef.current.length !== lights.length) {
      // Clean up existing meshes
      lightMeshesRef.current.forEach((mesh) => {
        if (mesh.material) {
          mesh.material.dispose();
        }
        mesh.dispose();
      });

      // Create new meshes
      lightMeshesRef.current = lights.map((light, index) => 
        createLightMesh(light, index)
      );
    }

    // Update light mesh properties
    lightMeshesRef.current.forEach((mesh, index) => {
      const light = lights[index];
      mesh.isVisible = isLightEditMode;
      
      // Update material color based on selection state
      const material = mesh.material as BABYLON.StandardMaterial;
      if (material) {
        material.emissiveColor = selectedLightIndex === index
          ? BABYLON.Color3.FromHexString("#00FF00")
          : BABYLON.Color3.FromHexString(light.color);
      }

      // Update position observer
      if (isLightEditMode) {
        mesh.onAfterWorldMatrixUpdateObservable.clear();
        mesh.onAfterWorldMatrixUpdateObservable.add(() => {
          setLights(prevLights => {
            const newLights = [...prevLights];
            newLights[index] = {
              ...newLights[index],
              position: {
                x: mesh.position.x,
                y: mesh.position.y,
                z: mesh.position.z
              }
            };
            return newLights;
          });
        });
      }
    });

    // Attach gizmo to selected light mesh
    if (isLightEditMode && selectedLightIndex !== null && selectedLightIndex < lightMeshesRef.current.length) {
      gizmoManagerRef.current?.attachToMesh(lightMeshesRef.current[selectedLightIndex]);
    } else {
      gizmoManagerRef.current?.attachToMesh(null);
    }

    return () => {
      lightMeshesRef.current.forEach(mesh => {
        mesh.onAfterWorldMatrixUpdateObservable.clear();
      });
    };
  }, [scene, lights, isLightEditMode, selectedLightIndex, activeControl, setLights]);

  return null;
};

export default LightVisualizer;
