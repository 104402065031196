// Created: 2024-02-08
// Purpose: Help page component providing user documentation and support resources
// Features:
// - Getting started guide
// - FAQ section
// - Usage instructions
// - Support contact information
// Changes:
// - Added toolbar for consistent navigation
// - Updated styling with white background
// - Added Discovery link to toolbar
// - Made content scrollable

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import config from '../config';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  background-color: #f4f4f4;
  overflow: hidden;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  margin-right: 40px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #666;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 32px;
  color: #333;
  margin-bottom: 24px;
`;

const Section = styled.section`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #444;
  margin-bottom: 16px;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 8px;
`;

const SubsectionTitle = styled.h3`
  font-size: 20px;
  color: #555;
  margin: 24px 0 12px;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  margin: 0 0 16px 20px;
  color: #666;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const KeyboardShortcut = styled.kbd`
  background-color: #f8f9fa;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding: 2px 6px;
  font-family: monospace;
  font-size: 14px;
  color: #374151;
`;

const CodeBlock = styled.pre`
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
  overflow-x: auto;
  font-family: monospace;
  font-size: 14px;
  color: #374151;
`;

const Note = styled.div`
  background-color: #e8f5e9;
  border-left: 4px solid #4CAF50;
  padding: 16px;
  margin: 16px 0;
  border-radius: 0 4px 4px 0;
`;

const Warning = styled.div`
  background-color: #fff3e0;
  border-left: 4px solid #ff9800;
  padding: 16px;
  margin: 16px 0;
  border-radius: 0 4px 4px 0;
`;

const Help: React.FC = () => {
  return (
    <PageContainer>
      <Toolbar>
        <Logo to="/">StorySplat</Logo>
        <NavLinks>
          <NavLink to="/editor">Editor</NavLink>
          <NavLink to="/discover">Discover</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/help">Help</NavLink>
          {config.stripeIntegrationEnabled && (
            <NavLink to="/pricing">Pricing</NavLink>
          )}
        </NavLinks>
      </Toolbar>

      <ScrollContainer>
        <ContentContainer>
          <Title>StorySplat Documentation</Title>
          
          <Section>
            <SectionTitle>Introduction to StorySplat</SectionTitle>
            <Text>
              StorySplat is a powerful platform for creating and sharing interactive 3D stories using Gaussian Splat technology. 
              This documentation will guide you through every aspect of creating, customizing, and sharing your 3D stories.
            </Text>
            <Note>
              Gaussian Splats are a modern way to represent 3D scenes using points in space. They offer high-quality visuals 
              while being more efficient than traditional 3D meshes.
            </Note>
          </Section>

          <Section>
            <SectionTitle>Understanding Gaussian Splats</SectionTitle>
            <Text>
              Before diving into creation, it's helpful to understand what Gaussian Splats are and how they work:
            </Text>
            <List>
              <ListItem>
                <strong>What are Gaussian Splats?</strong> They're a type of 3D point cloud where each point has properties 
                like position, size, orientation, and color that blend together to create smooth, high-quality 3D scenes.
              </ListItem>
              <ListItem>
                <strong>Advantages:</strong> Smaller file sizes, faster loading times, and better visual quality compared to 
                traditional 3D formats.
              </ListItem>
              <ListItem>
                <strong>Use Cases:</strong> Perfect for photogrammetry, 3D scans, architectural visualization, and interactive 
                storytelling.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionTitle>Getting Started</SectionTitle>
            
            <SubsectionTitle>File Formats and Conversion</SubsectionTitle>
            <Text>
              StorySplat supports two main file formats:
            </Text>
            <List>
              <ListItem>
                <strong>.ply files:</strong> Standard 3D point cloud format, commonly exported from 3D scanning software
              </ListItem>
              <ListItem>
                <strong>.splat files:</strong> Optimized format specifically for gaussian splatting
              </ListItem>
            </List>
            <Note>
              We recommend converting .ply files to .splat format using our built-in converter for optimal performance.
            </Note>

            <SubsectionTitle>Loading Your First Scene</SubsectionTitle>
            <Text>
              There are multiple ways to get your 3D content into StorySplat:
            </Text>
            <List>
              <ListItem>
                <strong>Drag and Drop:</strong> Simply drag your .ply or .splat file into the editor
              </ListItem>
              <ListItem>
                <strong>File Menu:</strong> Use the File → Open option to select your file
              </ListItem>
              <ListItem>
                <strong>URL Import:</strong> Load scenes directly from a URL (must be CORS-enabled)
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionTitle>Creating Interactive Stories</SectionTitle>
            
            <SubsectionTitle>Working with Waypoints</SubsectionTitle>
            <Text>
              Waypoints are the building blocks of your story's journey:
            </Text>
            <List>
              <ListItem>
                <strong>Creating Waypoints:</strong> Position your camera and click the "+" button in the Waypoints panel
              </ListItem>
              <ListItem>
                <strong>Editing Waypoints:</strong> Adjust camera position, timing, and transitions
              </ListItem>
              <ListItem>
                <strong>Adding Content:</strong> Each waypoint can include:
                <ul>
                  <li>Text descriptions</li>
                  <li>Spatial Audio</li>
                </ul>
              </ListItem>
            </List>

            <SubsectionTitle>Interactive Elements</SubsectionTitle>
            <Text>
              Make your stories more engaging with interactive features:
            </Text>
            <List>
              <ListItem>
                <strong>Hotspots:</strong> Create clickable points of interest
                <ul>
                  <li>Add text/image popups</li>
                  <li>Link to external content</li>
                  <li>Trigger custom actions</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Collision Areas:</strong> Define boundaries and restricted areas
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionTitle>Camera Controls and Navigation</SectionTitle>
            
            <SubsectionTitle>Navigation Modes</SubsectionTitle>
            <List>
              <ListItem>
                <strong>Tour Mode:</strong> Automated camera movement following waypoints
                <ul>
                  <li>Perfect for guided experiences</li>
                  <li>Customizable transition timing</li>
                  <li>Optional auto-play feature</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Explore Mode:</strong> Free camera movement
                <ul>
                  <li>WASD+QE keys for movement</li>
                  <li>Mouse for looking around</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Hybrid Mode:</strong> Combines guided tour with free exploration
              </ListItem>
            </List>

     {/*        <SubsectionTitle>Keyboard Shortcuts</SubsectionTitle>
            <List>
              <ListItem><KeyboardShortcut>W</KeyboardShortcut> <KeyboardShortcut>A</KeyboardShortcut> <KeyboardShortcut>S</KeyboardShortcut> <KeyboardShortcut>D</KeyboardShortcut> - Movement</ListItem>
              <ListItem><KeyboardShortcut>Space</KeyboardShortcut> - Toggle tour play/pause</ListItem>
              <ListItem><KeyboardShortcut>Esc</KeyboardShortcut> - Exit current mode</ListItem>
              <ListItem><KeyboardShortcut>Ctrl</KeyboardShortcut> + <KeyboardShortcut>S</KeyboardShortcut> - Quick save</ListItem>
            </List> */}
          </Section>

          <Section>
            <SectionTitle>Scene Customization</SectionTitle>
            
            <SubsectionTitle>Visual Settings</SubsectionTitle>
            <List>
              <ListItem>
                <strong>Background:</strong> Customize the scene background color or gradient
              </ListItem>
              <ListItem>
                <strong>Lighting:</strong> Adjust scene lighting and shadows, this only works for meshes, not splats (Working on Relighting Splats)
              </ListItem>
              <ListItem>
                <strong>UI Colors:</strong> Customize interface elements to match your brand
              </ListItem>
            </List>
            </Section>

          <Section>
            <SectionTitle>Sharing and Export</SectionTitle>
            
            <SubsectionTitle>Export Options</SubsectionTitle>
            <List>
              <ListItem>
                <strong>HTML Export:</strong> Create standalone web pages
                <ul>
                  <li>Complete with all interactions and animations</li>
                  <li>Embeddable in any website</li>
                  <li>Mobile-friendly responsive design</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Direct Link:</strong> Share via StorySplat platform
                <ul>
                  <li>Cloud Saves</li>
                  <li>Analytics tracking</li>
                </ul>
              </ListItem>
            </List>

            <SubsectionTitle>Embedding</SubsectionTitle>
            <Text>
              To embed your story in a website, copy the embed code from the user dashboard.
            </Text>
{/*             <CodeBlock>
{`<iframe
  src="https://storysplat.com/embed/your-story-id"
  width="100%"
  height="600px"
  frameborder="0"
  allowfullscreen
></iframe>`}
            </CodeBlock>*/}
          </Section> 

          <Section>
            <SectionTitle>Best Practices</SectionTitle>
            <List>
              <ListItem>
                <strong>Optimize Your Scenes:</strong>
                <ul>
                  <li>Convert to .splat format for better performance</li>
                  <li>Use appropriate quality settings for your target devices</li>
                  <li>Test on different devices and browsers</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Create Engaging Stories:</strong>
                <ul>
                  <li>Start with an interesting opening waypoint</li>
                  <li>Use hotspots to highlight important details</li>
                  <li>Keep transitions smooth and natural</li>
                  <li>Add context through descriptions and audio</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Performance Tips:</strong>
                <ul>
                  <li>Optimize scene size for web delivery</li>
                  <li>Use progressive loading for large scenes</li>
                  <li>Consider mobile users when setting quality levels</li>
                </ul>
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionTitle>Troubleshooting</SectionTitle>
            
            <SubsectionTitle>Common Issues</SubsectionTitle>
            <List>
              <ListItem>
                <strong>Scene Won't Load:</strong>
                <ul>
                  <li>Check file format compatibility</li>
                  <li>Verify file size limits</li>
                  <li>Ensure stable internet connection</li>
                </ul>
              </ListItem>
              <ListItem>
                <strong>Performance Issues:</strong>
                <ul>
                  <li>Reduce point count</li>
                  <li>Clear browser cache</li>
                </ul>
              </ListItem>
            </List>

            <Warning>
              If you encounter persistent issues, please contact support with your scene details and browser information.
            </Warning>
          </Section>

          <Section>
            <SectionTitle>Support Resources</SectionTitle>
            <List>
              <ListItem>Email: the@sonnycirasuolo.com</ListItem>
              <ListItem>Discord Community: Join for real-time help</ListItem>
              <ListItem>Example Gallery: Browse example stories</ListItem>
              <ListItem>Video Tutorials: Watch step-by-step guides</ListItem>
            </List>
          </Section>
        </ContentContainer>
      </ScrollContainer>
    </PageContainer>
  );
};

export default Help;
