import React, { Component, ErrorInfo } from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  padding: 20px;
  margin: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  border: 1px solid #dc3545;
  color: white;
`;

const ErrorHeading = styled.h2`
  color: #dc3545;
  margin-bottom: 15px;
`;

const ErrorMessage = styled.pre`
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 4px;
  overflow-x: auto;
  margin: 10px 0;
`;

const RetryButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    background-color: #45a049;
  }
`;

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorHeading>Something went wrong</ErrorHeading>
          <p>An error occurred while loading this component.</p>
          {this.state.error && (
            <ErrorMessage>
              {this.state.error.message}
            </ErrorMessage>
          )}
          <RetryButton onClick={this.handleRetry}>
            Try Again
          </RetryButton>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
