/*
File: CollisionVisualizer.tsx
Description: This component handles the visualization and manipulation of collision meshes in the 3D scene.
It manages the creation, updating, and interaction with collision meshes through a three-node transform hierarchy
(rotationNode -> scalingNode -> mesh) to ensure proper scaling and rotation functionality.

Last modified: 2024-01-09
Changes: Restored three-node transform hierarchy (rotationNode -> scalingNode -> mesh) to maintain proper
scaling and rotation functionality. Each collision mesh now has a dedicated rotation node and scaling node
to handle transformations independently.
*/

import React, { useEffect, useRef, Dispatch, SetStateAction } from "react";
import * as BABYLON from "@babylonjs/core";
import { SerializedMeshData } from "../types/SceneTypes";

interface CollisionVisualizerProps {
  scene: BABYLON.Scene;
  collisionMeshesData: SerializedMeshData[];
  setCollisionMeshesData: Dispatch<SetStateAction<SerializedMeshData[]>>;
  isCollisionEditMode: boolean;
  setCollisionMeshes: Dispatch<SetStateAction<BABYLON.Mesh[]>>;
  activeControl?: "move" | "rotate" | "scale";
  selectedMeshIndex: number | null;
  setSelectedMeshIndex: Dispatch<SetStateAction<number | null>>;
}

// Helper functions to convert between Vector3 and number arrays
const toVector3 = (arr: [number, number, number]): BABYLON.Vector3 => {
  return new BABYLON.Vector3(arr[0], arr[1], arr[2]);
};

const fromVector3 = (vector: BABYLON.Vector3): [number, number, number] => {
  return [vector.x, vector.y, vector.z];
};

const CollisionVisualizer: React.FC<CollisionVisualizerProps> = ({
  scene,
  collisionMeshesData,
  setCollisionMeshesData,
  isCollisionEditMode,
  setCollisionMeshes,
  activeControl = "move",
  selectedMeshIndex,
  setSelectedMeshIndex,
}) => {
  const meshesRef = useRef<BABYLON.Mesh[]>([]);
  const transformNodesRef = useRef<
    {
      rotationNode: BABYLON.TransformNode;
      scalingNode: BABYLON.TransformNode;
    }[]
  >([]);
  const rotationGizmoManagerRef = useRef<BABYLON.GizmoManager | null>(null);
  const scalingGizmoManagerRef = useRef<BABYLON.GizmoManager | null>(null);
  const isInitializedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!scene || !scene.getEngine()) {
      isInitializedRef.current = false;
      return;
    }

    // Initialize GizmoManagers
    const initializeGizmoManagers = () => {
      if (!isInitializedRef.current && scene.isReady()) {
        // Rotation GizmoManager
        const rotationGizmoManager = new BABYLON.GizmoManager(scene);
        rotationGizmoManager.positionGizmoEnabled = activeControl === "move";
        rotationGizmoManager.rotationGizmoEnabled = activeControl === "rotate";
        rotationGizmoManager.scaleGizmoEnabled = false;
        rotationGizmoManager.usePointerToAttachGizmos = false;
        rotationGizmoManager.attachableMeshes = [];
        rotationGizmoManagerRef.current = rotationGizmoManager;

        // Scaling GizmoManager
        const scalingGizmoManager = new BABYLON.GizmoManager(scene);
        scalingGizmoManager.positionGizmoEnabled = false;
        scalingGizmoManager.rotationGizmoEnabled = false;
        scalingGizmoManager.scaleGizmoEnabled = activeControl === "scale";
        scalingGizmoManager.usePointerToAttachGizmos = false;
        scalingGizmoManager.attachableMeshes = [];
        scalingGizmoManagerRef.current = scalingGizmoManager;

        isInitializedRef.current = true;
      }
    };

    if (!isInitializedRef.current) {
      if (scene.isReady()) {
        initializeGizmoManagers();
      } else {
        scene.onReadyObservable.addOnce(initializeGizmoManagers);
      }
    }

    // Update Gizmo Controls based on activeControl
    if (rotationGizmoManagerRef.current) {
      rotationGizmoManagerRef.current.positionGizmoEnabled = activeControl === "move";
      rotationGizmoManagerRef.current.rotationGizmoEnabled = activeControl === "rotate";
    }
    if (scalingGizmoManagerRef.current) {
      scalingGizmoManagerRef.current.scaleGizmoEnabled = activeControl === "scale";
    }

    // Function to create a mesh based on type
    const createMesh = (type: string, index: number): BABYLON.Mesh => {
      let mesh: BABYLON.Mesh;
      switch (type) {
        case "cube":
          mesh = BABYLON.MeshBuilder.CreateBox(
            `collisionMesh-${index}-cube`,
            { size: 3 },
            scene
          );
          break;
        case "sphere":
          mesh = BABYLON.MeshBuilder.CreateSphere(
            `collisionMesh-${index}-sphere`,
            { diameter: 3 },
            scene
          );
          break;
        case "plane":
        default:
          mesh = BABYLON.MeshBuilder.CreatePlane(
                `collisionMesh-${index}-plane`,
            { size: 3 },
            scene
          );
      }
      return mesh;
    };

    // Function to create a mesh and its transform nodes
    const createMeshAndTransformNodes = (
      data: SerializedMeshData,
      index: number
    ): BABYLON.Mesh => {
      // Create Rotation Node (handles position and rotation)
      const rotationNode = new BABYLON.TransformNode(
        `rotationNode-${index}`,
        scene
      );
      rotationNode.position = toVector3(data.position);
      rotationNode.rotation = toVector3(data.rotation);

      // Create Scaling Node (handles scaling)
      const scalingNode = new BABYLON.TransformNode(
        `scalingNode-${index}`,
        scene
      );
      scalingNode.parent = rotationNode;
      scalingNode.scaling = toVector3(data.scaling);

      // Store both nodes
      transformNodesRef.current[index] = { rotationNode, scalingNode };

      // Create Mesh
      const mesh = createMesh(data.meshType || "plane", index);
      mesh.parent = scalingNode;
      mesh.isVisible = isCollisionEditMode;
      mesh.checkCollisions = true;
      mesh.isPickable = true;

      // Create and setup material
      const material = new BABYLON.StandardMaterial(
        `collisionMaterial-${index}`,
        scene
      );
      material.backFaceCulling = false;
      material.diffuseColor = BABYLON.Color3.FromHexString("#FF0000");
      material.wireframe = false;
      mesh.material = material;

      // Setup mesh click handling
      if (!mesh.actionManager) {
        mesh.actionManager = new BABYLON.ActionManager(scene);
      }

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            if (isCollisionEditMode) {
              setSelectedMeshIndex(index);
            }
          }
        )
      );

      return mesh;
    };

    // Initial setup
    if (meshesRef.current.length !== collisionMeshesData.length) {
      // Clean up existing meshes and transform nodes
      meshesRef.current.forEach((mesh) => {
        if (mesh.material) {
          mesh.material.dispose();
        }
        mesh.dispose();
      });
      transformNodesRef.current.forEach(({ rotationNode, scalingNode }) => {
        rotationNode.dispose();
        scalingNode.dispose();
      });

      meshesRef.current = [];
      transformNodesRef.current = [];

      // Create new meshes with transform nodes
      meshesRef.current = collisionMeshesData.map((data, index) =>
        createMeshAndTransformNodes(data, index)
      );
    }

    // Setup mesh update handling
    if (isCollisionEditMode && isInitializedRef.current) {
      transformNodesRef.current.forEach(({ rotationNode, scalingNode }, index) => {
        const updateMeshData = () => {
          setCollisionMeshesData((prevData) => {
            const newData = [...prevData];
            newData[index] = {
              ...prevData[index],
              position: fromVector3(rotationNode.position),
              rotation: fromVector3(rotationNode.rotation),
              scaling: fromVector3(scalingNode.scaling),
            };
            return newData;
          });
        };

        // Remove existing observers to prevent duplicates
        rotationNode.onAfterWorldMatrixUpdateObservable.clear();
        scalingNode.onAfterWorldMatrixUpdateObservable.clear();

        // Add observers for transform updates
        rotationNode.onAfterWorldMatrixUpdateObservable.add(updateMeshData);
        scalingNode.onAfterWorldMatrixUpdateObservable.add(updateMeshData);
      });
    }

    // Cleanup function
    return () => {
      transformNodesRef.current.forEach(({ rotationNode, scalingNode }) => {
        rotationNode.onAfterWorldMatrixUpdateObservable.clear();
        scalingNode.onAfterWorldMatrixUpdateObservable.clear();
      });
    };
  }, [
    scene,
    collisionMeshesData,
    isCollisionEditMode,
    setCollisionMeshesData,
    activeControl,
    selectedMeshIndex,
  ]);

  // Handle gizmo attachment based on selectedMeshIndex
  useEffect(() => {
    if (
      !isCollisionEditMode ||
      !rotationGizmoManagerRef.current ||
      !scalingGizmoManagerRef.current ||
      selectedMeshIndex === null ||
      selectedMeshIndex >= transformNodesRef.current.length
    ) {
      rotationGizmoManagerRef.current?.attachToNode(null);
      scalingGizmoManagerRef.current?.attachToNode(null);
      return;
    }

    const { rotationNode, scalingNode } = transformNodesRef.current[selectedMeshIndex];

    if (activeControl === "move" || activeControl === "rotate") {
      rotationGizmoManagerRef.current.attachToNode(rotationNode);
      scalingGizmoManagerRef.current.attachToNode(null);
    } else if (activeControl === "scale") {
      rotationGizmoManagerRef.current.attachToNode(null);
      scalingGizmoManagerRef.current.attachToNode(scalingNode);
    }

    return () => {
      rotationGizmoManagerRef.current?.attachToNode(null);
      scalingGizmoManagerRef.current?.attachToNode(null);
    };
  }, [selectedMeshIndex, isCollisionEditMode, activeControl]);

  // Update mesh visibility and save data when exiting edit mode
  useEffect(() => {
    meshesRef.current.forEach((mesh, index) => {
      mesh.isVisible = isCollisionEditMode;
      
      // Update material for selection state
      const material = mesh.material as BABYLON.StandardMaterial;
      if (material) {
        material.diffuseColor = selectedMeshIndex === index
          ? BABYLON.Color3.FromHexString("#00FF00")
          : BABYLON.Color3.FromHexString("#FF0000");
      }
    });

    if (!isCollisionEditMode) {
      // Update collisionMeshesData with the latest transformations
      setCollisionMeshesData((prevData) =>
        meshesRef.current.map((mesh, index) => {
          const { rotationNode, scalingNode } = transformNodesRef.current[index];
          return {
            ...prevData[index],
            position: fromVector3(rotationNode.position),
            rotation: fromVector3(rotationNode.rotation),
            scaling: fromVector3(scalingNode.scaling),
          };
        })
      );
      // Update collision meshes
      setCollisionMeshes(meshesRef.current);
    }
  }, [isCollisionEditMode, selectedMeshIndex, setCollisionMeshesData, setCollisionMeshes]);

  return null;
};

export default CollisionVisualizer;
