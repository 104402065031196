/*
File: src/pages/Examples.tsx
Description: This file contains the Examples page component that displays featured examples.
The component has been modified to render as an overlay on top of the main scene instead
of a separate route to prevent scene unloading during navigation.
Changes: 2024-01-09 - Modified to render as overlay instead of full page replacement
*/

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiExternalLink } from 'react-icons/fi';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.95);
  z-index: 100000;
  overflow-y: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 40px 20px;
  color: white;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 0 0 20px 0;
  flex-grow: 1;
  font-weight: 600;
`;

const ExamplesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 0 20px 40px 20px;
`;

const ExampleCard = styled.a`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid #3c3c3c;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border-color: #4caf50;
  }
`;

const ExampleTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExampleDescription = styled.p`
  margin: 0;
  color: #b0b0b0;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #4caf50;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  font-weight: 500;

  &:hover {
    background-color: #45a049;
  }
`;

const ExamplesPage: React.FC = () => {
  return (
    <Overlay>
      <Container>
        <ContentWrapper>
          <Header>
            <BackButton to="/">
              <FiArrowLeft />
              Close
            </BackButton>
          </Header>
          
          <Title>Featured Examples</Title>
          
          <ExamplesGrid>
            <ExampleCard 
              href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FHead%20of%20a%20Warrior.html?alt=media&token=6a98a830-3da4-4f4a-ad36-032e80ae6ba0" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExampleTitle>
                Head of A Warrior - Picasso, MoMA
                <FiExternalLink size={16} />
              </ExampleTitle>
              <ExampleDescription>
                Experience Picasso's iconic sculpture in immersive 3D, capturing the raw emotion and geometric complexity of this masterpiece.
              </ExampleDescription>
            </ExampleCard>

            <ExampleCard 
              href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FSanta%20Maria%20Novella.html?alt=media&token=77b7fcc5-0ab5-472a-9767-a4a68a59eab5" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExampleTitle>
                Santa Maria Novella - Florence, Italy
                <FiExternalLink size={16} />
              </ExampleTitle>
              <ExampleDescription>
                Explore the architectural magnificence of this historic basilica in Florence, showcasing stunning Gothic and Renaissance design elements.
              </ExampleDescription>
            </ExampleCard>

         
            <ExampleCard 
              href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FDonatello%20Sculpture.html?alt=media&token=e705c0bb-222c-4c6b-acbc-c3de9d1f6c43" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExampleTitle>
                Donatello Sculpture - Florence, Italy
                <FiExternalLink size={16} />
              </ExampleTitle>
              <ExampleDescription>
                Discover the artistry of Donatello's sculpture in 3D, showcasing the masterful craftsmanship and lifelike details of this Renaissance masterpiece.
              </ExampleDescription>
            </ExampleCard>

            <ExampleCard 
              href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FHead%20of%20Alexander%20the%20Great.html?alt=media&token=34c49c6a-19eb-4197-9525-b78b12c55690" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExampleTitle>
                Alexander the Great - Athens, Greece
                <FiExternalLink size={16} />
              </ExampleTitle>
              <ExampleDescription>
                Experience the grandeur of Alexander the Great's iconic sculpture in 3D, capturing the power and majesty of this legendary historical figure.
              </ExampleDescription>
            </ExampleCard>

          </ExamplesGrid>
        </ContentWrapper>
      </Container>
    </Overlay>
  );
};

export default ExamplesPage;
