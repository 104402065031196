import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgba(30, 30, 30, 0.8);
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(40, 40, 40, 0.9);
  }
`;

interface VersionDisplayProps {
  version: string;
  onClick: () => void;
}

const VersionDisplay: React.FC<VersionDisplayProps> = ({ version, onClick }) => {
  return (
    <Container onClick={onClick}>
      v{version}
    </Container>
  );
};

export default VersionDisplay;
