/*
File: helpers.ts
Description: Helper functions for HTML generation and color manipulation
Last modified: 2024-01-09
Changes: Initial creation - Extracted from GenerateExportedHtml.ts
*/

export const adjustColorBrightness = (color: string, percent: number) => {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const isMobileDevice = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase()
  );
};

export const toExportFormat = (arr: [number, number, number]) => {
  return { _x: arr[0], _y: arr[1], _z: arr[2] };
};

export const fromVector3 = (vector: { x: number; y: number; z: number }): [number, number, number] => {
  return [vector.x, vector.y, vector.z];
};
