/*
File: Preloader.tsx
Description: Loading screen component that displays while models are being loaded
Last modified: 2024-02-21
Changes: 
- Fixed progress bar flickering by keeping it always visible during loading
- Modified progress bar to show actual loading progress with a smooth transition
- Hide the bar fill at 0% progress by making it transparent
- Add a transition so the bar width smoothly changes when progress updates
*/

import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';
import StorySplat from '../images/StorySplat.webp';

// Import the lottie file
//@ts-ignore
import storySplatLottie from '../images/storySplatLottie.json';

interface PreloaderProps {
  isLoading: boolean;
  progress: number | undefined;
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const PreloaderContainer = styled.div<{ $isLoading: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  pointer-events: ${({ $isLoading }) => $isLoading ? 'auto' : 'none'};
  animation: ${({ $isLoading }) => !$isLoading && fadeOut} 0.5s ease-out forwards;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const StoryImage = styled.img`
  height: 200px;
  width: auto;
  object-fit: contain;
  filter: invert(1); // Makes the image white
  margin-right: -100px;
  
  @media (max-width: 768px) {
    height: 100px;
    margin-right: -50px;
  }
`;

const StyledPlayer = styled(Player)`
  height: 200px;
  width: 200px;

  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
`;

const ProgressBarContainer = styled.div`
  width: 300px;
  height: 4px;
  background-color: #333;
  border-radius: 2px;
  margin-top: 20px;
  overflow: hidden;
  
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const ProgressBarFill = styled.div<{ $progress: number }>`
  width: ${props => props.$progress}%;
  height: 100%;
  background-color: ${props => (props.$progress > 0 ? '#fff' : 'transparent')};
  border-radius: 2px;
  transition: width 0.5s ease-out, background-color 0.5s ease-out;
`;

const Preloader: React.FC<PreloaderProps> = ({ isLoading, progress }) => {
  const progressRef = React.useRef(progress);
  useEffect(() => {
    console.log('Preloader loading state:', isLoading);
    console.log('Preloader progress:', progress);
    if(progress){
    progressRef.current = progress;
    }
  }, [isLoading, progress]);

  //progress ref

  

  return (
    <PreloaderContainer $isLoading={isLoading}>
      <ContentContainer>
        <MediaContainer>
          <StoryImage src={StorySplat} alt="StorySplat Logo" />
          <StyledPlayer
            autoplay
            loop
            src={storySplatLottie}
          />
        </MediaContainer>
        {isLoading && progressRef.current && (
          <ProgressBarContainer>
            <ProgressBarFill $progress={progressRef.current} />
          </ProgressBarContainer>
        )}
      </ContentContainer>

  
    </PreloaderContainer>
  );
};

export default Preloader;
